import React, { useContext } from "react";

import "./Cronometro.css";
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { AvatarImage } from '../../components/Images/AvatarImage';
import { NumberHelper } from "../../helpers/NumberHelper";

export const MatchTimerHeader: React.FC = () => {
  const { match, elapsedTime, deadTime, duration, totalElapsedTime } = useContext(MatchTimerContext);

  return (
    <>
      <div className="cronometro-container">
        <div className="team-container">
          <AvatarImage id={match?.homeTeamId} />
        </div>
        <div className="small-timer">{NumberHelper.toTimer(duration - elapsedTime)}</div>
        <div className="small-timer">{totalElapsedTime}</div>
        <div className="match-timer">
          <div className="match-stage">{match?.cronoPlaying ? "Periodo" : "Intervalo"} {match?.cronoPeriod}</div>
          <div className="big-timer">{NumberHelper.toTimer(elapsedTime)}</div>
        </div>
        <div className="small-timer">{NumberHelper.toTimer(deadTime)}</div>
        <div className="team-container">
          <AvatarImage id={match?.awayTeamId} />
        </div>
      </div>
      <div className="team-card">
        <span className="team-name">
          {match?.homeTeam?.team?.teamName} {match?.homeSubTeam}
        </span>
        <div className="team-score-container">
          <span className="team-score">00</span>
          <span className="team-score">00</span>
        </div>
        <span className="team-name team-name-left">
          {match?.awayTeam?.team?.teamName} {match?.awaySubTeam}
        </span>
      </div>
      {/* <div>
        start:{match?.cronoStart?.toString()} - elapsed:{match?.cronoElapsedTime} - dead:{match?.cronoDeadTime} - status:{match?.cronoStatus}
      </div>
      <div>
        elapsedTime [{elapsedTime}] - deadTime [{deadTime}]
      </div> */}
    </>
  );
};

