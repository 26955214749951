import React, { useContext } from "react";
import { Divider } from "antd";
import "../SideRightCard.css";
import { TeamsContext } from "../../contexts/TeamsContext";
import Label from "../../components/Form/Label/Label";
import { TeamFedarationsAdd } from "./TeamFedarationsAdd";
import TeamFederationCard from "./TeamFederationCard";

interface properties { }

export const TeamFedarationsList: React.FC<properties> = () => {
  const { current } = useContext(TeamsContext);

  return (
    <>
      <Divider orientation="left">Asociaciones/Federaciones</Divider>
      <div className="sideright-card-forms">
        <div className="small-container">
          {current?.federations?.length ? (
            current.federations.map((record) => (
              <TeamFederationCard federation={record} />
            ))
          ) : (
            <Label
              fontSize="14"
              fontWeight="500"
              gray
              style={{ marginBottom: 20 }}
            >
              No hay cuenta asociada
            </Label>
          )}
        </div>
      </div>
      <TeamFedarationsAdd />
    </>
  );
};
