import React, { useState, useEffect } from "react";
import "./MyAccount.css";

import { Card } from "antd";
import { CuentaEquipo } from "../../virtualclub/models/models/_obsolete/equipos/CuentaEquipo";

const AccountSubscriptions: React.FC = () => {
  const [equipos, setEquipos] = useState<CuentaEquipo[]>([]);

  useEffect(() => {
    const fetchEquipos = async () => {
      //const equiposData = await MyAccountService.getTeams();
      //setEquipos(equiposData || []);
    };

    fetchEquipos();
  }, []);

  return (
    <Card title="Subscripciones">
      {equipos.length > 0 ? (
        <ul>
          {equipos.map((equipo) => (
            <li key={equipo.equipoId}>{equipo.nombre}</li>
          ))}
        </ul>
      ) : (
        <p>No hay suscripciones disponibles.</p>
      )}
    </Card>

  );
};

export default AccountSubscriptions;
