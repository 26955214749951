import { useState } from 'react';
import { apiGet, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import {
  SearchFilters,
  convertToUrlParamiter,
} from '../virtualclub/models/filters/BaseFilters';
import { PageResult } from '../virtualclub/models/base/PageResult';
import { Team } from '../virtualclub/models/models/teams/Team';
import { TeamUpdatedResponse } from '../virtualclub/models/models/teams/Team.create';
import { useFileService } from './useFileService';

export const useTeamsService = () => {
  const { changeAvatar: changeImage } = useFileService();
  const [data, setData] = useState<PageResult<Team>>();
  const [current, setCurrent] = useState<Team>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const list = async (filters?: SearchFilters<Team>) => {
    setIsLoading(true);
    setError(null);
    try {
      const queryString = convertToUrlParamiter(filters);
      const apiUrl = `${apiRoutes.teams}${queryString}`;
      const result = await apiGet<PageResult<Team>>(apiUrl);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const select = (value?: Team) => {
    setCurrent(value);
  }

  const update = async (values: Team): Promise<TeamUpdatedResponse | undefined> => {
    const apiUrl = apiRoutes.teamsId.replace(":id", values.teamId ?? "")
    const result = await apiPost<Team, TeamUpdatedResponse>(apiUrl, values);

    if (current?.teamId) {
      setData((prevData) => {
        return {
          ...prevData,
          data: (prevData!.data || []).map((row) =>
            row.teamId === values.teamId ? values : row
          ),
        };
      });
    } else {
      setData((prevData) => ({
        ...prevData,
        data: [values, ...(prevData?.data || [])],
      }));
    }
    setCurrent(values);
    return result;
  }

  const changeAvatar = async (teamId: string, file: File, fileType?: string) => {
    changeImage?.(teamId, file, fileType);

    return undefined;
  };

  const getId = async (teamId: string) => {
    const apiUrl = apiRoutes.teamsId.replace(":id", teamId)
    const result = await apiGet<Team>(apiUrl);
    setCurrent(result);
  }

  const getTeamIdsByAccountId = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const apiUrl = `${apiRoutes.teams}`;
      const result = await apiGet<PageResult<Team>>(apiUrl);
      if (result?.data?.length) {
        return result?.data.map(x => x.teamId!);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
    return [];
  }

  return { data, current, isLoading, error, list, select, update, changeAvatar, getId, getTeamIdsByAccountId };
}
