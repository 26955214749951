import { useAsyncCall } from "../hooks/useAsyncCall";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { ICategoria } from "../virtualclub/models/models/_obsolete/categorias/ICategoria";
import { IEquipo } from "../virtualclub/models/models/_obsolete/equipos/IEquipo";
import { CategoriasService } from "../services/club/CategoriasService";
import { AuthorizationHelper } from "../helpers/AuthorizationHelper";
import { CuentaEquipo } from "../virtualclub/models/models/_obsolete/equipos/CuentaEquipo";
import { AccountType } from "../virtualclub/models/models/accounts/AccountType.enum";

interface Props { }

export const GeneralContext = createContext<GeneralContextValue>(
  {} as GeneralContextValue
);

export const GeneralContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children }) => {
  const [auth] = useState(new AuthorizationHelper());
  const [equipo, setEquipo] = useState<CuentaEquipo>();
  const [equipos, setEquipos] = useState<CuentaEquipo[]>();
  const [genero, setGenero] = useState<string>();
  const [categorias, setCategorias] = useState<ICategoria[]>();
  const [categoria, setCategoria] = useState<number>();
  const [letra, setLetra] = useState<number>();

  const changeEquipo = useCallback(
    async (equipo: CuentaEquipo): Promise<void> => {
      setEquipo(equipo);
      const categoriasService = new CategoriasService();
      if (equipo.cuentaId && equipo.deporteId) {
        const resCat = await categoriasService.getCategorias(
          equipo.cuentaId,
          equipo.deporteId
        );
        setCategorias(resCat);
        console.log(resCat);
      } else {
        setCategorias([]);
      }
    },
    []
  );

  const loadData = useCallback(async (): Promise<void> => {
    const result: CuentaEquipo[] = [];
    console.log(result);
    if (result && result.length > 0) {
      setEquipos(result);
      console.log(result);
      if (auth.isAccountType(AccountType.Club)) {
        changeEquipo(result[0]);
      }
    }
  }, [auth, changeEquipo]);

  const loader = useAsyncCall(loadData, []);

  const changeGenero = (value: string | undefined): void => {
    setGenero(value);
  };

  const changeCategoria = (value: number | undefined): void => {
    setCategoria(value);
  };

  const changeLetra = (value: number | undefined): void => {
    setLetra(value);
  };

  const contextValue = useMemo<GeneralContextValue>(
    () => ({
      loading: loader.loading,
      equipo,
      equipos,
      categorias,
      genero,
      changeGenero,
      categoria,
      changeCategoria,
      letra,
      changeLetra,
      changeEquipo,
    }),
    [
      loader.loading,
      equipo,
      equipos,
      categorias,
      genero,
      categoria,
      letra,
      changeEquipo,
    ]
  );

  return (
    <GeneralContext.Provider value={contextValue}>
      {children}
    </GeneralContext.Provider>
  );
};

export interface GeneralContextValue {
  loading: boolean;
  equipo?: CuentaEquipo;
  equipos?: CuentaEquipo[];
  changeEquipo?: (value: IEquipo) => void;
  categorias?: ICategoria[];

  genero?: string;
  changeGenero?: (value: string | undefined) => void;

  categoria?: number;
  changeCategoria?: (value: number | undefined) => void;

  letra?: number;
  changeLetra?: (value: number | undefined) => void;
}
