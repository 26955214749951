import React from "react";
import Header from "./Header";
import "./LandingScreen.css";
import HeroSection from "./HeroSection";
import FeaturesSection from "./FeatureSection";
import AboutSection from "./AboutSection";
import InfoSection from "./InfoSection";
import CreateSection from "./CreateSection";
import Footer from "./Footer";

const LandingScreen: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <InfoSection />
      <CreateSection />
      <Footer />
    </div>
  );
};

export default LandingScreen;
