import React from "react";
import { Card, Tag } from "antd";
import { TournamentView } from "../../../virtualclub/models/models/tournaments/Tournament.view";
import { SportImage } from "../../../components/Images/SportImage";

interface TournamentRowCardProps {
  tournament: TournamentView;
}

const TournamentRowCard: React.FC<TournamentRowCardProps> = ({
  tournament,
}) => {
  const borderColor = tournament.gender === 0 ? "#ff00e5" : "#001aff";

  return (
    <Card
      style={{
        borderLeft: `5px solid ${borderColor}`,
        position: "relative",
        width: "300px",
      }}
    >
      <Tag
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "12px",
        }}
      >
        {tournament.tournamentStartDate?.toISOString()}
      </Tag>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <SportImage
            sportId={tournament.sportId}
            style={{ width: "50px", height: "50px" }}
          />
        </div>

        <div
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}
        >
          {tournament.tournamentName}
        </div>
      </div>
    </Card>
  );
};

export default TournamentRowCard;
