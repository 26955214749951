import React, { useContext, useMemo, useState } from "react";
import { SearchInput } from "../../../components/Search/SearchInput";
import { OrderBy } from "../../../components/OrderBy/OrderBy";

import { PreSelectTeamContext } from "./PreSelectTeamContext";
import { AvailablePlayer } from "../playersComponents/AvailablePlayer";
import { Pagination } from "antd";
import { SportTeamCategoryAvailableView } from '../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryAvailableView';
import { ChangeShirtPopup } from '../playersComponents/ChangeShirtPopup';
import { ChangePositionPopup } from '../playersComponents/ChangePositionPopup';

interface properties {
}

export const AvailablePlayers: React.FC<properties> = () => {
  const { match, availablePlayers, updatedCategory } = useContext(PreSelectTeamContext);
  const { addedPlayers } = useContext(PreSelectTeamContext);

  const [filterText, setFilterText] = useState('');
  const [sortKey, setSortKey] = useState<keyof SportTeamCategoryAvailableView>("position");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(availablePlayers.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [openShirtNumberPopup, setOpenShirtNumberPopup] = useState(false);
  const [openPositionPopup, setPositionPopup] = useState(false);
  const [current, setCurrent] = useState<SportTeamCategoryAvailableView>();


  const currentItems = useMemo(() => {
    if (availablePlayers?.length) {
      // Filter
      const filteredItems = availablePlayers.filter((item) => {
        const fullName = `${item.lastnames?.toLowerCase()} ${item.firstnames?.toLowerCase()}`;
        return fullName.includes(filterText.toLowerCase());
      });

      // Order by
      const sortedItems = filteredItems.sort((a, b) => {
        const aValue = a[sortKey];
        const bValue = b[sortKey];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue)
        }
        return 0;
      });
      return sortedItems.slice(indexOfFirstItem, indexOfLastItem);
    }
    return [];
  }, [availablePlayers, filterText, indexOfFirstItem, indexOfLastItem, sortKey]);

  function handlerSort(value: string): void {
    setSortKey(value as keyof SportTeamCategoryAvailableView);
  }

  const handleClose = () => {
    setOpenShirtNumberPopup(false);
    setPositionPopup(false);
  };

  const excludeNumbers = useMemo(() => {
    const values = addedPlayers.map((x) => x.sportTeamNumber!);
    return values;
  }, [addedPlayers]);

  const onChangeShirtNumber = (value: SportTeamCategoryAvailableView) => {
    handleClose();
    setCurrent(value);
    setOpenShirtNumberPopup(true);
  };

  const handlerChangeShirtNumber = async (shirtNumber: number) => {
    if (current?.sportTeamCategoryId) {
      updatedCategory?.({ sportTeamCategoryId: current.sportTeamCategoryId, shirtNumber })
    }
    setOpenShirtNumberPopup(false);
  };

  const onChangePosition = (value: SportTeamCategoryAvailableView) => {
    handleClose();
    setCurrent(value);
    setPositionPopup(true);
  };

  const handlerChangePosition = async (position: number) => {
    if (current?.sportTeamCategoryId) {
      updatedCategory?.({ sportTeamCategoryId: current.sportTeamCategoryId, position })
    }
    setPositionPopup(false);
  };

  return (
    <>
      <div style={{ display: "flex", marginBottom: 10 }}>
        <span className="info-text"> Disponibles</span>

        <SearchInput value={filterText} onChange={(value) => setFilterText(value)} />

        <OrderBy
          options={[
            { value: "sportTeamCode", label: "Legajo" },
            { value: "position", label: "Posicion" },
            { value: "shirtNumber", label: "Numero" },
            { value: "lastnames", label: "Apellido y nombre" },
          ]}
          value={sortKey}
          onChange={handlerSort}
          style={{ width: "150px" }}
          showSort={false}
        />
      </div>

      {currentItems.map((j) => (
        <AvailablePlayer
          key={j.sportTeamCategoryId} value={j}
          onChangeShirtNumber={() => onChangeShirtNumber(j)}
          onChangePosition={() => onChangePosition(j)}
        />
      ))}

      {totalPages > 1 && (
        <Pagination
          total={availablePlayers.length}
          pageSize={itemsPerPage}
          current={currentPage}
          onChange={(value) => { setCurrentPage(value) }}
        />
      )}

      {openShirtNumberPopup && (
        <ChangeShirtPopup
          gender={current?.gender}
          lastnames={current?.lastnames}
          shirtNumber={current?.shirtNumber}
          openModal={openShirtNumberPopup}
          onClose={handleClose}
          onChange={handlerChangeShirtNumber}
          excludeNumbers={excludeNumbers}
        />
      )}
      {openPositionPopup && match?.sportId && (
        <ChangePositionPopup
          sportId={match?.sportId}
          position={current?.position!}
          shirtNumber={current?.shirtNumber}
          openModal={openPositionPopup}
          onClose={handleClose}
          onChange={handlerChangePosition}
        />
      )}
    </>
  );
};
