export enum SportTeamCategoryType {
  NotDefined = 0,
  Player = 1,
  Judge = 2,
  Coach = 3,
  Assistant = 4,
  PhysicalTrainer = 5,
  Medic = 6,
  Referee = 7,
}
