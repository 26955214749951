import React, { useContext, useEffect, useState } from "react";
import { Button } from "antd";
import "./MatchTimerButtons.css";
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { EventPopup } from "./EventPopup/EventPopup";
import { MatchEventType } from '../../virtualclub/models/models/matchesEvents/MatchEventType.enum';

const MatchTimerButtons: React.FC = () => {
  const { match, elapsedTime, start, pause } = useContext(MatchTimerContext);
  const [executeEventType, setExecuteEventType] = useState<MatchEventType>();

  // const { triggerEvent, startCrono, pauseCrono, isRunning } =
  //   useContext(CronoContext);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // switch (event.key) {
      //   case "q":
      //     triggerEvent(EventoTipo.Tarjeta_Verde);
      //     break;
      //   case "a":
      //     triggerEvent(EventoTipo.Tarjeta_Amarilla);
      //     break;
      //   case "z":
      //     triggerEvent(EventoTipo.Tarjeta_Roja);
      //     break;
      //   case "o":
      //     triggerEvent(EventoTipo.Tarjeta_Verde);
      //     break;
      //   case "k":
      //     triggerEvent(EventoTipo.Tarjeta_Amarilla);
      //     break;
      //   case "m":
      //     triggerEvent(EventoTipo.Tarjeta_Roja);
      //     break;

      //   default:
      //     break;
      // }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);


  const handlerExecuteEvent = (eventType: MatchEventType) => {
    setExecuteEventType(eventType);
  }


  return (
    <>
      <div className="crono-buttons-container">
        {match?.cronoStatus === 1 ?
          <Button
            type="primary"
            icon={<i className="fa-solid fa-triangle" />}
            size="large"
            className="play-button"
            onClick={pause}
          /> :
          <Button type="primary"
            icon={<i className="fa-solid fa-play" />}
            size="large"
            className="play-button"
            onClick={start}
          />
        }
        <div className="small-buttons-container">
          <Button
            type="primary"
            icon={<i className="fa-regular fa-triangle green" />}
            className="smallbtn"
            onClick={() => handlerExecuteEvent(MatchEventType.GreenCard)}
          />
          <Button
            type="primary"
            icon={<i className="fa-regular fa-circle yellow" />}
            className="smallbtn "
            onClick={() => handlerExecuteEvent(MatchEventType.YellowCard)}
          />
          <Button
            type="primary"
            icon={<i className="fa-regular fa-square red" />}
            className="smallbtn "
            onClick={() => handlerExecuteEvent(MatchEventType.RedCard)}
          />
          <Button
            type="primary"
            icon={<i className="fa-regular fa-futbol" />}
            className="smallbtn"
            onClick={() => handlerExecuteEvent(MatchEventType.Goal)}
          />
          <Button
            type="primary"
            icon={<i className="fa-regular fa-check-to-slot" />}
            className="smallbtn"
            onClick={() => handlerExecuteEvent(MatchEventType.Substitution)}
          />
          <Button
            type="primary"
            icon={<i className="fa-regular fa-corner" />}
            className="smallbtn"
            onClick={() => handlerExecuteEvent(MatchEventType.CornerGoal)}
          />
        </div>
      </div>
      {executeEventType && <EventPopup eventType={executeEventType} onClose={() => setExecuteEventType(undefined)} />}
    </>
  );
};

export default MatchTimerButtons;
