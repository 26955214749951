import React from "react";
import infoImage from "./assets/InfoImage.png";

const InfoSection: React.FC = () => {
  return (
    <section className="info-section">
      <div className="info-content-left">
        <div className="info-text-left">
          <h3>Anotate y juega alguno de nuestros torneos</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <button className="btn green">Comenzar ya</button>
        </div>
        <div className="info-image">
          <img src={infoImage} alt="Info" />
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
