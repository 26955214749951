import React, { useState } from "react";
import Popup from "../../../components/Modal/Popup";
import "./ButtonRemove.css";
import { ButtonSaveCancel } from "./ButtonSaveCancel";
import { ButtonAction } from './ButtonAction';

interface ButtonRemoveProps {
  onConfirm: () => void;
  itemName?: string; // Nombre del elemento a borrar, opcional
}

export const ButtonRemove: React.FC<ButtonRemoveProps> = ({
  onConfirm,
  itemName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <ButtonAction type="cancel" onClick={handleOpen} />
      <Popup
        visible={isModalVisible}
        title=""
        showCloseButton
        showBackButton
        width={600}
        onClose={handleClose}

      >
        <div>
          <p>¿Estás seguro de que deseas eliminar este elemento?</p>
          {itemName && (
            <p>
              <strong>{itemName}</strong>
            </p>
          )}
          <ButtonSaveCancel
            saveText="Confirmar"
            onSaveClick={handleConfirm}
            onCancelClick={handleClose}
          />
        </div>
      </Popup>
    </>
  );
};
