import { useContext } from "react";
import "./EventList.css";
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { NumberHelper } from "../../helpers/NumberHelper";
import { DateHelper } from "../../helpers/DateHelper";
import { MatchEventType } from '../../virtualclub/models/models/matchesEvents/MatchEventType.enum';

const EventList = () => {
  const { match, events } = useContext(MatchTimerContext);

  const getEventIcon = (eventType: MatchEventType) => {
    switch (eventType) {
      case MatchEventType.Goal:
      case MatchEventType.OwnGoal:
      case MatchEventType.PenaltyGoal:
      case MatchEventType.RemoveGoal:
        return <i className="fa-regular fa-futbol fa-xl"></i>;
      case MatchEventType.GreenCard:
        return <i className="fa-regular fa-triangle green" />;
      case MatchEventType.YellowCard:
        return <i className="fa-regular fa-circle yellow" />;
      case MatchEventType.RedCard:
        return <i className="fa-regular fa-square red" />;
      case MatchEventType.Substitution:
        return <i className="fa-regular fa-check-to-slot" />;
      case MatchEventType.CornerGoal:
        return <i className="fa-regular fa-corner" />;
      default:
        return null;
    }
  };

  return (
    <div className="event-list">
      <div className="event-period">

        {events?.map((event, index) => {

          const isVisitante = event.federationTeamId === match?.awayFederationTeamId;
          return <>
            {
              event.matchEventType! <= 6 &&
              <div className="event-period-header">
                <span className="event-period-text"> {event.details}  P{event.period} {DateHelper.formatTimeLong(event.createdAt)}</span>
              </div>
            }

            {
              event.matchEventType! > 6 &&
              <div
                key={index}
                className={`event-row ${isVisitante ? "reverse" : ""}`}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  {getEventIcon(event.matchEventType ?? 0)}
                  <span className="event-text">{event.sportTeamNames ?? event.details}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span className="event-text-sm">{DateHelper.formatTimeLong(event.createdAt)}</span>
                  <span className="event-text">{NumberHelper.toTimer(event.timeInSeconds ?? 0)}</span>
                </div>
              </div>
            }
          </>;
        })}

      </div>
    </div>
  );
};

export default EventList;
