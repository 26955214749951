import React from "react";
import style from "./TableCard.module.css";

interface properties {
  id: string | number;
  gender?: number;
  onClick?: (id: string) => void;
  className?: string;
  gap?: string;
  active?: boolean;
  justify?: boolean;
  height?: string;
}

export const Row: React.FC<React.PropsWithChildren<properties>> = ({
  id,
  gender,
  onClick,
  children,
  gap,
  active,
  justify,
  height,
}) => {
  const handleRowClick = () => {
    onClick?.(id.toString());
  };

  let generoClassName =
    gender === 0 ? style.gender0 : gender === 1 ? style.gender1 : "";

  const containerStyle = {
    gap: gap || "15px",
    backgroundColor: active ? "antiquewhite" : "",
    justifyContent: justify ? "space-between" : "start",
    height: height || "40px",
  };

  return (
    <div
      className={`${style.tablecard} ${generoClassName}`}
      onClick={handleRowClick}
      style={containerStyle}
    >
      {children}
    </div>
  );
};
