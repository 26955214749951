import { useContext } from "react";
import { Checkbox, Pagination, Spin } from "antd";

import { SearchInput } from "../../components/Search/SearchInput";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { SportsTeamsContext } from "../../contexts/SportsTeamsContext";
import { ColAvatar } from "../../components/TableCard/ColAvatar";
import { ColSport } from "../../components/TableCard/ColSport";
import { ColTag } from "../../components/TableCard/ColTag";
import { NamesHelper } from "../../helpers/NamesHelper";
import { SelectGenre } from "../../components/lookups/SelectGenre";
import { SelectCategoryType } from "../../components/lookups/SelectCategoryType";
import { ColStatus } from "../../components/TableCard/ColStatus";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";

interface properties {
  onSelect?: () => void;
}

export const TechnicalStaffList: React.FC<properties> = ({ onSelect }) => {
  const { isLoading, data, current, selected, gotoPage } =
    useContext(SportsTeamsContext);

  const handleRowClick = (sportTeamCategoryId: string) => {
    const value = data?.data?.find(
      (p) => p.sportTeamCategoryId === sportTeamCategoryId
    );
    if (value) {
      selected?.(value);
      onSelect?.();
    }
  };

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage?.(page);
  }

  return (
    <Spin spinning={isLoading}>
      <div className="small-container">
        {data &&
          data?.data &&
          data?.data.map((player) => (
            <Row
              id={player.sportTeamCategoryId ?? ""}
              gender={player.gender}
              onClick={handleRowClick}
              active={
                player.sportTeamCategoryId === current?.sportTeamCategoryId
              }
              justify
            >
              <ColAvatar id={player.teamId ?? ""} />
              <ColSport value={player.sportId} />
              <ColText
                width="150px"
                value={`${player.team?.teamShort} ${NamesHelper.LetterName(
                  player.subTeam
                )}`}
              />

              <ColAvatar id={player.federationId ?? ""} />
              <ColText
                value={player.sportTeamCode ?? ""}
                grayText
                width="75px"
              />
              <ColText value={player.lastnames + "," + player.firstnames} />
              <ColTag
                value={NamesHelper.CategoryTypeShort(
                  player.sportTeamCategoryType
                )}
                width={"45px"}
              />
              <ColTag value={player.categoryShort} width={"60px"} />
              <ColStatus
                value={player.sportTeamCategoryStatus as number}
                priority={2}
              />
            </Row>
          ))}
      </div>

      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
