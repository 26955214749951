import React from "react";
import Logo from "../../assets/VC.svg";

interface properties {
  sportId?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const FieldImage: React.FC<properties> = ({ sportId, className, style }) => {

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  const getSportImage = (id?: number): string => {
    if (id) {
      // Retorna la ruta dinámica de la imagen según el ID
      return `/assets/field${id}.svg`;
    }
    // Fallback a logo si no hay id
    return Logo;
  };


  const icon = getSportImage(sportId);

  return <img
    src={icon}
    alt={``}
    onError={handleImageError}
    className={className}
    style={style}
  />;
};
