import React from "react";
import "./Layout.css";
import logo from "../public/mainLogo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/RouteConstants";

export const Header: React.FC = () => {
  const navigate = useNavigate();
  return (
    <header className="main-header">
      <div className="main-logo" onClick={() => navigate(routes.Matches)}>
        <img src={logo} className="main-img"></img>
      </div>
      <div className="main-user">
        <span>Demostracion</span>
      </div>
    </header>
  );
};
