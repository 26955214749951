import { IsDateString, IsEnum, IsNotEmpty, IsOptional } from "class-validator";
import { MemberStatus } from "./MemberStatus.enum";
import { plainToInstance } from "class-transformer";
import { Gender } from "./Genders.enum";

export class Member {
  @IsOptional()
  memberId?: string = "";

  @IsOptional()
  accountId?: string = "";

  @IsOptional()
  fileId?: string = "";

  @IsNotEmpty()
  lastnames?: string = "";

  @IsNotEmpty()
  firstnames?: string = "";

  @IsOptional()
  gender?: Gender = Gender.Undefined;

  @IsOptional()
  @IsDateString()
  datebirth?: Date = new Date();

  @IsOptional()
  identityDocument?: number = 0;

  @IsOptional()
  address?: string = "";

  @IsOptional()
  floor?: string = "";

  @IsOptional()
  apartment?: string = "";

  @IsOptional()
  cityname?: string = "";

  @IsOptional()
  postalCode?: string = "";

  @IsOptional()
  statename?: string = "";

  @IsOptional()
  countryname?: string = "";

  @IsOptional()
  phone?: string = "";

  @IsOptional()
  email?: string = "";

  @IsOptional()
  password?: string = "";

  @IsOptional()
  @IsEnum(MemberStatus)
  memberStatus?: MemberStatus = MemberStatus.Active;

  constructor(init?: Partial<Member>) {
    Object.assign(this, init);
  }

  static fromPlain(plain: Member): Member {
    return plainToInstance(Member, plain);
  }
}
