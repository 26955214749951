export function validEnum<T extends object>(
  enumObj: T,
  value: string | number
): T[keyof T] | undefined {
  if (typeof value === 'number') {
    if (Object.values(enumObj).includes(value)) {
      const ret = value as T[keyof T];
      return ret;
      //return value as T[keyof T];
    }
  } else if (typeof value === 'string') {
    // Verifica si el valor es un nombre de la clave del enum
    if (value in enumObj) {
      return enumObj[value as keyof T];
    }
    // Verifica si el valor es un string numérico que coincide con un valor del enum
    if (
      !isNaN(Number(value)) &&
      Object.values(enumObj).includes(Number(value))
    ) {
      return Number(value) as T[keyof T];
    }
  }
  return undefined;
}

export function getEnumName<T extends { [key: string]: string | number }>(
  enumObj: T,
  value: T[keyof T]
): string | undefined {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
}
