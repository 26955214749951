import React, { useState } from "react";
import { Steps, Button } from "antd";
import { TournamentsContextProvider } from "../../../contexts/TournamentsContext";
import { TournamentContextProvider } from "../../../contexts/TournamentContext";

import TournamentMatches from "./TournamentMatches";
import { TournamentFormats } from "./TournamentFormats";
import { TournamentTeams } from "./TournamentTeams";

const { Step } = Steps;

const TournamentPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <TournamentsContextProvider>
      <TournamentContextProvider>
        <div className="tournament-page-container">
          <Steps current={currentStep}>
            <Step title="Formato del Torneo" />
            <Step title="Ingresar Equipos" />
            <Step title="Confirmar" />
          </Steps>

          <div className="steps-content">
            {currentStep === 0 && <TournamentFormats nextStep={next} />}
            {currentStep === 1 && (
              <>
                <TournamentTeams nextStep={next} />
              </>
            )}
            {currentStep === 2 && (
              <div>
                <h2>Confirmación del Torneo</h2>
                <TournamentMatches />
              </div>
            )}
          </div>
        </div>
      </TournamentContextProvider>
    </TournamentsContextProvider>
  );
};

export default TournamentPage;
