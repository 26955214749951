import React, { useCallback, useContext, useState } from "react";
import { Form, message, Col, Row } from "antd";
import "../SideRightCard.css";
import Popup from "../../components/Modal/Popup";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { MembersContext } from "../../contexts/MembersContext";
import { Team } from "../../virtualclub/models/models/teams/Team";
import { ButtonSave } from "../../components/Form/Buttons/ButtonSave";
import { SportsTeamsCreate } from "../../virtualclub/models/models/sportsTeams/SportsTeams.create";
import { SelectFederation } from "../../components/lookups/SelectFederation";
import { FederationTeam } from "../../virtualclub/models/models/federationsTeams/FederationTeam";
import { SelectShirtNumber } from "../../components/lookups/SelectShirtNumber";
import { SelectPosition } from "../../components/lookups/SelectPosition";
import { SelectSportTeamType } from "../../components/lookups/SelectSportTeamType";
import { useSportsTeamsService } from "../../services/useSportsTeamsService";
import { SelectFederatedTeams } from "../../components/lookups/SelectFederatedTeams";
import { AccountView } from "../../virtualclub/models/models/accounts/Account.view";
import { SelectCategory } from "../../components/lookups/selectCategory";
import { Category } from "../../virtualclub/models/models/categories/Category";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import ShirtPopup from "../../components/Modal/ShirtPopup";

import { Shirt } from "../../components/Shirt/Shirt";
import { PlayerShirt } from "../../components/Shirt/PlayerShirt";
interface properties {}

export const MembersActivityPopup: React.FC<properties> = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { current, editing, addSportTeam } = useContext(MembersContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sportTeam, setSportTeam] = useState<SportsTeamsCreate>(
    new SportsTeamsCreate()
  );
  const [shirtNumber, setShirtNumber] = useState<number>(0);
  const [federation, setFederation] = useState<AccountView>();
  const [federationTeam, setFederationTeam] = useState<FederationTeam>();

  const handlerAddRow = () => {
    setIsModalVisible(true);
  };

  const handlerClose = () => {
    setIsModalVisible(false);
  };

  const handlerSaveClick = useCallback((): void => {
    //   form.submit();
  }, []);

  const onFinish = useCallback(async (): Promise<void> => {
    try {
      if (current?.memberId) {
        const model: SportsTeamsCreate = {
          ...sportTeam,
          memberId: current?.memberId,
        };
        console.log(model);

        const result = await addSportTeam(model);
        if (result) {
          messageApi.open({
            type: "success",
            content: "Guardado con éxito",
          });
          handlerClose();
          return;
        }
      }
    } catch (err) {
      console.error("Error al guardar el equipo deportivo:", err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardar.",
    });
  }, [addSportTeam, current?.memberId, messageApi, sportTeam]);

  const handlerChange = (fieldname: string, value: any): void => {
    setSportTeam((prevValues) => ({
      ...prevValues,
      [fieldname]: value,
    }));
  };

  const handlerChangeShirtNumber = (value: number): void => {
    setShirtNumber(value);
    handlerChange("shirtNumber", value);
  };

  const handlerChangeFederation = (value: AccountView, option: any): void => {
    handlerChange("federationId", value.accountId);
    setFederation(value);
  };

  const handlerChangeTeam = (value: FederationTeam, option: any): void => {
    handlerChange("federationTeamId", value.federationTeamId);
    setFederationTeam(value);
  };

  const renderShirt = () => {
    if (sportTeam.sportTeamCategoryType === 1) {
      return (
        <PlayerShirt
          gender={current?.gender ?? 0}
          number={shirtNumber}
          name={current?.lastnames}
        />
      );
    }
    return <Shirt categoryType={sportTeam.sportTeamCategoryType} />;
  };

  return (
    <>
      {!editing && current && (
        <div className="sideright-card-options">
          <ButtonAction size={42} type="plus" onClick={handlerAddRow} />
        </div>
      )}

      <ShirtPopup
        visible={isModalVisible}
        title="Agregar Afiliaciones"
        onClose={handlerClose}
        renderShirt={renderShirt}
      >
        <Form
          layout="horizontal"
          onFinish={onFinish}
          className="sideright-card-form"
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label="Federacion">
                <SelectFederation
                  value={sportTeam.federationId}
                  onChange={handlerChangeFederation}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Equipo">
                <SelectFederatedTeams
                  federationId={sportTeam.federationId}
                  value={sportTeam.federationTeamId}
                  onChange={handlerChangeTeam}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Tipo">
                <SelectSportTeamType
                  value={sportTeam.sportTeamCategoryType}
                  onChange={(value: number) =>
                    handlerChange("sportTeamCategoryType", value)
                  }
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="Categoria">
                <SelectCategory
                  value={sportTeam.categoryId}
                  federationId={sportTeam.federationId}
                  sportId={federationTeam?.sportId}
                  onChange={(value: Category) =>
                    handlerChange("categoryId", value.categoryId)
                  }
                  readOnly={
                    !sportTeam.sportTeamCategoryType ||
                    sportTeam.sportTeamCategoryType > 3
                  }
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="Numero camiseta">
                <SelectShirtNumber
                  shirtNumber={sportTeam.shirtNumber}
                  onChange={handlerChangeShirtNumber} // Usa el manejador específico
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="Posicion">
                <SelectPosition
                  value={sportTeam.position}
                  onChange={(value: number) => handlerChange("position", value)}
                  readOnly={sportTeam.sportTeamCategoryType !== 1}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <ButtonSave onClick={onFinish} />
      </ShirtPopup>
    </>
  );
};

{
  /*
   */
}
