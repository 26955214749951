import React from "react";
import feature1 from "./assets/Feature1.png";
import feature2 from "./assets/Feature2.png";
import feature3 from "./assets/Feature3.png";
import feature4 from "./assets/Feature4.png";

const FeaturesSection: React.FC = () => {
  return (
    <section className="features-section">
      <h2 style={{ fontWeight: 700, fontSize: 26 }}>
        Con VirtualClub podes...
      </h2>
      <div className="features">
        <div className="feature">
          <img src={feature1} alt="Gestionar Equipos" className="feature-img" />
          <span>Gestionar Equipos</span>
        </div>
        <div className="feature">
          <img src={feature2} alt="Crear Torneos" className="feature-img" />
          <span>Crear Torneos</span>
        </div>
        <div className="feature">
          <img
            src={feature3}
            alt="Control de Partidos"
            className="feature-img"
          />
          <span>Control de Partidos</span>
        </div>
        <div className="feature">
          <img src={feature4} alt="Unirse a Torneos" className="feature-img" />
          <span>Unirse a Torneos</span>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
