import React from "react";
import heroImage from "./assets/HeroImage.png";
import Carousel from "antd/es/carousel";

import car2 from "./assets/carrousel-1-vc.jpg";
import car1 from "./assets/carrousel-2-vc.jpg";
import car3 from "./assets/carrousel-3-vc.jpg";

const HeroSection: React.FC = () => {
  return (
    <div className="carousel-container">
      <Carousel autoplay arrows>
        <div>
          <img src={car1}></img>
        </div>
        <div>
          <img src={car2}></img>
        </div>
        <div>
          <img src={car3}></img>
        </div>
      </Carousel>
      <div className="hero-text">
        <h1>
          <strong>ORGANIZA Y GESTIONA</strong> torneos deportivos sin
          complicaciones
        </h1>
        <button className="btn white-btn">Comenzar Ya</button>
        <button className="btn green-btn">Más Info</button>
      </div>
    </div>
  );
};

export default HeroSection;
