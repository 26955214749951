import { useContext, useMemo } from "react";

import "./EventPopup.css";
import { Row } from "../../../components/TableCard/Row";
import { ColText } from "../../../components/TableCard/ColText";
import { MatchTimerContext } from '../../../contexts/MatchTimerContext';
import { MatchSportTeamView } from "../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view";
import { ColShirt } from "../../../components/TableCard/ColShirt";

interface propierties {
  federationTeamId: string;
  onSelectPlayer?: (player: MatchSportTeamView) => void;
}

export const StepSelectPlayer: React.FC<propierties> = ({ federationTeamId, onSelectPlayer }) => {
  const { getPlayers } = useContext(MatchTimerContext);

  const players = useMemo(() => getPlayers(federationTeamId), [federationTeamId, getPlayers]);

  return (
    <>
      {
        players.map((value) => (
          <Row id={value.matchSportTeamId ?? 0} onClick={() => onSelectPlayer?.(value)}>
            <ColShirt value={value.sportTeamNumber?.toString()} />
            <ColText value={value.sportTeamNames ?? "--"} />
          </Row>
        ))
      }
    </>
  );
};

