import React from "react";
import heroImage from "./assets/HeroImage.png";
import TitleText from "./TitleText";
import PosicionesList from "./PosicionesList";

import FixtureList from "./FixtureList";

const partidosEjemplo = [
  {
    id: 1,
    fecha: "2024-07-01",
    clubLocal: { id: 1, nombre: "Equipo Local A", icono: "/icons/team1.png" },
    clubVisitante: {
      id: 2,
      nombre: "Equipo Visitante B",
      icono: "/icons/team2.png",
    },
  },
  {
    id: 2,
    fecha: "2024-07-05",
    clubLocal: { id: 3, nombre: "Equipo Local C", icono: "/icons/team3.png" },
    clubVisitante: {
      id: 4,
      nombre: "Equipo Visitante D",
      icono: "/icons/team4.png",
    },
  },
];
const ContentSection: React.FC = () => {
  return (
    <div className="federation-content-section">
      <div className="federation-column-left">
        <PosicionesList />
      </div>
      <div className="federation-column-right">
        {/*next match */}
        <FixtureList
          fechaEncuentros="Julio 2024"
          torneoNum={1}
          ruedaNum={1}
          partidos={partidosEjemplo}
        />
      </div>
    </div>
  );
};

export default ContentSection;
