import { IsString, IsNotEmpty, IsEmail } from 'class-validator';
import { plainToInstance } from 'class-transformer';

export class FederationTeamAssociate {
  @IsString()
  @IsNotEmpty()
  teamId: string;

  @IsEmail()
  @IsNotEmpty()
  email: string;

  force?: number;

  static fromPlain(plain: FederationTeamAssociate): FederationTeamAssociate {
    return plainToInstance(FederationTeamAssociate, plain);
  }
}

export class FederationTeamAssociateRequest {
  result?: boolean;
  accountName?: string;
}
