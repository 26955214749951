import React, { useContext, useState } from "react";
import formatStyles from "./TournamentFormats.module.css";
import Styles from "./TournamentPage.module.css";
import { ReactComponent as LeagueIcon } from "../../../assets/tournaments/leagueFormat.svg";
import { ReactComponent as LeagueIconActive } from "../../../assets/tournaments/leagueFormatActive.svg";
import { ReactComponent as KnockoutIcon } from "../../../assets/tournaments/coupFormat.svg";
import { ReactComponent as KnockoutIconActive } from "../../../assets/tournaments/coupFormatActive.svg";
import { ReactComponent as GroupIcon } from "../../../assets/tournaments/groupFormat.svg";
import { ReactComponent as GroupIconActive } from "../../../assets/tournaments/groupFormatActive.svg";
import { TournamentContext } from "../../../contexts/TournamentContext";
import { SelectSport } from "../../../components/lookups/SelectSport";

interface TournamentFormatsProps {
  nextStep: () => void;
}

export const TournamentFormats: React.FC<TournamentFormatsProps> = ({
  nextStep,
}) => {
  const { setTournamentFormat } = useContext(TournamentContext);

  // Estado para manejar el formato seleccionado
  const [selectedFormat, setSelectedFormat] = useState<
    "Liga" | "Copa" | "Grupos" | null
  >(null);

  // Estado para manejar el hover sobre un formato
  const [hoveredFormat, setHoveredFormat] = useState<
    "Liga" | "Copa" | "Grupos" | null
  >(null);

  const handleFormatSelection = (format: "Liga" | "Copa" | "Grupos") => {
    setSelectedFormat(format);
    setTournamentFormat(format);
  };

  return (
    <div>
      <div className={Styles.header}>
        <div className={formatStyles.inputWrapper}>
          <label className={formatStyles.label}>Nombre</label>
          <input type="text" className={formatStyles.input} />
        </div>

        <div className={formatStyles.inputWrapper}>
          <label className={formatStyles.label}>Deporte</label>
          <SelectSport isFilter />
        </div>

        <div className={formatStyles.inputWrapper}>
          <label className={formatStyles.label}>Asociación</label>
          <input type="text" className={formatStyles.input} />
        </div>

        <div className={formatStyles.inputWrapper}>
          <label className={formatStyles.label}>Inicio</label>
          <input type="date" className={formatStyles.input} />
        </div>

        <div>
          <button
            className={Styles.button}
            onClick={nextStep}
            disabled={!selectedFormat}
          >
            Siguiente
          </button>
        </div>
      </div>

      <div className={formatStyles.container}>
        <div
          className={formatStyles.format}
          onClick={() => handleFormatSelection("Grupos")}
          onMouseEnter={() => setHoveredFormat("Grupos")}
          onMouseLeave={() => setHoveredFormat(null)}
        >
          <div className={formatStyles.iconWrapper}>
            <GroupIcon
              className={`${formatStyles.icon} ${
                selectedFormat === "Grupos" || hoveredFormat === "Grupos"
                  ? formatStyles.hidden
                  : ""
              }`}
            />
            <GroupIconActive
              className={`${formatStyles.iconActive} ${
                selectedFormat === "Grupos" || hoveredFormat === "Grupos"
                  ? formatStyles.visible
                  : ""
              }`}
            />
          </div>
          <p className={formatStyles.text}>Grupos</p>
        </div>

        <div
          className={formatStyles.format}
          onClick={() => handleFormatSelection("Liga")}
          onMouseEnter={() => setHoveredFormat("Liga")}
          onMouseLeave={() => setHoveredFormat(null)}
        >
          <div className={formatStyles.iconWrapper}>
            <LeagueIcon
              className={`${formatStyles.icon} ${
                selectedFormat === "Liga" || hoveredFormat === "Liga"
                  ? formatStyles.hidden
                  : ""
              }`}
            />
            <LeagueIconActive
              className={`${formatStyles.iconActive} ${
                selectedFormat === "Liga" || hoveredFormat === "Liga"
                  ? formatStyles.visible
                  : ""
              }`}
            />
          </div>
          <p className={formatStyles.text}>Liga</p>
        </div>

        <div
          className={formatStyles.format}
          onClick={() => handleFormatSelection("Copa")}
          onMouseEnter={() => setHoveredFormat("Copa")}
          onMouseLeave={() => setHoveredFormat(null)}
        >
          <div className={formatStyles.iconWrapper}>
            <KnockoutIcon
              className={`${formatStyles.icon} ${
                selectedFormat === "Copa" || hoveredFormat === "Copa"
                  ? formatStyles.hidden
                  : ""
              }`}
            />
            <KnockoutIconActive
              className={`${formatStyles.iconActive} ${
                selectedFormat === "Copa" || hoveredFormat === "Copa"
                  ? formatStyles.visible
                  : ""
              }`}
            />
          </div>
          <p className={formatStyles.text}>Copa</p>
        </div>
      </div>
    </div>
  );
};
