import { Pagination, Spin } from "antd";
import { useContext } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { CategoryContext } from "../../contexts/CategoriesContext";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { ColSport } from "../../components/TableCard/ColSport";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { ButtonAdd } from "../../components/Form/Buttons/ButtonAdd";

interface properties {
  title?: string;
}

export const CategoryList: React.FC<properties> = ({ title }) => {
  const {
    isLoading: searching,
    data,
    filters,
    create,
    updateFilters,
    selected,
    gotoPage,
    current,
  } = useContext(CategoryContext);

  function handlerSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }
  const handleRowClick = (categoriaId: string) => {
    const categoria = data?.data?.find(
      (c) => c.categoryId?.toString() === categoriaId
    );
    if (categoria) {
      selected(categoria);
    }
  };

  return (
    <Spin spinning={searching}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          marginBottom: 5,
        }}
      >
        <div style={{ flex: 1, fontWeight: 400 }}>
          {title ?? "Listado de categorias"}
        </div>
        <div style={{ flex: 1 }}>
          <SearchInput value={filters?.search} onChange={handlerSearch} />
        </div>
        <div style={{ flex: 0.2 }}>
          <ButtonAdd onClick={create} />
        </div>
      </div>
      <div className="small-container">
        {data?.data &&
          data?.data.map((record) => (
            <Row
              id={record.categoryId ?? ""}
              onClick={handleRowClick}
              active={record.categoryId === current?.categoryId}
            >
              <ColText
                value={record.categoryCode ?? ""}
                grayText
                width="40px"
              />
              <ColSport value={record.sportId} />
              <ColText value={record.categoryName ?? ""} />
              <ColText
                value={record.categoryShort ?? ""}
                grayText
                width="40px"
              />
            </Row>
          ))}
      </div>
      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
