import React, { useContext } from "react";
import { CheckMatchContext } from "../CheckMatchContext";
import { Button } from "antd";
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../constants/RouteConstants';

interface properties {
}

export const CheckMatchMenu: React.FC<properties> = () => {
  const { match } = useContext(CheckMatchContext);
  const navigate = useNavigate();

  const openCronus = () => {
    if (match?.matchId) {
      window.open(routes.MatchTimerManager.replace(":matchId", match.matchId), '_blank', 'noopener,noreferrer');
    }
  };


  return (
    <>
      <span style={{ fontWeight: 700, fontSize: 18 }}>
        Opciones
      </span>
      <Button type="primary" onClick={() => openCronus()}>
        Abrir Cronus
      </Button>
      <Button type="primary" >
        Suspender partido
      </Button>
      <Button type="primary">
        Modificar eventos
      </Button>
      <Button type="primary" >
        Finalizar
      </Button>
      <Button type="primary" >
        Imprimir planilla
      </Button>
    </>
  );
};
