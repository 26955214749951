import React, { useContext, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { TournamentContext } from "../../../contexts/TournamentContext";

const TournamentMatches: React.FC = () => {
  const { currentWeek, setCurrentWeek, filterMatchesByWeek, generateMatches } =
    useContext(TournamentContext);
  useEffect(() => {
    generateMatches();
  }, []);
  const matches = filterMatchesByWeek(currentWeek);

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek + 1);
  };

  const handlePreviousWeek = () => {
    if (currentWeek > 1) {
      setCurrentWeek(currentWeek - 1);
    }
  };

  return (
    <div>
      <h3>Partidos - Semana {currentWeek}</h3>
      <div>
        <Button onClick={handlePreviousWeek} disabled={currentWeek === 1}>
          Semana Anterior
        </Button>
        <Button onClick={handleNextWeek}>Siguiente Semana</Button>
      </div>

      <div className="matches-list">
        {matches.map((match: any) => (
          <Row
            key={match.matchId}
            className="match-row"
            justify="space-between"
            align="middle"
          >
            <Col>
              <img
                src={`path-to-avatar/${match.homeTeam.teamId}.png`}
                alt={match.homeTeam.teamName}
                style={{ width: "50px" }}
              />
              <span>{match.homeTeam.teamName}</span>
            </Col>
            <Col>VS</Col>
            <Col>
              <span>{match.awayTeam.teamName}</span>
              <img
                src={`path-to-avatar/${match.awayTeam.teamId}.png`}
                alt={match.awayTeam.teamName}
                style={{ width: "50px" }}
              />
            </Col>
            <Col>
              <span>
                {match.day} a las {match.time}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default TournamentMatches;
