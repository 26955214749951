import React from "react";
import { ColorPicker } from "antd";
import { Color } from "antd/es/color-picker";

interface properties {
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export const ColorExt: React.FC<properties> = (props) => {
  const { value, disabled, onChange } = props;

  function handlerChange(value: Color, hex: string): void {
    onChange?.(hex);
  }

  return (
    <ColorPicker
      value={value}
      onChange={handlerChange}
      disabled={disabled}
      style={{
        marginTop: 5,
        marginLeft: 5,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
    />
  );
};
