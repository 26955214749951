import React from "react";
import style from "./TableCard.module.css";
import { Tag } from "antd";
import { useMediaQuery } from "react-responsive";

interface properties {
  value?: string;
  width?: string | number;
  onClick?: () => void;
  priority?: 1 | 2 | 3;
}

export const ColTag: React.FC<properties> = ({
  value,
  width,
  onClick,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const colstyle = `${style.tag} ${onClick ? style.clickable : ""}`;
  return (
    <Tag className={colstyle} style={{ width: width }} onClick={onClick}>
      <span className={style.tagtext}>{value}</span>
    </Tag>
  );
};
