import React from "react";
import "./ButtonSaveCancel.css";

interface properties {
  onSaveClick?: () => void;
  onCancelClick?: () => void;
  saveText?: string;
  cancelText?: string;
}

export const ButtonSaveCancel: React.FC<properties> = ({
  onSaveClick,
  onCancelClick,
  saveText = "Guardar",
  cancelText = "Cancelar",
}) => {
  return (
    <div className="buttons-group">
      <button className="button cancel" type="button" onClick={onCancelClick}>
        {cancelText}
      </button>
      <button className="button save" type="button" onClick={onSaveClick}>
        {saveText}
      </button>
    </div>
  );
};
