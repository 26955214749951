import { SportTeamCategoryType } from "../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { PositionsType } from "../virtualclub/models/interfaces/PositionsType";
import { MatchEventType } from '../virtualclub/models/models/matchesEvents/MatchEventType.enum';

interface IShirtCategories {
  name: string,
  color: string,
  letter: string
}

const ShirtCategories: IShirtCategories[] = [
  { name: "", color: "", letter: "" },
  { name: "JG", color: "", letter: "black" },
  { name: "JM", color: "#B76EFF", letter: "#000" },
  { name: "DT", color: "#262626", letter: "#fff" },
  { name: "AY", color: "#6ED4FF", letter: "#000" },
  { name: "PF", color: "#7BDE62", letter: "#000" },
  { name: "+", color: "#FFFFFF", letter: "red" },
  { name: "AR", color: "#FFF16E", letter: "#000" },
]

export class NamesHelper {
  static LetterName(value: number | string | undefined): string {
    if (!value || value === 0) {
      return "--";
    }
    const num = typeof value === "string" ? parseInt(value) : value;
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return alphabet[(num - 1) % 26];
  }

  static PosicionNameShort(
    sportId: number | undefined,
    posicion: number | undefined
  ): string {
    if (!sportId || !posicion) {
      return "--";
    }
    const positions = PositionsType[sportId];
    if (posicion >= 0 && posicion < positions.length) {
      return positions[posicion].shortName;
    } else {
      return "--";
    }
  }

  static CategoryTypeShort(value?: SportTeamCategoryType): string {
    let text = "--";
    if (value) {
      text = ShirtCategories[value].name;
    }
    return text;
  }

  static CategoryTypeColor(value?: SportTeamCategoryType): string {
    let color = "";
    if (value) {
      color = ShirtCategories[value].color;
    }
    return color;
  }

  static CategoryTypeLetterColor(value?: SportTeamCategoryType | undefined): string {
    let color = "";
    if (value) {
      color = ShirtCategories[value].letter;
    }
    return color;
  }

  static MatchEventTypeName(
    eventTypeId: MatchEventType | undefined,
  ): string {
    if (!eventTypeId) {
      return "";
    }

    switch (eventTypeId) {
      default:
        return MatchEventType[eventTypeId];
    }

  }

  static StatusName(value?: number | undefined): string {
    switch (value) {
      case 0: return "Borrado";
      case 1: return "Pendiente";
      case 2: return "Rechazado";
      case 3: return "Aceptado";
      default: return "";
    }
  }
}
