import React, { useContext } from "react";
import { CheckMatchPlayersList } from "./CheckMatchPlayersList";
import { CheckMatchContext } from "../CheckMatchContext";

interface properties {}

export const CheckMatchPlayers: React.FC<properties> = () => {
  const { match } = useContext(CheckMatchContext);
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginTop: 15,
        }}
      >
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: 18 }}>
            Jugadores locales
          </span>
          <CheckMatchPlayersList
            federationTeamId={match?.homeFederationTeamId!}
          />
        </div>

        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 700, fontSize: 18 }}>
            Jugadores visitantes
          </span>
          <CheckMatchPlayersList
            federationTeamId={match?.awayFederationTeamId!}
          />
        </div>
      </div>
    </>
  );
};
