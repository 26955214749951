import { plainToInstance } from 'class-transformer';
export class Category {
  categoryId?: number;
  accountId?: string;
  sportId?: number;
  categoryCode?: string;
  categoryName?: string;
  categoryShort?: string;
  categoryOrder?: number;

  static fromPlain(plain: Category): Category {
    return plainToInstance(Category, plain);
  }
}
