import React from "react";
import Logo from "../../assets/VC.svg";
import { configApp } from '../../services/configApp';

interface properties {
  id?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AvatarImage: React.FC<properties> = ({ id, alt, className, style }) => {

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  const url = `${configApp.avatarUrl}${id}.png`;

  return (
    <img
      src={url}
      alt={alt}
      onError={handleImageError}
      className={className}
      style={style}
    />
  );
};
