import React from "react";
import "./MembersActivity.css";
import { SportImage } from "../../components/Images/SportImage";
import { PositionsType } from "../../virtualclub/models/interfaces/PositionsType";
import { SportTeamCategory } from "../../virtualclub//models/models/members/MemberCategories.view";
import { NamesHelper } from "../../helpers/NamesHelper";
import { SportTeamCategoryType } from "../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { TShirtShort } from "../../components/TShirt/tshirtShort";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";
import { StatusIcon } from "../../components/Icons/StatusIcon";
import { ButtonRemove } from "../../components/Form/Buttons/ButtonRemove";
import { useSportsTeamsCategoriesService } from "../../services/useSportsTeamsCategoriesService";
import { MemberSportTeam } from "../../virtualclub/models/models/members/Member.sportteam";

interface properties {
  sportTeam: MemberSportTeam;
  gender: number;
}

const MemberActivity: React.FC<properties> = ({ sportTeam, gender }) => {
  const { updateCategory } = useSportsTeamsCategoriesService();

  const handleRemove = async (sportTeamCategoryId: string) => {
    const updatedCategory: SportTeamCategory = {
      ...sportTeam,
      sportTeamCategoryStatus: SportTeamStatus.Canceled,
    };

    await updateCategory(sportTeamCategoryId, updatedCategory);
  };
  return (
    <>
      <div className="members-activity-card">
        <div className="activity-details">
          <span className="activity-team-asociation">
            {sportTeam.federationShort ?? sportTeam.federationName ?? "--"}
          </span>
          <div className="activity-team-details">
            <div>
              <SportImage sportId={sportTeam.categoryId ?? 0} />
            </div>
            <div className="activity-info">
              <span className="activity-team-name">{sportTeam.teamName}</span>
              <div className="activity-card-inputs">
                <input
                  className="activity-card-input "
                  readOnly
                  value={sportTeam.categoryShort}
                />
                <input
                  className="activity-card-input "
                  readOnly
                  value={NamesHelper.LetterName(sportTeam.subTeam)}
                />
                {sportTeam.sportTeamCategoryType ===
                  SportTeamCategoryType.Player && (
                  <input
                    className="activity-card-input "
                    readOnly
                    value={
                      sportTeam.position !== undefined
                        ? PositionsType[1][sportTeam.position]?.shortName ?? ""
                        : ""
                    }
                  />
                )}
                {sportTeam.sportTeamCategoryType !==
                  SportTeamCategoryType.Player && (
                  <input
                    className="activity-card-input "
                    readOnly
                    value={NamesHelper.CategoryTypeShort(
                      sportTeam.sportTeamCategoryType
                    )}
                  />
                )}
              </div>
            </div>
            <StatusIcon
              value={sportTeam.sportTeamCategoryStatus as number}
              size="40"
            />
            {sportTeam.sportTeamCategoryStatus === SportTeamStatus.Pending ? (
              <ButtonRemove
                onConfirm={() => handleRemove(sportTeam.sportTeamCategoryId!)}
                itemName="Cancelar asociacion.."
              />
            ) : (
              <div style={{ width: 50 }}></div>
            )}
          </div>
        </div>
        <TShirtShort
          gender={gender}
          sportTeamCategoryType={sportTeam.sportTeamCategoryType!}
          number={sportTeam.shirtNumber}
          color={sportTeam.color1}
        />
      </div>
    </>
  );
};

export default MemberActivity;
