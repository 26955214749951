import { useState } from 'react';

import { apiGet, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import {
  convertToUrlParamiter
} from '../helpers/filterHelper';
import { PageResult } from '../virtualclub/models/base/PageResult';

import { SearchFilters } from '../virtualclub/models/filters/BaseFilters';
import { TournamentView } from '../virtualclub/models/models/tournaments/Tournament.view';
import { TournamentTeamView } from './../virtualclub/models/models/tournamentsTeams/TournamentTeam.view';


export const useTournamentsService = () => {
  const [data, setData] = useState<PageResult<TournamentView>>();
  const [current, setCurrent] = useState<TournamentView>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [tournamentTeams, setTeams] = useState<TournamentTeamView[]>();

  const list = async (
    filters?: SearchFilters<TournamentView>
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = apiRoutes.tournaments + convertToUrlParamiter(filters);
      const result = await apiGet<PageResult<TournamentView>>(url);

      setData(result);
      if (result?.data?.length) {
        select(result.data[0]);
      } else {
        select(undefined);
      }

    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getTeams = async (id: string): Promise<TournamentTeamView[] | undefined> => {
    try {
      const model = await apiGet<any>(
        apiRoutes.tournamentsId.replace(':id', id)
      );
      return model;
    } catch (er) { }
    return undefined;
  };

  const select = async (value?: TournamentView) => {
    setCurrent(value);

    if (value?.tournamentId) {
      const teams = await getTeams(value.tournamentId);
      if (teams) {
        setTeams(teams);
      }
    }
  };

  const update = async (values: TournamentView): Promise<TournamentView | undefined> => {
    const apiUrl = apiRoutes.tournamentsId.replace(":id", values.tournamentId?.toString() ?? "")
    const result = await apiPost<TournamentView, TournamentView>(apiUrl, values);

    if (current?.tournamentId) {
      setData((prevData) => {
        return {
          ...prevData,
          data: (prevData!.data || []).map((row) =>
            row.tournamentId === values.tournamentId ? values : row
          ),
        };
      });
    } else {
      setData((prevData) => ({
        ...prevData,
        data: [values, ...(prevData?.data || [])],
      }));
    }
    setCurrent(values);
    return result;
  }


  return { data, current, isLoading, error, list, select, update, tournamentTeams };
}