import React, { useCallback, useContext, useEffect } from "react";
import { Col, Form, message, Row } from "antd";
import "./TeamCard.css";
import { TeamsContext } from "../../contexts/TeamsContext";
import { Avatar } from "../../components/Avatar/Avatar";
import Input from "../../components/Form/Input/Input";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { SelectSport } from "../../components/lookups/SelectSport";
import { ColorExt } from "../../components/ColorExt";
import { FormItemHidden } from "../../components/Form/FormItem/FormItemHidden";
import { Team } from "../../virtualclub/models/models/teams/Team";
import { TeamFedarationsList } from "./TeamFedarationsList";
import Card from "../../components/Card/Card";
import { ButtonAction } from '../../components/Form/Buttons/ButtonAction';

export const TeamCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, editing, edit, cancel, update, updateAvatar } =
    useContext(TeamsContext);

  useEffect(() => {
    form.setFieldsValue(current);
  }, [current, form]);

  const handlerEditClick = () => {
    edit();
  };

  const handlerCancelClick = () => {
    form.setFieldsValue(current ?? {});
    cancel();
  };

  const handlerSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  async function onFinish(values: any): Promise<void> {
    try {
      const result = (await update(values)) ?? false;
      if (result) {
        messageApi.open({
          type: "success",
          content: "Guardado con exito",
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardado.",
    });
  }

  async function handlerChangeImage(
    file: File,
    fileType: string
  ): Promise<void> {
    if (current?.teamId) {
      await updateAvatar(current?.teamId, file, fileType);
    }
  }

  return (
    <Card>
      {contextHolder}
      <Form
        form={form}
        initialValues={new Team()}
        layout="vertical"
        onFinish={onFinish}
        disabled={!editing}
        className="members-form"
      >
        <FormItemHidden name="teamId" />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            height: 30,
            marginTop: 5,
          }}
        >
          {!editing && <ButtonAction type="edit" onClick={handlerEditClick} />}
          {editing && (
            <ButtonSaveCancel
              onSaveClick={handlerSaveClick}
              onCancelClick={handlerCancelClick}
            />
          )}
        </div>

        <div className="teams-card">
          <div className="teams-card-header">
            <div className="teams-card-avatar">
              <Avatar
                imageId={current?.teamId}
                canChangeImage={current && editing}
                color={current?.color}
                onImageUpload={handlerChangeImage}
              />
            </div>

            <div>
              <Row gutter={16}>
                <Col span={24}>
                  <FormItem
                    label="Nombre"
                    name="teamName"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese el nombre del equipo",
                      },
                    ]}
                  >
                    <Input disabled={!editing} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem label="Nombre Corto" name="teamShort">
                    <Input disabled={!editing} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem
                    label="Deporte"
                    name="sportId"
                    rules={[{ required: true, message: "Ingrese el deporte" }]}
                  >
                    <SelectSport disabled={!editing && !!current?.teamId} />
                  </FormItem>
                </Col>
              </Row>
            </div>
          </div>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem
                textAlign="center"
                label="Fondo"
                name="color"
                labelCol={{ span: 24 }}
              >
                <ColorExt />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem textAlign="center" label="Primario" name="color1">
                <ColorExt />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem textAlign="center" label="Secundario" name="color2">
                <ColorExt />
              </FormItem>
            </Col>
          </Row>
        </div>
      </Form>

      <TeamFedarationsList />
    </Card>
  );
};

{
  /* */
}
