import React from "react";

const CreateSection: React.FC = () => {
  return (
    <section className="create-tournament-section">
      <div className="create-tournament-content">
        <h3>Crea y organiza tu propio torneo</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <button className="btn green">Comenzar ya</button>
      </div>
    </section>
  );
};

export default CreateSection;
