import { apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';

export const useFileService = () => {

  const changeAvatar = async (id: string, file: File, fileType?: string) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const apiUrl = apiRoutes.fileAvatarImage.replace(":id", id)
      const response = await apiPost<FormData, boolean>(
        apiUrl,
        formData,
        "multipart/form-data"
      );
      return response;
    } catch (error) {
      console.error('Error: Image update', error);
    }
    return undefined;
  };

  return { changeAvatar };
}
