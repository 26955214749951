import React from "react";
import MatchStaffList from "../tecnicalStaffComponents/MatchStaffList";
import { PreSelectPlayers } from "./PreSelectPlayers";
import { PreSelectTeamContextProvider } from "./PreSelectTeamContext";
import { MatchView } from "../../../virtualclub/models/models/matches/Match.view";
import { PreSelectedFotter } from './PreSelectedFotter';

interface properties {
  match?: MatchView;
  teamId?: string;
}

export const PreSelectTeamContent: React.FC<properties> = ({ match }) => {
  return (
    <PreSelectTeamContextProvider match={match}>
      <MatchStaffList />
      <PreSelectPlayers />
      <PreSelectedFotter />
    </PreSelectTeamContextProvider>
  );
};
