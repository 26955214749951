import React, { useContext } from "react";
import "../SideRightCard.css";
import { MembersContext } from "../../contexts/MembersContext";
import Label from "../../components/Form/Label/Label";
import { MembersActivityPopup } from "./MembersActivityPopup";
import MemberActivity from "./MemberActivity";
interface properties {}

export const MembersActivities: React.FC<properties> = () => {
  const { current, sportsTeams } = useContext(MembersContext);

  return (
    <div>
      <div style={{ maxHeight: "250px", overflow: "auto" }}>
        {sportsTeams?.length ? (
          sportsTeams.map((record) => (
            <MemberActivity sportTeam={record} gender={current?.gender!} />
          ))
        ) : (
          <Label
            fontSize="14"
            fontWeight="500"
            gray
            style={{ marginBottom: 20 }}
          >
            No hay cuenta asociada
          </Label>
        )}
      </div>
      <MembersActivityPopup />
    </div>
  );
};
