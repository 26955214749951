import React from "react";
import "./FixturePartido.css"; // Estilo CSS para FixturePartido

interface FixturePartidoProps {
  partido: {
    id: number;
    fecha: string;
    clubLocal: {
      id: number;
      nombre: string;
      icono: string;
    };
    clubVisitante: {
      id: number;
      nombre: string;
      icono: string;
    };
  };
}

const FixturePartido: React.FC<FixturePartidoProps> = ({ partido }) => {
  return (
    <div className="fixture-partido">
      <div className="fecha-partido">{partido.fecha}</div>
      <div className="club-local">
        <img src={partido.clubLocal.icono} alt={partido.clubLocal.nombre} />
        <span>{partido.clubLocal.nombre}</span>
      </div>

      <div className="club-visitante">
        <img
          src={partido.clubVisitante.icono}
          alt={partido.clubVisitante.nombre}
        />
        <span>{partido.clubVisitante.nombre}</span>
      </div>
    </div>
  );
};

export default FixturePartido;
