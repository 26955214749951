import React from "react";
import style from "./TableCard.module.css";
import { useMediaQuery } from "react-responsive";

interface properties {
  value: string;
  className?: string;
  isDate?: boolean;
  isTime?: boolean;
  grayText?: boolean;
  textAlign?: "left" | "right" | "center";
  width?: string;
  priority?: 1 | 2 | 3;
}

export const ColText: React.FC<properties> = ({
  value,
  className,
  isDate,
  isTime,
  grayText,
  textAlign = "left",
  width,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;

  const containerStyle = {
    width: width || "100%",
    textAlign: textAlign as "left" | "right" | "center",
  };
  const colStyle = className ?? (grayText ? style.grayText : style.text);
  return (
    <span className={colStyle} style={containerStyle}>
      {isDate && <i className="fa-regular fa-calendar "></i>}
      {isTime && <i className="fa-regular fa-clock "></i>}
      {value}
    </span>
  );
};
