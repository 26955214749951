import React from "react";
import { StatusIcon } from "../Icons/StatusIcon";
import { useMediaQuery } from "react-responsive";

interface properties {
  value: number;
  size?: string;
  className?: string;
  priority?: 1 | 2 | 3;
}

export const ColStatus: React.FC<properties> = ({
  value,
  size,
  className,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const imageSize = {
    width: size || "30px",
    height: size || "30px",
  };

  return (
    <div style={imageSize}>
      <StatusIcon className={className} value={value} size={size} />
    </div>
  );
};
