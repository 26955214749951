import React from "react";

export const GenericPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      {children}
    </div>

  );
};

