import React, { useContext, useMemo } from "react";
import { CheckMatchPlayer } from "./CheckMatchPlayer";
import { SportTeamCategoryType } from '../../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum';
import { CheckMatchContext } from '../CheckMatchContext';
interface properties {
  federationTeamId: string;
}

export const CheckMatchPlayersList: React.FC<properties> = ({ federationTeamId }) => {
  const { getMatchSportTeams } = useContext(CheckMatchContext);

  const playes = useMemo(() => {
    return getMatchSportTeams ? getMatchSportTeams(federationTeamId, SportTeamCategoryType.Player) : [];
  }, [federationTeamId, getMatchSportTeams])

  return (
    <>
      {playes.map((j) => (
        <CheckMatchPlayer
          key={j.matchSportTeamId} value={j}
        />
      ))}
    </>
  );
};
