import React from "react";
import { Tooltip } from "antd"; // Importa Tooltip de Ant Design
import { ReactComponent as ActiveIcon } from "../../assets/icons/statusActive.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/statusPending.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/statusWarning.svg";
import { NamesHelper } from "../../helpers/NamesHelper";

interface Properties {
  value: number;
  size?: string;
  className?: string;
  showCaption?: boolean;
}

export const StatusIcon: React.FC<Properties> = ({
  value,
  size,
  className,
  showCaption = false,
}) => {
  const iconSize = size || "30px";

  return (
    <div className={className}>
      <Tooltip title={NamesHelper.StatusName(value)} placement="leftBottom">
        {value === 1 && (
          <PendingIcon style={{ width: iconSize, height: iconSize }} />
        )}
        {value === 2 && (
          <WarningIcon style={{ width: iconSize, height: iconSize }} />
        )}
        {value === 3 && (
          <ActiveIcon style={{ width: iconSize, height: iconSize }} />
        )}
      </Tooltip>
      {showCaption && <>{NamesHelper.StatusName(value)}</>}
    </div>
  );
};
