import React, { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectItem } from "../Select/SelectItem";
import { SelectExt, SelectExtProps } from "../SelectExt";

export const SelectSportTeamType: React.FC<SelectExtProps> = ({
  value,
  readOnly,
  onChange,
  type,
  ...props
}) => {
  const items: SelectItem[] = [
    { value: -1, label: "Seleccionar tipo de categoria" },
    { value: 1, label: "Jugador" },
    { value: 2, label: "Juez" },
    { value: 3, label: "Director Tecnico" },
    { value: 4, label: "Asistente" },
    { value: 5, label: "Asistente Terapeuticos" },
    { value: 6, label: "Medico" },
    //{ value: 7, label: "Referre" },
  ];

  const [selectedValue, setSelectedValue] = useState(value ?? items[0].value);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  function onChangeValue(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    setSelectedValue(value);
    onChange?.(value, option);
  }

  return (
    <SelectExt
      {...props}
      options={items}
      value={selectedValue}
      onChange={onChangeValue}
      type={type}
    />
  );
};
