import { TShirt } from "../../../components/TShirt/tshirt";
import Popup from "../../../components/Modal/Popup";
import React, { useState } from "react";
import { Gender } from '../../../virtualclub/models/models/members/Genders.enum';

interface properties {
  openModal: boolean;
  gender?: Gender;
  excludeNumbers?: number[];
  lastnames?: string;
  shirtNumber?: number;
  onClose?: () => void;
  onChange?: (value: number) => void;
}

export const ChangeShirtPopup: React.FC<properties> = ({
  shirtNumber = 0,
  gender = 0,
  lastnames,
  onClose,
  onChange,
  openModal,
  excludeNumbers,
}) => {
  const [newShirtNumber, setNewShirtNumber] = useState<number>(shirtNumber);

  const handleChange = () => {
    if (newShirtNumber && !excludeNumbers?.includes(newShirtNumber)) {
      onChange?.(newShirtNumber);
    } else {
      alert(`El número ${newShirtNumber} ya está en uso o es inválido.`);
    }
  };

  return (
    <Popup
      title="Cambiar Camiseta"
      visible={openModal}
      onClose={onClose}
      showCloseButton
      width={300}
    >
      <TShirt
        gender={gender}
        shirtNumber={newShirtNumber}
        name={lastnames}
        color={"white"}
        editableNumber={true}
        onNumberChange={(value) => setNewShirtNumber(value)}
        excludeNumbers={excludeNumbers}
      />
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleChange}
          style={{
            backgroundColor: "#90fb3d",
            color: "white",
            padding: "1px 12px",
            fontSize: "20px",
            borderRadius: "24px",
            cursor: "pointer",
            border: "none",
            outline: "none",
          }}
        >
          Cambiar
        </button>
      </div>
    </Popup>
  );
};
