import React, { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectExt, SelectExtProps, SelectItem } from "../SelectExt";
import { AvatarImage } from "../Images/AvatarImage";
import { useFederationTeamsService } from "../../services/useFederationTeamsService";
import { AccountView } from "../../virtualclub/models/models/accounts/Account.view";

interface properties extends SelectExtProps {
  showAll?: boolean;
  textAll?: string;
}

export const SelectFederation: React.FC<properties> = ({
  showAll = false,
  textAll = showAll ? "Todos las federaciones" : "Seleccione una federacion",
  value,
  readOnly,
  type,
  onChange,
  ...props
}) => {
  const { getFedetations } = useFederationTeamsService();
  const [federations, setFederations] = useState<AccountView[]>([]);
  const [options, setOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    const loadFederations = async () => {
      const data = await getFedetations();
      setFederations(data);

      const its: SelectItem[] =
        data?.map((row) => ({
          value: row.accountId,
          label: (
            <span style={{ display: "flex", alignItems: "center" }}>
              <AvatarImage
                id={row.accountId}
                alt={row.shortName}
                style={{ width: 20, marginRight: 8 }}
              />
              {row.accountName}
            </span>
          ),
        })) ?? [];

      if (showAll) {
        its.unshift({ value: -1, label: textAll });
      }

      setOptions(its);
    };

    loadFederations();
  }, [showAll, textAll]);

  function onChangeValue(
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    const selectedTeam = federations?.find((row) => row.accountId === value);
    onChange?.(selectedTeam, option);
  }

  return (
    <SelectExt
      options={options}
      readOnly={readOnly}
      value={value}
      placeholder={textAll}
      onChange={onChangeValue}
      type={type}
    />
  );
};
