export const PositionsType = [
  [
    {
      shortName: 'NONE',
      name: 'No definido',
      description: '',
      shirtNumber: 0,
    },
  ],
  [
    {
      shortName: 'ARQ',
      name: 'Portero',
      description: 'Defensa central, dentro del área de la portería.',
      shirtNumber: 1,
    },
    {
      shortName: 'DFI',
      name: 'Defensa lateral izquierdo',
      description: 'Lado izquierdo de la defensa.',
      shirtNumber: 2,
    },
    {
      shortName: 'DFD',
      name: 'Defensa lateral derecho',
      description: 'Lado derecho de la defensa.',
      shirtNumber: 3,
    },
    {
      shortName: 'DFC-I',
      name: 'Defensa central izquierdo',
      description:
        'Zona central izquierda de la defensa, frente a la portería.',
      shirtNumber: 4,
    },
    {
      shortName: 'DFC-D',
      name: 'Defensa central derecho',
      description: 'Zona central derecha de la defensa, frente a la portería.',
      shirtNumber: 5,
    },
    {
      shortName: 'MCD',
      name: 'Mediocampista defensivo',
      description: 'Detrás del mediocampo central, cerca de la defensa.',
      shirtNumber: 6,
    },
    {
      shortName: 'ED',
      name: 'Extremo derecho',
      description: 'Lado derecho del ataque.',
      shirtNumber: 7,
    },
    {
      shortName: 'MCC',
      name: 'Mediocampista central',
      description: 'Centro del campo.',
      shirtNumber: 8,
    },
    {
      shortName: 'DLC',
      name: 'Delantero central',
      description: 'Centro del ataque.',
      shirtNumber: 9,
    },
    {
      shortName: 'MCO',
      name: 'Mediocampista ofensivo',
      description: 'Delante del mediocampo central, cerca del ataque.',
      shirtNumber: 10,
    },
    {
      shortName: 'EI',
      name: 'Extremo izquierdo',
      description: 'Lado izquierdo del ataque.',
      shirtNumber: 11,
    },
  ],
];

export interface PositionField {
  shortName: string;
  name: string;
  description: string;
  shirtNumber: number;
}
