import { FederationTeamView } from '../federationsTeams/FederationTeam.view';
import { TeamView } from './Team.View';
import { plainToInstance } from 'class-transformer';

export class Team extends TeamView {
  federations?: FederationTeamView[] = [];

  static fromPlain(plain: Team): Team {
    return plainToInstance(Team, plain);
  }
}
