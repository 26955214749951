import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { SearchFilters } from "../virtualclub/models/filters/BaseFilters";
import { useFederationTeamsService } from "../services/useFederationTeamsService";
import { FederationTeam } from "../virtualclub/models/models/federationsTeams/FederationTeam";

interface Props {
  baseFilters?: SearchFilters<FederationTeam>;
}

export const FederationTeamsContext =
  createContext<FederationTeamsContextValue>({} as FederationTeamsContextValue);

export const FederationContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const { data, current, isLoading, error, list, select } =
    useFederationTeamsService();

  const [filters, setFilters] = useState<SearchFilters<FederationTeam>>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list(filters);
  }, [debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: SearchFilters<FederationTeam>) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = () => {
    select({} as FederationTeam);
    setEditing(true);
  };

  const edit = useCallback(() => {
    if (current && current?.teamId) {
      setEditing(true);
      return true;
    }
    return false;
  }, [current]);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: FederationTeam) => {
      select(value);
    },
    [select]
  );

  const contextValue = useMemo<FederationTeamsContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      cancel,
    }),
    [
      isLoading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
    ]
  );

  return (
    <FederationTeamsContext.Provider value={contextValue}>
      {children}
    </FederationTeamsContext.Provider>
  );
};

export interface FederationTeamsContextValue {
  isLoading?: boolean;
  filters?: SearchFilters<FederationTeam>;
  editing: boolean;
  data?: PageResult<FederationTeam>;
  current?: FederationTeam;

  updateFilters: (filters?: SearchFilters<FederationTeam>) => void;
  gotoPage: (page: number) => void;
  changePageSize: (size: number) => void;

  create: () => void;
  selected: (value: FederationTeam) => void;
  edit: () => boolean;
  cancel: () => void;
}
