import React from "react";
import styles from "./SearchInput.module.css"; // Archivo CSS para estilos personalizados
import { ChangeEvent } from "react";

interface SearchProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<SearchProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  function handlerChange(event: ChangeEvent<HTMLInputElement>): void {
    onChange?.(event.currentTarget.value);
  }

  return (
    <div className={styles.inputContainer}>
      <input
        type="text"
        className={styles.styledInput}
        placeholder={placeholder ?? "Buscar..."}
        value={value}
        onChange={handlerChange}
      />
      <i className={`fa-solid fa-search ${styles.searchIcon}`}></i>
    </div>
  );
};
