import React, { useContext } from "react";
import { MatchField } from "../playersComponents/MatchField";
import { SelectedPlayers } from "./SelectedPlayers";
import { Counter } from "../playersComponents/Counter";
import { AvailablePlayers } from "./AvailablePlayers";
import { PreSelectTeamContext } from "./PreSelectTeamContext";

interface properties {
}

export const PreSelectPlayers: React.FC<properties> = () => {
  const { enabled } = useContext(PreSelectTeamContext);
  return (
    <>
      <div style={{ display: "flex", gap: "20px", marginTop: 15 }}>
        <div style={{ flex: 0.1 }}>
          <MatchField />
        </div>

        <div style={{ flex: 0.5 }}>
          <span style={{ fontWeight: 700, fontSize: 18 }}>
            Lista de Buena Fe
          </span>
          <SelectedPlayers />
        </div>

        {enabled &&
          <div style={{ flex: 0.5 }}>
            <Counter />
            <AvailablePlayers />
          </div>
        }
      </div>
    </>
  );
};
