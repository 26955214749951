import React from "react";
import logo from "./assets/AsociacionLogo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/RouteConstants";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="federation-small-header">
        <a onClick={() => navigate(routes.Login)}>Login</a>
        <i className="fa-solid fa-user"></i>
      </div>
      <header className="federation-large-header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <nav>
          <ul>
            <li>
              <a href="#ligas-asociadas">Ligas Asociadas</a>
            </li>
            <li>
              <a href="#quienes-somos">Quienes Somos</a>
            </li>
            <li>
              <a href="#torneos">Torneos</a>
            </li>
            <li>
              <a href="#crear-torneos">Crear Torneos</a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="federation-header-equipos"></div>
    </div>
  );
};

export default Header;
