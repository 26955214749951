export interface BaseFilters {
  page?: number;
  limit?: number;
  total?: number;
}

export interface SearchFilters<T> extends BaseFilters {
  search?: string;
  project?: Array<keyof T>;
  sort?: Array<{ [K in keyof T]: 'ASC' | 'DESC' }>;
}

export function convertToUrlParamiter(o: any): string {
  const isObject = function (o: any): any {
    return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
  };

  let sep = '?';
  let paramiters = '';

  if (o && isObject(o)) {
    const n: any = {};
    Object.keys(o).forEach((k) => {
      if (o[k] !== undefined) {
        paramiters += `${sep}${k}=${o[k]}`;
        sep = '&';
      }
    });
  }

  return paramiters;
}
