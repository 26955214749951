import React from "react";
import "./TitlePage.css";

interface properties extends React.PropsWithChildren {
  title?: string;
}

export const TitlePage: React.FC<properties> = ({ children, title }) => {
  return (
    <div className="titlepage" >
      <div className="titlepage-title">{title}</div>
      <div className="titlepage-container">
        {children}
      </div>
    </div>
  );
};

