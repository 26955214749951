import React from "react";

import { CategoryList } from "./CategoryList";

import { CategoryContextProvider } from "../../contexts/CategoriesContext";
import { CategoryCard } from './CategoryCard';

const CategoryPage: React.FC = () => {
  return (
    <CategoryContextProvider>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "75%", marginRight: 10, marginTop: 5 }}>
          <CategoryList />
        </div>
        <div style={{ width: "25%" }}>
          <CategoryCard />
        </div>
      </div>
    </CategoryContextProvider>
  );
};

export default CategoryPage;
