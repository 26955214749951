import React from "react";
import logo from "./assets/LogoBlanco.png";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <img src={logo} alt="Logo" className="footer-logo" />
      </div>
      <div className="footer-column">
        <span>Únete a Nosotros</span>
        <ul>
          <li>Organiza tu Torneo</li>
          <li>Inscribirse en un Torneo</li>
        </ul>
      </div>
      <div className="footer-column">
        <span>Contacto Directo</span>
        <p>email@example.com</p>
      </div>
    </footer>
  );
};

export default Footer;
