import React from "react";
import TitleText from "./TitleText";

import "./FixtureList.css"; // Estilo CSS para FixtureList
import FixturePartido from "./FixturePartido";

interface Partido {
  id: number;
  fecha: string;
  clubLocal: {
    id: number;
    nombre: string;
    icono: string;
  };
  clubVisitante: {
    id: number;
    nombre: string;
    icono: string;
  };
}

interface FixtureListProps {
  fechaEncuentros: string; // Fecha de los encuentros
  torneoNum: number; // Número del torneo
  ruedaNum: number; // Número de rueda
  partidos: Partido[]; // Array de partidos
}

const FixtureList: React.FC<FixtureListProps> = ({
  fechaEncuentros,
  torneoNum,
  ruedaNum,
  partidos,
}) => {
  return (
    <div className="fixture-list">
      <TitleText text="Fixture" />
      <div className="fecha-info">
        <div className="fecha-grande">{fechaEncuentros}</div>
        <div className="info-chica">
          Torneo {torneoNum}, Rueda {ruedaNum}
        </div>
      </div>
      <div className="partidos">
        {partidos.map((partido) => (
          <FixturePartido key={partido.id} partido={partido} />
        ))}
      </div>
    </div>
  );
};

export default FixtureList;
