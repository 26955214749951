import React from "react";
import "./Popup.css";
import { Button } from "antd";
import { ButtonAction } from "../Form/Buttons/ButtonAction";

type ModalType = "primary" | "success" | "info" | "warning" | "error";

interface properties {
  type?: ModalType;
  visible?: boolean;
  title?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  width?: number;
  onClose?: () => void;
  onBack?: () => void;
}

const Popup: React.FC<React.PropsWithChildren<properties>> = ({
  type = "primary",
  title,
  visible,
  width = 400,
  showCloseButton = true,
  showBackButton = false,
  onClose,
  onBack,
  children,
}) => {
  const getModalStyle = () => {
    switch (type) {
      case "success":
        return "modal--success";
      case "info":
        return "modal--info";
      case "warning":
        return "modal--warning";
      case "error":
        return "modal--error";
      default:
        return "";
    }
  };

  if (!visible) return null;

  return (
    <div className="modal-overlay">
      <div className={`modal ${getModalStyle()}`} style={{ width }}>
        <div className="modal-header">
          {showBackButton && onBack && (
            <ButtonAction type="back" onClick={onBack} tooltip="Volver" />
          )}
          <span className="modal-title">{title}</span>
          {showCloseButton && (
            <ButtonAction type="cancel" onClick={onClose} tooltip="Cerrar" />
          )}
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
