import { apiGet } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import { Account } from '../virtualclub/models/models/accounts/Account';

export class MyAccountService {

  static async get(): Promise<Account | undefined> {
    try {
      const model = await apiGet<any>(apiRoutes.myAccount);
      return model;
    } catch (er) { }
    return undefined;
  };

  // static async getTeams(): Promise<CuentaEquipo[] | undefined> {
  //   try {
  //     const model = await apiGet<CuentaEquipo[]>(apiRoutes.miCuentaEquipos);
  //     return model;
  //   } catch (er) { }
  //   return undefined;
  // };

}