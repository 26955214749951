import React from "react";
import "./FormItem.css";
import { ColProps, Form } from "antd";
import { Rule } from "antd/es/form";

interface properties {
  label?: string;
  name?: string;
  disabled?: boolean;
  noStyle?: boolean;
  rules?: Rule[];
  labelCol?: ColProps | undefined;
  textAlign?: "center" | "left" | "right";
}

export const FormItem: React.FC<React.PropsWithChildren<properties>> = (
  { children, noStyle, label, disabled, name, textAlign = "left", ...props },
  ref
) => {
  return (
    <div className="formitem" style={{ textAlign }}>
      <span className="label">{label}</span>
      <Form.Item name={name} noStyle={noStyle} labelCol={props.labelCol}>
        {children}
      </Form.Item>
    </div>
  );
};
