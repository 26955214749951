import { useState } from 'react';
import { apiGet, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import {
  convertToUrlParamiter
} from '../virtualclub/models/filters/BaseFilters';
import { PageResult } from '../virtualclub/models/base/PageResult';
import { FederationTeam } from '../virtualclub/models/models/federationsTeams/FederationTeam';
import { FederationTeamUpdated } from '../virtualclub/models/models/federationsTeams/FederationTeam.updated';
import { FederationTeamFederate } from '../virtualclub/models/models/federationsTeams/FederationTeam.federate';
import { FederationTeamFilters } from '../virtualclub/models/models/federationsTeams/FederationTeam.filters';
import { AccountView } from '../virtualclub/models/models/accounts/Account.view';
import { FederationTeamAssociate, FederationTeamAssociateRequest } from '../virtualclub/models/models/federationsTeams/FederationTeam.associate';

export const useFederationTeamsService = () => {
  const [data, setData] = useState<PageResult<FederationTeam>>();
  const [current, setCurrent] = useState<FederationTeam>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const list = async (filters?: FederationTeamFilters) => {
    setIsLoading(true);
    setError(null);
    try {
      const queryString = convertToUrlParamiter(filters);
      const apiUrl = `${apiRoutes.federationsTeams}${queryString}`;
      const result = await apiGet<PageResult<FederationTeam>>(apiUrl);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const getFedetations = async (): Promise<AccountView[]> => {
    try {
      const f: FederationTeamFilters = { limit: 50, project: ["federationTeamId", "federation"] };
      const queryString = convertToUrlParamiter(f);
      const apiUrl = `${apiRoutes.federationsTeams}${queryString}`;
      const result = await apiGet<PageResult<FederationTeam>>(apiUrl);
      if (result?.data) {
        const uniqueData = Array.from(new Map(result?.data.map(item => [item.federation?.accountId, item])).values());

        const ret: AccountView[] = [];
        uniqueData.map(x => {
          if (x.federation) {
            ret.push(x.federation);
          }
        });
        return ret;
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
    }
    return [];
  }

  const select = (value?: FederationTeam) => {
    setCurrent(value);
  }

  const federate = async (model: FederationTeamFederate) => {
    const result = await apiPost<FederationTeamFederate, FederationTeamUpdated>(apiRoutes.federationsTeamsFederate, model);
    return result?.federationTeamId;
  }

  const associate = async (model: FederationTeamAssociate) => {
    const result = await apiPost<FederationTeamAssociate, FederationTeamAssociateRequest>(apiRoutes.federationsTeamsAssociate, model);
    return result;
  }

  return { data, current, isLoading, error, list, select, federate, associate, getFedetations };
}

