import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";
import { SportsTeamsFilters } from "../virtualclub/models/models/sportsTeams/SportsTeams.filters";
import { useSportsTeamsService } from "../services/useSportsTeamsService";
import { SportTeamView } from "../virtualclub/models/models/sportsTeams/SportTeam.view";
interface Props {
  baseFilters?: SportsTeamsFilters;
}

export const SportsTeamsContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const { data, current, isLoading, error, list, select, getId } =
    useSportsTeamsService();

  const [filters, setFilters] = useState<SportsTeamsFilters>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list({ ...filters, ...baseFilters });
  }, [baseFilters, debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: SportsTeamsFilters) => {
    console.log(newFilters);
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = () => {
    // setCurrent({} as TeamMembers);
    // setEditing(true);
  };

  const edit = useCallback(() => {
    // if (current && current?.socioId) {
    //   setEditing(true);
    //   return true;
    // }
    return false;
  }, []);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: SportTeamView) => {
      select(value);
    },
    [select]
  );

  const contextValue = useMemo<SportsTeamsContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      selected,
      updateFilters,
      gotoPage,
      changePageSize,
      create,
      edit,
      cancel,
      getId,
    }),
    [
      isLoading,
      filters,
      data,
      current,
      editing,
      selected,
      updateFilters,
      gotoPage,
      changePageSize,
      edit,
      getId,
    ]
  );

  return (
    <SportsTeamsContext.Provider value={contextValue}>
      {children}
    </SportsTeamsContext.Provider>
  );
};

export const SportsTeamsContext = createContext<SportsTeamsContextValue>(
  {} as SportsTeamsContextValue
);

export interface SportsTeamsContextValue {
  isLoading?: boolean;
  filters?: SportsTeamsFilters;
  editing: boolean;
  data?: PageResult<SportTeamView>;
  current?: SportTeamView;

  updateFilters?: (filters?: SportsTeamsFilters) => void;
  gotoPage?: (page: number) => void;
  changePageSize?: (size: number) => void;

  create?: () => void;
  selected?: (value: SportTeamView) => void;
  edit?: () => boolean;
  cancel?: () => void;
  update?: (value: SportTeamView) => Promise<boolean>;
  getId: (id: string) => Promise<SportTeamView | undefined>;
}
