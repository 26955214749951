import React, { createContext, useCallback, useMemo, useState } from "react";

import { useAsyncCall } from "../../hooks/useAsyncCall";

import { CuentaService } from "../../services/core/CuentaService";
import { Account } from "../../virtualclub/models/models/accounts/Account";

interface Props {
  externalId: string;
}

export const PublicFederationContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, externalId }) => {
  const [account, setAccount] = useState<Account>();

  const loadData = useCallback(async (): Promise<void> => {
    /* const newResult = await CuentaService.getPublic(externalId);
        if (newResult) {
            setAccount(newResult);
        }*/
  }, [externalId]);

  const loader = useAsyncCall(loadData, []);

  const contextValue = useMemo<PublicFederationContextValue>(
    () => ({
      externalId,
      account,
      loading: loader.loading,
    }),
    [externalId, account, loader.loading]
  );

  return (
    <PublicFederationContext.Provider value={contextValue}>
      {children}
    </PublicFederationContext.Provider>
  );
};

export const PublicFederationContext =
  createContext<PublicFederationContextValue>(
    {} as PublicFederationContextValue
  );

export interface PublicFederationContextValue {
  externalId: string;

  loading?: boolean;
}
