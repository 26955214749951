import React, { useCallback, useContext, useEffect } from 'react';
import { Col, Form, message, Row } from 'antd';
import { CategoryContext } from '../../contexts/CategoriesContext';
import { ButtonSaveCancel } from '../../components/Form/Buttons/ButtonSaveCancel';
import Card from '../../components/Card/Card';
import { FormItem } from '../../components/Form/FormItem/FormItem';
import { FormItemHidden } from '../../components/Form/FormItem/FormItemHidden';
import { Category } from '../../virtualclub/models/models/categories/Category';
import Input from '../../components/Form/Input/Input';
import { SelectSport } from '../../components/lookups/SelectSport';
import { ButtonAction } from '../../components/Form/Buttons/ButtonAction';

export const CategoryCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, edit, cancel, editing, update } =
    useContext(CategoryContext);

  useEffect(() => {
    console.log(current);
    form.setFieldsValue(current ?? {});
  }, [current, form]);

  const handlerEditClick = () => {
    edit();
  };
  const handlerSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const handlerCancelClick = () => {
    form.setFieldsValue(current ?? {});
    cancel();
  };

  async function onFinish(values: any): Promise<void> {
    try {
      const result = (await update(values)) ?? false;
      if (result) {
        console.log(1);
        messageApi.open({
          type: 'success',
          content: 'Guardado con exito',
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    messageApi.open({
      type: 'error',
      content: 'Error al guardado.',
    });
  }

  return (
    <Card>
      {contextHolder}

      <Form
        form={form}
        initialValues={new Category()}
        layout='vertical'
        onFinish={onFinish}
        disabled={!editing}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            height: 30,
            marginTop: 5,
          }}
        >
          {!editing && <ButtonAction type='edit' onClick={handlerEditClick} />}
          {editing && (
            <ButtonSaveCancel
              onSaveClick={handlerSaveClick}
              onCancelClick={handlerCancelClick}
            />
          )}
        </div>
        <FormItemHidden name='categoryId' />

        <Row gutter={16}>
          <Col span={24}>
            <FormItem label='Nombre de Categoria' name='categoryName' noStyle>
              <Input
                style={{ fontSize: 20 }}
                placeholder='Categoria'
                disabled={!editing}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label='Deporte'
              name='sportId'
              rules={[{ required: true, message: 'Ingrese el deporte' }]}
            >
              <SelectSport disabled={!editing && !current?.categoryId} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col span={6}>
            <FormItem
              label='Código'
              name='categoryCode'
              rules={[{ required: true, message: 'Ingrese el código' }]}
            >
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label='Nombre Corto'
              name='categoryShort'
              rules={[{ required: true, message: 'Ingrese el nombre corto' }]}
            >
              <Input disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label='Orden' name='categoryOrder'>
              <Input disabled={!editing} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={4}>
            <FormItem label='Juez' name='judges'>
              <Input min={0} disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label='DT' name='technicalDirector'>
              <Input min={0} disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label='PF' name='physicalTrainer'>
              <Input min={0} disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label='Asistente' name='assistants'>
              <Input min={0} disabled={!editing} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label='Arbitro' name='referees'>
              <Input min={0} disabled={!editing} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
