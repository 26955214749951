import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { configApp } from './configApp';
import { LocalStorageService } from './LocalStorageService';
import { HttpResponse } from '../virtualclub/utils/helpers/HttpResponses';

// export enum ApplicationName {
//   Core,
//   SAS
// }

export enum HttpMethod {
  Delete,
  Get,
  Patch,
  Post,
  Put,
}

export class ErrorApiException extends Error {
  error: APIErrorResponse;
  constructor(error: APIErrorResponse, message?: string) {
    super(message);
    this.error = error;
  }
}

const getResponse = async <ResT, ReqT>(
  httpMethod: HttpMethod,
  relativeUrl: string,
  data?: ReqT,
  contentType?: 'application/json' | 'multipart/form-data'
): Promise<ResT | undefined> => {
  const fullUrl = configApp.apiUrl + relativeUrl;
  console.log("apiUrl", configApp.apiUrl)
  // let headers: AxiosRequestConfig = {};
  const token = LocalStorageService.get('token');
  const headers = {
    headers: {
      'Content-Type': contentType ?? 'application/json',
      Authorization: '',
    },
    crossdomain: true,

  };
  axios.defaults.withCredentials = true;

  if (token) {
    headers.headers.Authorization = `bearer ${token}`;
  }
  switch (httpMethod) {
    case HttpMethod.Get:
      try {
        const responseGet: any = await axios.get<APIResponse<ResT>>(
          fullUrl,
          headers
        );
        if (responseGet?.data.result === "success") {
          return responseGet?.data?.data;
        }
        return responseGet?.data;
      } catch (error: any) {
        handleError(error.response, 'GET');
      }
      break;

    case HttpMethod.Post:
      try {
        const responsePost = await axios.post<ReqT, HttpResponse<ResT>>(
          fullUrl,
          data,
          headers
        );
        if (responsePost?.data) {
          return responsePost.data.data ? responsePost.data.data : responsePost.data;
        }
      } catch (error: any) {
        handleError(error.message, 'POST', fullUrl, data as any);
      }
      break;

    case HttpMethod.Patch:
      try {
        const responsePatch = await axios.patch<
          ReqT,
          AxiosResponse<APIDataResponse<ResT>>
        >(fullUrl, data, headers);

        if (responsePatch?.data) {
          return responsePatch.data.data ? responsePatch.data.data as ResT : responsePatch.data as ResT;
        }
      } catch (error: any) {
        handleError(error.response, 'PATCH', fullUrl, data as any);
      }
      break;

    case HttpMethod.Delete:
      try {
        const responseDelete = await axios.delete<
          ReqT,
          AxiosResponse<APIDataResponse<ResT>>
        >(fullUrl, headers);
        if (responseDelete?.data) {
          return responseDelete.data.data ? responseDelete.data.data as ResT : responseDelete.data as ResT;
        }
      } catch (error: any) {
        handleError(error.response, 'DELETE', fullUrl, data as any);
      }
      break;

    // TODO: Validate return types for delete
    // case HttpMethod.Delete: {
    //   const response = await axios.delete<APIDataResponse<ResT>>(fullUrl, headers);
    //   return response.data.data;
    // }

    default: {
      return Promise.reject('Invalid HTTP Method ' + httpMethod);
    }
  }

  return undefined;
};
const handleError = (
  error: APIErrorResponse,
  method: string,
  fullUrl = '',
  data = {}
): void => {
  console.log(error);
  console.error(
    `Error in request. Error Code: ${error} URL requested: ${fullUrl} Payload: ${JSON.stringify(
      data
    )}`
  );
  throw new ErrorApiException(
    error,
    `Error in ${method} request. Error Code: ${error.status}. Message: ${error?.data?.message}`
  );
};


export const apiPost = async <ReqT, ResT>(
  url: string,
  data?: ReqT,
  contentType?: 'application/json' | 'multipart/form-data'
): Promise<ResT | undefined> =>
  await getResponse(HttpMethod.Post, url, data, contentType);
export const apiPatch = async <ReqT, ResT>(
  url: string,
  data?: ReqT
): Promise<ResT | undefined> => await getResponse(HttpMethod.Patch, url, data);
export const apiGet = async <ResT>(url: string): Promise<ResT | undefined> =>
  await getResponse(HttpMethod.Get, url, undefined);
export const apiDelete = async <ResT>(url: string): Promise<ResT | undefined> =>
  await getResponse(HttpMethod.Delete, url);

export const apiGetArrayBuffer = async (
  relativeUrl: string
): Promise<ArrayBuffer> => {
  const url = configApp.apiUrl + relativeUrl;
  const token = LocalStorageService.get('token');
  const headers: AxiosRequestConfig = {
    headers: {
      // "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    responseType: 'arraybuffer',
  };
  const response = await axios.get(url, headers);
  return response.data;
};

export enum Status {
  Loading = 0,
  Loaded,
  Error,
  Unauthorized,
}

export type Service<T> = {
  status: Status;
  payload?: T;
  error?: Error;
};

// TODO: Commented until better passing and handling of APIMessageResponse is implemented.
export type APIResponse<T> = /* APIMessageResponse |*/
  | APIDataResponse<T>
  | APIErrorResponse;

export type APIErrorResponse = {
  data?: {
    message: string;
    result: 'error';
  };
  status: number;
  statusText: string;
  config: {
    data?: any;
    method: string;
    url: string;
  };
};

export type APIMessageResponse = {
  result: 'success';
  message: string;
};

export type APIDataResponse<T> = {
  result: 'success';
  data: T;
};

export type APIDataResponsePaginated<T> = APIDataResponse<T> & {
  size: number;
  totalCount: number;
  offset: number;
  nextUrl?: string;
};
