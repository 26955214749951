import React from "react";
import { SportImage } from "../Images/SportImage";
import { useMediaQuery } from "react-responsive";

interface properties {
  value?: number;
  className?: string;
  priority?: 1 | 2 | 3;
}

export const ColSport: React.FC<properties> = ({
  value,
  className,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  return (
    <SportImage sportId={value} style={{ width: "25px", height: "25px" }} />
  );
};
