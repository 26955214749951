import React from "react";
import { ColTag } from "./ColTag";
import { useMediaQuery } from "react-responsive";

interface properties {
  value?: string;
  onClick?: () => void;
  priority?: 1 | 2 | 3;
}

export const ColShirt: React.FC<properties> = ({
  value,
  onClick,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  let num = !value ? "--" : value;
  num = num?.toString().length < 2 ? `0${num}` : num;
  return (
    <ColTag value={num} width={"40px"} onClick={onClick} />
    // <div style={{ height: "40px", width: "40px" }}>
    //   {num}
    //   {/* <Camiseta number={num} /> */}
    // </div>
  );
};
