import { apiGet, apiPost } from '../BaseService';
import { apiRoutes } from '../../constants/ApiRouteConstants';
import { ICategoria } from '../../virtualclub/models/models/_obsolete/categorias/ICategoria';
import { jugadorCategoria } from '../../virtualclub/models/models/_obsolete/categorias/JugadorCategoria';
import { PageResult } from '../../virtualclub/models/base/PageResult';
import { SearchFilters, convertToUrlParamiter } from '../../virtualclub/models/filters/BaseFilters';

export class CategoriasService {


  async listar(filters: SearchFilters<ICategoria>): Promise<PageResult<ICategoria> | undefined> {
    try {
      const url = apiRoutes.categorias + convertToUrlParamiter(filters);
      const models = await apiGet<PageResult<ICategoria>>(url);
      return models;
    } catch (err) {
      console.log(err);
    }
    return undefined;
  }


  async getCategorias(cuentaId: string, deporte: string): Promise<ICategoria[] | undefined> {
    try {
      const apiUrl = `${apiRoutes.categorias}/${cuentaId}/${deporte}`;
      const result = await apiGet<ICategoria[]>(apiUrl);

      return result;
    } catch (err) {
      console.log(err);
    }
    return undefined;
  }

  async update(model: jugadorCategoria): Promise<jugadorCategoria | undefined> {
    try {
      const response = await apiPost<jugadorCategoria, jugadorCategoria>(`${apiRoutes.categoriasUpdateJugador}`, model);
      return response;
    } catch (error) {
      console.error('Error updating jugador category:', error);
      return undefined;
    }
  }
}