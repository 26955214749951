import { Pagination, Radio, Spin } from "antd";
import { useContext, useState } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { TournamentsContext } from "../../contexts/TournamentsContext";
import TournamentRowCard from "./TournamentCard/TournamentRowCard";

interface Properties {
  title?: string;
}

export const TournamentsList: React.FC<Properties> = ({ title }) => {
  const {
    isLoading: searching,
    data,
    filters,
    updateFilters,
    selected,
    gotoPage,
    current,
  } = useContext(TournamentsContext);

  const [viewMode, setViewMode] = useState<"list" | "card">("list");

  function handleSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handleSort(value: string): void {
    // updateFilters?.({ sort: value });
  }

  function handleChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }

  const handleRowClick = (tournamentId: string) => {
    const tournament = data?.data?.find(
      (t) => t.tournamentId?.toString() === tournamentId
    );
    if (tournament) {
      selected(tournament);
    }
  };
  const containerStyle: React.CSSProperties = {
    animation: "fadeInOut 0.5s ease-in-out",
    width: "100%",
    marginLeft: 20,
  };

  return (
    <Spin spinning={searching}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          marginBottom: 5,
        }}
      >
        <div style={{ flex: 1, fontWeight: 400 }}>
          {title ?? "Listado de torneos"}
        </div>
        <div style={{ flex: 1 }}>
          <SearchInput value={filters?.search} onChange={handleSearch} />
        </div>
        <div>
          <Radio.Group
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <Radio.Button value="list">
              <i className="fa-solid fa-list"></i>
            </Radio.Button>
            <Radio.Button value="card">
              <i className="fa-solid fa-grid-2"></i>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className="small-container">
        {viewMode === "list" ? (
          data?.data?.map((record) => (
            <Row
              key={record.tournamentId}
              id={record.tournamentId ?? ""}
              onClick={() => handleRowClick(record.tournamentId ?? "")}
              active={record.tournamentId === current?.tournamentId}
              gender={record.gender}
            >
              <ColText
                value={record.tournamentCode ?? ""}
                grayText
                width="100px"
              />
              <ColText value={record.tournamentName ?? ""} />
            </Row>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              overflowY: "auto",
              whiteSpace: "nowrap",
              gap: "1rem",
            }}
          >
            {data?.data?.map((record) => (
              <TournamentRowCard
                key={record.tournamentId}
                tournament={record}
              />
            ))}
          </div>
        )}
      </div>

      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handleChangePage}
        />
      )}
    </Spin>
  );
};
