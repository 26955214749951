export const routeRoots = {
    matches: '/management/matches',
    match: '/match',
    teams: '/management/teams',
    teamsTactics: '/management/tactics',
    federationTeams: '/management/federationteams',
    players: '/management/players',
    members: '/management/members',
    technicalStaff: '/management/technicalstaff',
    categories: '/management/categories',
    tournaments: '/management/tournaments',
};


export const routes = {
    // ----------PUBLIC----------------
    Home: '/',
    Login: '/login',
    PublicFederation: `/:externalId`,

    // ----------ADMIN----------------
    MyAccount: `/management/MyAccount`,
    Matches: `${routeRoots.matches}/`,
    Match: `${routeRoots.matches}/:id`,
    MatchTimerManager: `${routeRoots.matches}/:matchId/cronus`,
    Players: `${routeRoots.players}/`,
    TechnicalStaff: `${routeRoots.technicalStaff}/`,
    Members: `${routeRoots.members}/`,
    Teams: `${routeRoots.teams}/`,
    TeamsTactics: `${routeRoots.teamsTactics}/`,
    FederationTeams: `${routeRoots.federationTeams}/`,
    Categories: `${routeRoots.categories}`,
    Tournaments: `${routeRoots.tournaments}`,
    Tournament: `${routeRoots.tournaments}/:id`,
}