import React from "react";
import "./MyAccount.css";

import AccountForm from "./AccountForm";
import AccountUsers from "./AccountUsers";
import AccountSubscriptions from "./AccountSubscriptions";

const MyAccountScreen: React.FC = () => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "60%", marginRight: 10 }}>
        <AccountForm />
        <AccountUsers />
      </div>
      <div style={{ width: "40%" }}>
        <AccountSubscriptions />
      </div>
    </div>
  );
};

export default MyAccountScreen;
