import React, { useState } from "react";
import logo from "../../../public/mainLogoV.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/RouteConstants";
import LoginPopup from "./LoginPopup";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className="small-header">
        <a onClick={showModal}>Ingresar</a>
        <i className="fa-solid fa-user"></i>
      </div>
      <header className="large-header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <nav>
          <ul>
            <li>
              <a href="#ligas-asociadas">Ligas Asociadas</a>
            </li>
            <li>
              <a href="#quienes-somos">Quienes Somos</a>
            </li>
            <li>
              <a href="#torneos">Torneos</a>
            </li>
            <li>
              <a href="#crear-torneos">Crear Torneos</a>
            </li>
          </ul>
        </nav>
      </header>
      <LoginPopup visible={isModalVisible} onClose={handleClose} />
    </div>
  );
};

export default Header;
