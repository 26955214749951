import React, { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { PositionsType } from "../../virtualclub/models/interfaces/PositionsType";
import { SelectExt, SelectExtProps, SelectItem } from "../SelectExt";
import styles from "./Selects.module.css";

interface properties extends SelectExtProps {
  sportId?: number;
  showAll?: boolean;
  textAll?: string;
  isFilter?: boolean;
}

export const SelectPosition: React.FC<properties> = ({
  sportId = 1,
  showAll = true,
  textAll = "--",
  value,
  readOnly,
  type,
  onChange,
  isFilter = false,
  ...props
}) => {
  const [items, setItems] = useState<SelectItem[]>([]);

  useEffect(() => {
    const posiciones = PositionsType[sportId];
    let its: SelectItem[] = [];

    if (posiciones) {
      if (showAll) {
        its.push({ value: -1, label: textAll });
      }
      its = its.concat(
        posiciones.map((posicion, index) => ({
          value: index,
          label: posicion.shortName,
        }))
      );
      setItems(its);
    }
  }, [sportId, showAll, textAll]);

  const onChangeValue = (
    newValue: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void => {
    onChange?.(newValue, option);
  };

  return (
    <SelectExt
      readOnly={readOnly}
      options={items}
      value={value}
      placeholder={textAll}
      onChange={onChangeValue}
      type={type}
      className={isFilter ? styles.isFilter : ""}
      {...props}
    />
  );
};
