import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Layout,
  Space,
  Spin,
  message,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginScreen.css";

import { AuthRequest } from "../../../virtualclub/models/models/login/AuthRequest";
import { routes } from "../../../constants/RouteConstants";
import { PublicService } from "../../../services/PublicService";
import Label from "../../../components/Form/Label/Label";

export interface LoginProps {
  urlRedirect?: string;
}

export const LoginScreen: React.FC<LoginProps> = ({ urlRedirect }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  const enterFullscreen = () => {
    const elem = document.documentElement; // El elemento raíz de la página

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).mozRequestFullScreen) {
      // Firefox
      (elem as any).mozRequestFullScreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      // Chrome, Safari y Opera
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
      // IE/Edge
      (elem as any).msRequestFullscreen();
    }
  };

  // Función para salir del modo pantalla completa
  const exitFullscreen = () => {
    if (
      document.fullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        // Firefox
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        // Chrome, Safari y Opera
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        // IE/Edge
        (document as any).msExitFullscreen();
      }
    }
  };

  const onFinish = async (values: AuthRequest): Promise<void> => {
    setLoading(true);
    setHasError(false);
    try {
      const userInfo = await PublicService.Authenticate(values);

      if (userInfo) {
        enterFullscreen();
        navigate(routes.Matches);
      }
    } catch (err) {
      console.log(err);
      messageApi.open({
        type: "error",
        content: "Verifique el usuario y o la contraseña.",
      });
    }
    setLoading(false);
  };
  return (
    <Layout className="login-container">
      <div className="login-content">
        <Card className="login-card">
          <Spin spinning={loading} delay={500}>
            {contextHolder}
            <Space direction="vertical" size={"large"}>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                layout="vertical"
                onFinish={onFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Ingrese su nombre de usuario" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email / nombre de usuario"
                  />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: "Ingrese su contraseña" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Contraseña"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Recordar</Checkbox>
                  </Form.Item>

                  <a className="login-label" href="">
                    Olvide mi contraseña
                  </a>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Ingresar
                  </Button>
                  O{" "}
                  <a className="login-label" href="">
                    Registrar ahora
                  </a>
                </Form.Item>
              </Form>
            </Space>
          </Spin>
        </Card>
      </div>
    </Layout>
  );
};
