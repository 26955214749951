import React from "react";
import { MatchesContextProvider } from "../../contexts/MatchesContext";
import { MatchesList } from "./MatchesList";

const MatchesPage: React.FC = () => {
  return (
    <MatchesContextProvider>
      <MatchesList />
    </MatchesContextProvider>
  );
};

export default MatchesPage;
