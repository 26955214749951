import React, { useState } from "react";
import { PlayersList } from "./PlayersList";
import { SportsTeamsContextProvider } from "../../contexts/SportsTeamsContext";
import { PlayerCard } from "./PlayerCard";
import { GenericPage } from "../../components/Screen/GenericPage";
import { ContentPage } from "../../components/Screen/ContentPage";
import { SideRightPage } from "../../components/Screen/SideRightPage";
import { PlayersSearch } from "./PlayersSearch";

const PlayersScreen: React.FC = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleSelect = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <SportsTeamsContextProvider baseFilters={{ isPlayer: true }}>
      <GenericPage>
        <ContentPage>
          <PlayersSearch />
          <PlayersList onSelect={handleSelect} />
        </ContentPage>
        <SideRightPage
          isDrawerVisible={isDrawerVisible}
          onCloseDrawer={handleCloseDrawer}
        >
          <PlayerCard />
        </SideRightPage>
      </GenericPage>
    </SportsTeamsContextProvider>
  );
};

export default PlayersScreen;
