import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { CssBaseline, GeistProvider } from "@geist-ui/react";
import { BrowserRouter } from "react-router-dom";
import "./public/CustomTable.css";
import "./public/fonts.css";

const rootEl = document.createElement("div");
document.body.appendChild(rootEl);
const root = createRoot(rootEl);
root.render(
  <GeistProvider>
    <CssBaseline />
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GeistProvider>
);
