import React from "react";
import style from "./TableCard.module.css";
import Logo from "../../assets/VC.svg";
import { ColImage } from "./ColImage";
import { ColTeam } from "./ColTeam";
import { ColText } from "./ColText";
import { configApp } from "../../services/configApp";
import { FederationTeamView } from "../../virtualclub/models/models/federationsTeams/FederationTeam.view";
import { useMediaQuery } from "react-responsive";

interface properties {
  hometeam?: FederationTeamView;
  awayTeam?: FederationTeamView;
  priority?: 1 | 2 | 3;
}

export const ColMatch: React.FC<properties> = ({
  hometeam,
  awayTeam,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  return (
    <div className={style.matchContainer}>
      <div
        className={style.matchColorLeft}
        style={
          {
            "--homeColor1": hometeam?.team?.color1 || "defaultColor",
            "--homeColor2": hometeam?.team?.color || "defaultColor",
          } as React.CSSProperties
        }
      ></div>
      <ColImage
        src={`${configApp.avatarUrl}${hometeam?.team?.teamId}.png`}
        alt="Equipo Local"
        size="100px"
      />

      <ColTeam
        teamName={hometeam?.team?.teamShort ?? ""}
        subTeam={hometeam?.subTeam}
        textAlign="left"
      />

      <ColText value="VS" width="150px" textAlign="center" />

      <ColTeam
        teamName={awayTeam?.team?.teamShort ?? ""}
        subTeam={awayTeam?.subTeam}
        textAlign="right"
      />
      <ColImage
        src={`${configApp.avatarUrl}${awayTeam?.team?.teamId}.png`}
        alt="Equipo Visitante"
        size="100px"
      />
      <div
        className={style.matchColorRight}
        style={
          {
            "--awayColor1": awayTeam?.team?.color1 || "defaultColor",
            "--awayColor2": awayTeam?.team?.color || "defaultColor",
          } as React.CSSProperties
        }
      ></div>
    </div>
  );
};
