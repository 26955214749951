import React, { useEffect, useState, ReactNode } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectItem } from "../Select/SelectItem";
import { SelectExt, SelectExtProps } from "../SelectExt";
import { ReactComponent as MaleIcon } from "../../assets/maleIcon.svg";
import { ReactComponent as FemaleIcon } from "../../assets/femaleIcon.svg";
import "./SelectGenre.css";

export const SelectGenre: React.FC<SelectExtProps> = ({
  value,
  readOnly,
  onChange,
  type,
  disabled,
  ...props
}) => {
  const items: { value: string | number; label: ReactNode }[] = [
    { value: "", label: "Todos los géneros" },
    {
      value: 0,
      label: (
        <>
          <FemaleIcon style={{ color: "pink", width: "1em", height: "1em" }} />{" "}
        </>
      ),
    },
    {
      value: 1,
      label: (
        <>
          <MaleIcon style={{ color: "blue", width: "1em", height: "1em" }} />{" "}
        </>
      ),
    },
  ];

  const [selectedValue, setSelectedValue] = useState<string | number>(
    value ?? items[0].value
  );

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  function onChangeValue(
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    setSelectedValue(value);
    onChange?.(value, option);
  }

  return (
    <SelectExt
      {...props}
      options={items}
      disabled={disabled}
      value={selectedValue}
      onChange={onChangeValue}
      type={type}
      className="select-genre"
    />
  );
};
