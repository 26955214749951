import React, { useState } from "react";
import { NamesHelper } from "../../helpers/NamesHelper";
import { FederationTeamView } from "../../virtualclub/models/models/federationsTeams/FederationTeam.view";
import { ButtonRemove } from "../../components/Form/Buttons/ButtonRemove";
import { configApp } from "../../services/configApp";
import { FederationTeamStatus } from "../../virtualclub/models/models/federationsTeams/FederationTeamStatus.enum";
import { StatusIcon } from "../../components/Icons/StatusIcon";
import { AuthorizationHelper } from "../../helpers/AuthorizationHelper";

interface properties {
  federation?: FederationTeamView;
}

const TeamFederationCard: React.FC<properties> = ({ federation: value }) => {
  const [auth] = useState(new AuthorizationHelper());
  const url = configApp.avatarUrl + value?.federationId + ".png";
  return (
    <div className="team-federation-card">
      <div className="team-federation-body">
        <img src={url} className="team-federation-logo"></img>
        <div className="team-federation-details">
          <span className="activity-team-name">
            {value?.federation?.accountName}
          </span>
          <div className="team-federation-inputs">
            <input
              className="team-federation-input largo"
              readOnly
              value={"Primera"}
            />
            <input
              className="team-federation-input corto"
              readOnly
              value={NamesHelper.LetterName(value?.subTeam)}
            />
            {value?.federationStatus === FederationTeamStatus.Pending ? (
              <ButtonRemove
                onConfirm={() => {}}
                itemName={value?.federation?.accountName}
              />
            ) : (
              <div className="team-federation-input-placeholder"></div>
            )}
          </div>
        </div>

        <StatusIcon value={value?.federationStatus as number} size="40" />
      </div>
    </div>
  );
};

export default TeamFederationCard;
