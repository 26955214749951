import React, { useCallback, useContext, useMemo, useState } from "react";
import { Form, message, Divider, Button, Col, Row } from "antd";
import "../SideRightCard.css";
import { ColAvatar } from "../../components/TableCard/ColAvatar";
import Label from "../../components/Form/Label/Label";
import { Row as TableRow } from "../../components/TableCard/Row";

import { TournamentsContext } from "../../contexts/TournamentsContext";

interface properties {
  readOnly?: boolean;
}

export const TournamentsTeams: React.FC<properties> = ({ readOnly }) => {
  const { tournamentTeams } = useContext(TournamentsContext);

  return (
    <>
      <Divider orientation="left">Equipos</Divider>
      <div style={{ maxHeight: 280, overflow: "auto" }}>
        {tournamentTeams?.length ? (
          tournamentTeams.map((record) => (
            <TableRow id={record.tournamentTeamId ?? ""} justify>
              <ColAvatar id={record.teamId ?? ""} />
            </TableRow>
          ))
        ) : (
          <Label
            fontSize="20"
            fontWeight="500"
            gray
            style={{ marginBottom: 20 }}
          >
            No hay Equipos agregados
          </Label>
        )}
      </div>
    </>
  );
};
