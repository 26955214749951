import { Checkbox, Pagination, Spin } from "antd";
import { SportsTeamsContext } from "../../contexts/SportsTeamsContext";
import { useContext, useState } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { ColTag } from "../../components/TableCard/ColTag";
import { ColShirt } from "../../components/TableCard/ColShirt";
import { ColField } from "../../components/TableCard/ColField";
import { AuthorizationHelper } from "../../helpers/AuthorizationHelper";

import { ColAvatar } from "../../components/TableCard/ColAvatar";
import { NamesHelper } from "../../helpers/NamesHelper";
import { ColSport } from "../../components/TableCard/ColSport";
import { SelectGenre } from "../../components/lookups/SelectGenre";
import { SelectPosition } from "../../components/lookups/SelectPosition";
import { ColStatus } from "../../components/TableCard/ColStatus";

interface properties {
  title?: string;
  onSelect?: () => void;
}

export const PlayersList: React.FC<properties> = ({ title, onSelect }) => {
  const [auth] = useState(new AuthorizationHelper());

  const { isLoading, data, current, getId, gotoPage } =
    useContext(SportsTeamsContext);

  const handleRowClick = async (modelId: string) => {
    const model = await getId(modelId);
    if (model) {
      onSelect?.();
    }
  };

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage?.(page);
  }

  return (
    <Spin spinning={isLoading}>
      <div className="small-container">
        {data &&
          data?.data &&
          data?.data.map((player) => (
            <Row
              id={player.sportTeamId ?? ""}
              gender={player.gender}
              onClick={handleRowClick}
              active={
                player.sportTeamCategoryId === current?.sportTeamCategoryId
              }
              justify
            >
              <ColAvatar id={player.teamId ?? ""} />
              <ColSport value={player.sportId} />
              <ColText
                width="150px"
                value={`${player.team?.teamShort} ${NamesHelper.LetterName(
                  player.subTeam
                )}`}
              />
              <ColAvatar id={player.federationId ?? ""} />
              <ColText
                value={player.sportTeamCode ?? ""}
                grayText
                width="75px"
              />
              <ColText
                value={player.lastnames + "," + player.firstnames}
                priority={1}
              />
              <ColTag
                value={player.categoryShort}
                priority={2}
                width={"60px"}
              />
              <ColShirt value={player.shirtNumber?.toString()} priority={3} />
              <ColField value={player.position} priority={3} />
              <ColStatus
                value={player.sportTeamCategoryStatus as number}
                priority={2}
              />
            </Row>
          ))}
      </div>
      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
