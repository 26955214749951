import React from "react";
import { Button } from "antd";
import style from "./TableCard.module.css";
import { useMediaQuery } from "react-responsive";

interface properties {
  icon?: string;
  width?: string | number;
  onClick: () => void;
  isSelected?: boolean;
  priority?: 1 | 2 | 3;
}

export const ColButton: React.FC<properties> = ({
  onClick,
  icon,
  width,
  isSelected,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const buttonClass = `${style.btn} ${isSelected ? style["isSelected"] : ""}`;

  return <Button className={buttonClass} icon={icon} onClick={onClick} />;
};
