import { PublicFederationContextProvider } from "../contexts/public/PublicFederationContext";
import React, { ReactNode } from "react";
import { Outlet, useParams } from "react-router-dom";

const PublicLayout: React.FC = () => {
  const { externalId } = useParams();
  return (
    <PublicFederationContextProvider externalId={externalId ?? ""}>
      <Outlet />
    </PublicFederationContextProvider>
  );
};

export default PublicLayout;
