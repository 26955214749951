import React from "react";

interface TitleTextProps {
  text: string;
}

const TitleText: React.FC<TitleTextProps> = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "#0100B4",
        color: "white",
        padding: "15px 20px",
        borderRadius: 8,
        textAlign: "center",
        marginBottom: 20,
      }}
    >
      <span style={{ fontWeight: 700, fontSize: 20 }}>{text}</span>
    </div>
  );
};

export default TitleText;
