import React from "react";
import MainLayout from "./containers/MainLayout";
import { Route, Routes } from "react-router-dom";
import { routes } from "./constants/RouteConstants";

import { LoginScreen } from "./screens/publics/Login/LoginScreen";
import PlayersScreen from "./screens/players/PlayersScreen";

import TechnicalStaffScreen from "./screens/technicalStaff/TechnicalStaffScreen";
import TeamsPage from "./screens/teams/TeamsPage";
import LandingScreen from "./screens/publics/Landing/LandingScreen";

import MyAccountScreen from "./screens/MyAccount/MyAccount";
import MembersPage from "./screens/members/MembersPage";
import FederationTeamsPage from "./screens/federationTeams/FederationTeamsPage";
import TournamentsPage from "./screens/tournaments/TournamentsPage";
import CategoryPage from "./screens/categories/CategoriesPage";
import MatchesPage from "./screens/matches/MatchesPage";
import MatchPage from "./screens/match/MatchPage";
import TournamentPage from "./screens/tournaments/Tournament/TournamentPage";
import MatchTimerManagerPage from "./screens/matchTimer/MatchTimerManagerPage";
import TeamTacticsScreen from "./screens/teamTactics/TeamTacticsScreen";
import PublicLayout from "./containers/PublicLayout";
import FederationScreen from "./screens/publics/Asociacion/FederationScreen";

const App: React.FC = () => {
  return (
    <Routes>
      <Route>
        <Route index path={routes.Home} element={<LandingScreen />} />
        <Route index path={routes.Login} element={<LoginScreen />} />

        <Route element={<PublicLayout />}>
          <Route
            path={routes.PublicFederation}
            element={<FederationScreen />}
          />
        </Route>
      </Route>

      <Route element={<MainLayout />}>
        <Route path={routes.Matches} element={<MatchesPage />} />

        <Route path={routes.Categories} element={<CategoryPage />} />
        <Route path={routes.Tournaments} element={<TournamentsPage />} />
        <Route
          path={routes.FederationTeams}
          element={<FederationTeamsPage />}
        />
        <Route path={routes.TeamsTactics} element={<TeamTacticsScreen />} />
      </Route>
      <Route element={<MainLayout showFilters={false} />}>
        <Route path={routes.Teams} element={<TeamsPage />} />

        <Route path={routes.Members} element={<MembersPage />} />
        <Route path={routes.Players} element={<PlayersScreen />} />
        <Route
          path={routes.TechnicalStaff}
          element={<TechnicalStaffScreen />}
        />
      </Route>
      <Route element={<MainLayout showMenu={false} />}>
        <Route
          path={routes.MatchTimerManager}
          element={<MatchTimerManagerPage />}
        />
      </Route>
      <Route element={<MainLayout showHeader={false} />}>
        <Route path={routes.Tournament} element={<TournamentPage />} />
        <Route path={routes.Match} element={<MatchPage />} />
        <Route path={routes.MyAccount} element={<MyAccountScreen />} />
      </Route>
    </Routes>
  );
};

export default App;
