import { Pagination, Spin } from "antd";
import { useContext } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { OrderBy } from "../../components/OrderBy/OrderBy";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { ColAvatar } from "../../components/TableCard/ColAvatar";
import { FederationTeamsContext } from "../../contexts/FederationTeamsContext";
import { ColTag } from "../../components/TableCard/ColTag";
import { NamesHelper } from "../../helpers/NamesHelper";
import { ColSport } from "../../components/TableCard/ColSport";
import { ColStatus } from '../../components/TableCard/ColStatus';

interface properties {
  title?: string;
}

export const FederationTeamsList: React.FC<properties> = ({ title }) => {
  const {
    isLoading: searching,
    data,
    filters,
    current,
    updateFilters,
    selected,
    gotoPage,
  } = useContext(FederationTeamsContext);

  function handlerSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handlerSort(value: string): void {
    //updateFilters?.({ sort: value });
  }

  const handleRowClick = (federationTeamId: string) => {
    const equipo = data?.data?.find((p) => p.federationTeamId === federationTeamId);
    if (equipo) {
      selected(equipo);
    }
  };

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }

  return (
    <Spin spinning={searching}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          marginBottom: 10,
        }}
      >
        <div style={{ flex: 1, fontWeight: 400 }}>
          {title ?? "Listado de equipos"}
        </div>
        <div style={{ flex: 1 }}>
          <SearchInput value={filters?.search} onChange={handlerSearch} />
        </div>
        <div style={{ flex: 1 }}>
          <OrderBy
            //value={filters?.sort}
            options={[
              { value: "teamName", label: "Nombre" },
              { value: "teamShort", label: "Nombre Corto" },
            ]}
            onChange={handlerSort}
            showSort
          />
        </div>
      </div>
      <div className="small-container">
        {data?.data &&
          data?.data.map((record) => (
            <Row
              key={record.federationTeamId}
              id={record.federationTeamId ?? ""}
              onClick={handleRowClick}
              active={record.federationTeamId === current?.federationTeamId}
            >
              <ColSport value={record.sportId} />
              <ColAvatar id={record.teamId ?? ""} />
              <ColText value={record.teamName ?? ""} />
              <ColTag value={NamesHelper.LetterName(record.subTeam) ?? ""} />
              <ColText value={record.account?.accountName ?? ""} />
              <ColStatus value={record.federationStatus as number} />
            </Row>
          ))}
      </div>
      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
