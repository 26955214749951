import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SearchFilters } from "../virtualclub/models/filters/BaseFilters";
import { IPartido } from "../virtualclub/models/models/_obsolete/partidos/IPartido";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { MatchEventView } from '../virtualclub/models/models/matchesEvents/MatchesEvents.view';
import { MatchSportTeamView } from '../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { MatchEventType } from '../virtualclub/models/models/matchesEvents/MatchEventType.enum';
import { useMatchTimerService } from '../services/useMatchTimerService';
import { TimerStatus } from "../virtualclub/models/models/matchTimer/TimerStatus.enum";
import { MatchTimerState } from "../virtualclub/models/models/matchTimer/MatchTimerState";
export interface MatchTimerContextValue {
  isLoading: boolean;
  match?: MatchView;
  events?: MatchEventView[];
  cards?: MatchEventView[];
  elapsedTime: number;
  totalElapsedTime: number;
  deadTime: number;
  duration: number;
  start: () => void;
  pause: () => void;
  getPlayers: (teamId: string) => MatchSportTeamView[];
  addEvent: (event: MatchEventView) => void;
}

// Definición de las propiedades del componente proveedor de contexto
interface Props {
  baseFilters?: SearchFilters<IPartido>;
  matchId: string;
}

// Creación del contexto
export const MatchTimerContext = createContext<MatchTimerContextValue>(
  {} as MatchTimerContextValue
);

export const MatchTimerContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  matchId,
  baseFilters,
}) => {
  const { getMatch, sendEvent, match, setMatch, matchSportsTeams, events } = useMatchTimerService();
  const [timerState, setTimerState] = useState<MatchTimerState>({
    elapsed: 0,
    status: TimerStatus.Inactive,
    deadTime: 0,
    duration: 0,
  });
  const [totalElapsedTime, setTotalElapsedTime] = useState(0);
  const [cards, setCards] = useState<MatchEventView[]>([]);

  const timerRef = useRef(null);

  const fetchMatch = useCallback(async () => {
    if (matchId) {
      getMatch(matchId);
    }
  }, [getMatch, matchId]);

  const loader = useAsyncCall(fetchMatch, []);

  useEffect(() => {
    if (match) {
      const now = new Date();
      const diff = match.cronoStart ? (Number(now) - Number(match.cronoStart)) / 1000 : 0;
      if (match.cronoStatus === TimerStatus.Running) {
        setTimerState((prev) => ({
          ...prev,
          elapsed: (match.cronoElapsedTime ?? 0) + diff,
          status: TimerStatus.Running,
        }));
      }
      if (match.cronoStatus === TimerStatus.Paused) {
        setTimerState((prev) => ({
          ...prev,
          deadTime: (match.cronoDeadTime ?? 0) + diff,
          status: TimerStatus.Paused,
        }));
      }
    }
  }, [getMatch, match, matchId]);

  const addEvent = useCallback((event?: MatchEventView) => {
    if (!event) return;

    let finishSecondsTime = 0;
    if (event.matchEventType && [MatchEventType.GreenCard].includes(event.matchEventType)) {
      finishSecondsTime =
        totalElapsedTime + 120;
    }

    const ev = {
      ...event,
      matchId: match?.matchId,
      period: match?.cronoPeriod,
      timeInSeconds: timerState.elapsed,
      createdAt: new Date(),
      finishSecondsTime,
      startTime: match?.cronoStart,
      deadTime: match?.cronoDeadTime
    };
    sendEvent(ev);
    setCards((prev) => [...prev, ev]);
  }, [match?.matchId, match?.cronoPeriod, match?.cronoStart, match?.cronoDeadTime, timerState.elapsed, sendEvent, totalElapsedTime]);

  const start = useCallback(() => {
    if (match?.cronoStatus !== TimerStatus.Running) {
      let cronoPeriod = match?.cronoPeriod ?? 0;
      let cronoPlaying = true;
      let cronoStatus = match?.cronoStatus;
      if (cronoPeriod === 0) {
        cronoPeriod = 1;
        cronoStatus = TimerStatus.Running;
        setTimerState((prev) => ({
          ...prev,
          duration: match?.periodsTime ?? 0,
          status: TimerStatus.Running,
        }));
        addEvent({ matchEventType: MatchEventType.Start, details: "Inicio de partido" });
      } else {
        if (match?.cronoStatus === TimerStatus.Paused) {
          addEvent({ matchEventType: MatchEventType.Event, details: "Reinicio" });
          cronoStatus = TimerStatus.Running;
        }
        if (match?.cronoStatus === TimerStatus.Stop) {
          addEvent({ matchEventType: MatchEventType.Event, details: "Inicio de periodo" });
          cronoStatus = TimerStatus.Running;
          cronoPeriod++;
        }
      }
      setMatch((prev) => ({
        ...prev,
        cronoPeriod,
        cronoStatus,
        cronoStart: new Date(),
        cronoElapsedTime: timerState.elapsed,
        cronoDeadTime: timerState.deadTime,
        cronoPlaying,
      }));
    }
  }, [addEvent, timerState.deadTime, timerState.elapsed, match?.cronoPeriod, match?.cronoStatus, match?.periodsTime, setMatch]);

  const pause = useCallback(() => {
    if (match && match.cronoStatus === TimerStatus.Running) {
      setMatch((prev) => ({
        ...prev,
        cronoElapsedTime: timerState.elapsed,
        cronoStatus: TimerStatus.Paused,
        cronoStart: new Date(),
      }));
      addEvent({ matchEventType: MatchEventType.Event, details: "Pausa" });
    }
  }, [addEvent, timerState.elapsed, match, setMatch]);

  const handleTimeComplete = useCallback(() => {
    setMatch((prev) => {
      let cronoPeriod = prev?.cronoPeriod ?? 1;
      let cronoPlaying = prev?.cronoPlaying;
      let cronoStatus = prev?.cronoStatus;

      if (cronoPlaying) {
        if (cronoPeriod === match?.numberPeriods) {
          cronoStatus = TimerStatus.Finish;
          addEvent({ matchEventType: MatchEventType.EndMatch, details: "Fin del partido" });
        } else {
          cronoPlaying = false;
          addEvent({ matchEventType: MatchEventType.EndPeriod, details: "Fin del periodo" });
          addEvent({ matchEventType: MatchEventType.Intermission, details: "Inicio del intervalo" });
        }
      } else {
        cronoStatus = TimerStatus.Stop;
        addEvent({ matchEventType: MatchEventType.EndIntermission, details: "Fin del intervalo" });
      }

      setTimerState((prev) => ({
        ...prev,
        duration: cronoPlaying ? match?.periodsTime ?? 0 : match?.intervalTime ?? 0,
        elapsed: 0,
        status: cronoStatus!,
      }));
      return { ...prev, cronoStatus, cronoStart: new Date(), cronoElapsedTime: 0, cronoPlaying, cronoPeriod };
    });
  }, [addEvent, match?.intervalTime, match?.numberPeriods, match?.periodsTime, setMatch]);

  const getPlayers = useCallback((federationTeamId: string) => {
    return matchSportsTeams.filter((x) => x.federationTeamId === federationTeamId);
  }, [matchSportsTeams]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (timerState.status === TimerStatus.Running) {
      intervalId = setInterval(() => {
        setTimerState((prev) => {
          const newElapsedTime = prev.elapsed + 1;
          if (newElapsedTime >= prev.duration) {
            handleTimeComplete();
            clearInterval(intervalId!);
          }
          setTotalElapsedTime(((match?.cronoPeriod ?? 0) - 1) * (match?.periodsTime ?? 0) + newElapsedTime);
          return { ...prev, elapsed: newElapsedTime };
        });
      }, 1000);
    } else if (timerState.status === TimerStatus.Paused) {
      intervalId = setInterval(() => {
        setTimerState((prev) => ({ ...prev, deadTime: prev.deadTime + 1 }));
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timerState.status, match?.cronoPeriod, match?.periodsTime, handleTimeComplete]);


  // const startPeriodTimer = () => {
  //   if (timerRef.current) return; // Evitar múltiples timers

  //   timerRef.current = setInterval(() => {
  //     setCurrentTime(prev => {
  //       // Si llegamos al final del período
  //       if (prev >= PERIOD_DURATION) {
  //         clearInterval(timerRef.current);
  //         timerRef.current = null;
  //         setIsPlaying(false);
  //         setIsBreak(true);
  //         return prev;
  //       }
  //       return prev + 1;
  //     });
  //   }, 1000);
  // };

  const contextValue = useMemo<MatchTimerContextValue>(() => ({
    isLoading: loader.loading,
    match,
    events,
    cards,
    elapsedTime: timerState.elapsed,
    totalElapsedTime,
    deadTime: timerState.deadTime,
    duration: timerState.duration,
    start,
    pause,
    getPlayers,
    addEvent,
  }), [loader.loading, match, events, cards, timerState, totalElapsedTime, start, pause, getPlayers, addEvent]);

  return (
    <MatchTimerContext.Provider value={contextValue}>
      {children}
    </MatchTimerContext.Provider>
  );
};
