import React from "react";
import addImage from "../../../assets/icons/buttonAdd.svg";

interface properties {
  onClick?: () => void;
  size?: number;
}

export const ButtonAdd: React.FC<properties> = ({ onClick, size = 30 }) => {
  const style: React.CSSProperties = {
    cursor: "pointer",
  };
  return <img src={addImage} onClick={onClick} style={style} alt="Nuevo" />;
};
