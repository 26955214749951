import React, { useState, useEffect } from "react";
import "./MyAccount.css";
import { Card, Tag } from "antd";
import { ICuentaUsuario } from "../../virtualclub/models/models/_obsolete/usuarios/CuentaUsuario";

const AccountUsers: React.FC = () => {
  // const [usuarios, setUsuarios] = useState<ICuentaUsuario[]>([]);

  // useEffect(() => {
  //   // const fetchEquipos = async () => {
  //   //   const equiposData = await MiCuentaService.getEquipos();
  //   //   setEquipos(equiposData || []);
  //   // };

  //   // fetchEquipos();
  //   const usuarios = [
  //     { usuarioId: "admin@demo", nombre: "Demo Administrador", roles: [{ rolId: "1", nombre: "Adminstrador" }, { rolId: "2", nombre: "Juez mesa" }] }
  //   ];
  //   setUsuarios(usuarios);
  // }, []);

  const usuarios: ICuentaUsuario[] = [
    {
      usuarioId: "1",
      username: "admin@demo",
      nombre: "Demo Administrador",
      roles: [
        { rolId: "1", nombre: "Adminstrador" },
        { rolId: "2", nombre: "Juez mesa" },
      ],
    },
    {
      usuarioId: "2",
      username: "finanzas@demo",
      nombre: "Contador",
      roles: [{ rolId: "1", nombre: "Finanzas" }],
    },
  ];

  return (
    <Card title="Usuarios">
      <ul>
        {usuarios.length > 0 ? (
          usuarios.map((usuario) => (
            <li key={usuario.usuarioId}>
              <div>
                {usuario.username} / {usuario.nombre}
                <br />
                {usuario.roles?.map((x) => (
                  <Tag>{x.nombre}</Tag>
                ))}
              </div>
            </li>
          ))
        ) : (
          <li>No hay usuarios asignados.</li>
        )}
      </ul>
    </Card>
  );
};

export default AccountUsers;
