import React from "react";
import styles from "./tshirt.module.css";
import { ReactComponent as C0 } from "../../assets/shirts/c0.svg";
import { ReactComponent as C1 } from "../../assets/shirts/c1.svg";
import { NumberHelper } from "../../helpers/NumberHelper";
import { SelectShirtNumber } from '../lookups/SelectShirtNumber';
import { Gender } from '../../virtualclub/models/models/members/Genders.enum';

interface properties {
  gender?: Gender | number;
  shirtNumber?: number;
  name?: string;
  backcolor?: string;
  color?: string;
  fontcolor?: string;
  editableNumber?: boolean;
  onNumberChange?: (value: number) => void;
  excludeNumbers?: number[];
}

export const TShirt: React.FC<properties> = ({
  shirtNumber,
  name,
  gender,
  backcolor,
  color = "gray",
  fontcolor = "black",
  editableNumber = false,
  onNumberChange,
  excludeNumbers: excludeIds,
}) => {
  let num = !shirtNumber ? "--" : NumberHelper.zero(shirtNumber);
  const shirtStyle = {
    color,
  };

  return (
    <div>
      <div className={styles.shirtContainer}>
        <div className={styles.shirtImageContainer} style={shirtStyle}>
          <div
            className={styles.circleBackground}
            style={{
              backgroundColor: backcolor,
            }}
          />
          {gender === 1 ? (
            <C1 className={styles.shirtImage} />
          ) : (
            <C0 className={styles.shirtImage} />
          )}
        </div>
        {editableNumber ? (
          <div className={styles.shirtNumber}>
            <SelectShirtNumber
              shirtNumber={shirtNumber}
              onChange={(value) => onNumberChange?.(value)}
              excludeIds={excludeIds}
              className={styles.shirtNumberSelect}
            />
          </div>
        ) : (
          <div className={styles.shirtNumber} style={{ color: fontcolor }}>
            {num}
          </div>
        )}
        {name && (
          <div className={styles.shirtName} style={{ color: fontcolor }}>
            {name}
          </div>
        )}
      </div>
    </div>
  );
};
