import React from "react";
import { useMediaQuery } from "react-responsive";

export const ContentPage: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div
      style={{
        width: isSmallScreen ? "100%" : "75%",
        marginRight: isSmallScreen ? 0 : 10,
        marginTop: 5,
      }}
    >
      {children}
    </div>
  );
};
