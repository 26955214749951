import React from "react";
import style from "./TableCard.module.css";
import Logo from "../../assets/VC.svg";
import { useMediaQuery } from "react-responsive";

interface properties {
  src: string;
  alt?: string;
  className?: string;
  size?: string;
  priority?: 1 | 2 | 3;
}

export const ColImage: React.FC<properties> = ({
  src,
  alt,
  className,
  size,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const imageSize = {
    width: size || "35px",
    height: size || "35px",
  };

  const colstyle = className ?? style.image;
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  return (
    <div className={style.imageContainer} style={imageSize}>
      <img
        src={src}
        alt={alt}
        onError={handleImageError}
        className={colstyle}
      />
    </div>
  );
};
