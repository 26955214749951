import React from "react";
import "./TournamentTeamCard.css";
import { configApp } from "../../../services/configApp";
import Logo from "../../../assets/VC.svg";
import { TournamentTeamView } from "../../../virtualclub/models/models/tournamentsTeams/TournamentTeam.view";
import { ButtonRemove } from "../../../components/Form/Buttons/ButtonRemove";
import { ButtonAction } from "../../../components/Form/Buttons/ButtonAction";

interface TournamentTeamCardProps {
  team: TournamentTeamView | undefined;
  reverse?: boolean;
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void;
  onRemove?: () => void;
  onAccept?: () => void;
}

export const TournamentTeamCard: React.FC<TournamentTeamCardProps> = ({
  team,
  reverse = false,
  onDragStart,
  onRemove, // Para manejar el evento de eliminación
  onAccept, // Para manejar el evento de aceptar
}) => {
  const url = team?.teamId
    ? `${configApp.avatarUrl}${team.teamId}.png`
    : undefined;

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  const teamName = team?.teamId ? `Team ${team.teamId}` : "Sin seleccionar";

  return (
    <div
      className={`tournament-team-card ${reverse ? "tournament-reverse" : ""}`}
      style={{ display: "flex", alignItems: "center" }}
      draggable={!!onDragStart} // Hacer que el componente sea draggable
      onDragStart={onDragStart}
    >
      <div className="tournament-team-avatar">
        {url ? (
          <img
            src={url}
            alt="Team Avatar"
            className="tournament-team-image"
            onError={handleImageError}
          />
        ) : (
          <div className="tournament-team-placeholder" />
        )}
      </div>

      <div className="tournament-team-name" style={{ marginLeft: "10px" }}>
        {teamName}
      </div>

      {/* Mostrar el icono de arrastrar si es draggable */}
      {onDragStart && (
        <div className="tournament-team-icon" style={{ marginLeft: "auto" }}>
          <i className="fa-solid fa-bars"></i>
        </div>
      )}

      {/* Mostrar el botón de eliminar si la función onRemove está definida */}
      {onRemove && (
        <div style={{ marginLeft: "10px" }}>
          <ButtonRemove
            onConfirm={onRemove}
            itemName={team?.teamId ? `Team ${team.teamId}` : "Sin seleccionar"}
          />
        </div>
      )}

      {/* Mostrar el botón de aceptar si la función onAccept está definida */}
      {onAccept && (
        <div style={{ marginLeft: "10px" }}>
          <ButtonAction type="check" onClick={onAccept} />
        </div>
      )}
    </div>
  );
};

export default TournamentTeamCard;
