export enum PartidoEstado {
  Borrado = 0,
  Pendiente = 1,
  SinIniciar = 2,

  EnJuego = 10,
  Pausa = 11,
  Intermedio = 12,
  Terminado = 15,
  Cerrado = 16,

  Suspendido = 20,
  Anulado = 21,
  WalkOver = 22,
  WO_Local = 23,
  WO_Visitante = 24,
}
