import React, { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectItem } from "../Select/SelectItem";
import { SelectExt, SelectExtProps } from "../SelectExt";

export const SelectTournamentType: React.FC<SelectExtProps> = ({
  value,
  readOnly,
  onChange,
  type,
  ...props
}) => {
  const items: SelectItem[] = [
    { value: "", label: "Todos los tipos" },
    { value: 0, label: "Copa" },
    { value: 1, label: "Liga" },
  ];

  const [selectedValue, setSelectedValue] = useState(value ?? items[0].value);

  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

  function onChangeValue(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    setSelectedValue(value);
    onChange?.(value, option);
  }

  return (
    <SelectExt
      {...props}
      options={items}
      value={selectedValue}
      onChange={onChangeValue}
      type={type}
    />
  );
};
