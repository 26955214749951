import Popup from "../../../components/Modal/Popup";
import React from "react";
import { FieldGame, FieldGamePlayer } from '../../../components/FieldGame/FieldGame';

interface properties {
  position: number;
  sportId: number;
  shirtNumber?: number;
  onClose?: () => void;
  openModal: boolean;
  onChange?: (position: number) => void;
}

export const ChangePositionPopup: React.FC<properties> = ({
  onClose,
  onChange,
  openModal,
  position,
  sportId,
  shirtNumber
}) => {
  const playerposition: FieldGamePlayer = {
    position,
    number: shirtNumber?.toString() ?? "",
    color: "yellow"
  }

  function handlerClick(position: number): void {
    onChange?.(position);
  }

  return (
    <Popup
      title="Posicion de juego"
      visible={openModal}
      onClose={onClose}
      showCloseButton
      width={300}
    >
      <FieldGame
        sportId={sportId}
        players={[playerposition]}
        width={240}
        height={400}
        onChange={handlerClick}
      />
    </Popup>
  );
};
