import { FederationContextProvider } from "../../contexts/FederationTeamsContext";
import React from "react";
import { FederationTeamsList } from "./FederationTeamsList";
import { FederationTeamCard } from "./FederationTeamCard";
import { GenericPage } from "../../components/Screen/GenericPage";
import { ContentPage } from "../../components/Screen/ContentPage";
import { SideRightPage } from "../../components/Screen/SideRightPage";

const FederationTeamsPage: React.FC = () => {
  return (
    <FederationContextProvider>
      <GenericPage>
        <ContentPage><FederationTeamsList /></ContentPage>
        <SideRightPage><FederationTeamCard /></SideRightPage>
      </GenericPage>

    </FederationContextProvider>
  );
};

export default FederationTeamsPage;
