import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import Subheader from "./Subheader";
import { GeneralContextProvider } from "../contexts/GeneralContext";
import { SideMenu } from "./SideMenu";
import { Header } from "./Header";

interface properties {
  showFilters?: boolean;
  showMenu?: boolean;
  showHeader?: boolean;
}

const MainLayout: React.FC<properties> = ({
  showFilters,
  showMenu = true,
  showHeader = true,
}) => {
  return (
    <GeneralContextProvider>
      <div className="main-container">
        <Header />
        <div className="main-layout">
          {showMenu && <SideMenu />}
          <main className="main-content">
            {showMenu && showHeader && <Subheader showFilters={showFilters} />}

            <Outlet />
          </main>
        </div>
      </div>
    </GeneralContextProvider>
  );
};

export default MainLayout;
