import { useContext, useState } from "react";
import "./Subheader.css";
import logo from "../public/ahba-logo.png";
import { SelectGenre } from "../components/lookups/SelectGenre";
import { SelectSubTeam } from "../components/lookups/SelectSubTeam";
import { DefaultOptionType } from "antd/es/select";
import { GeneralContext } from "../contexts/GeneralContext";
import { AuthorizationHelper } from "../helpers/AuthorizationHelper";
import { AccountType } from "../virtualclub/models/models/accounts/AccountType.enum";
import { SelectTeam } from "../components/lookups/SelectTeam";

interface properties {
  showFilters?: boolean;
}

const Subheader: React.FC<properties> = ({ showFilters = true }) => {
  const context = useContext(GeneralContext);
  const [auth] = useState(new AuthorizationHelper());

  function handlerGeneroChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    context?.changeGenero?.(value);
  }

  function handlerCategoriaChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    context?.changeCategoria?.(value);
  }

  function handlerEquipoChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    context?.changeEquipo?.(value);
  }

  function handlerLetraChange(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    context?.changeLetra?.(value);
  }

  return (
    <div className="subheader">
      <div className="subheader-banner" data-id="24">
        <img src={logo} className="liga-img"></img>
        <h1 className="subheader-title" data-id="25">
          Asociacion Amateur de Hockey de Buenos Aires
        </h1>
        <i className="fa-solid fa-chevron-down"></i>
      </div>
      {showFilters && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginTop: 5,
          }}
        >
          {auth.isAccountType(AccountType.Federation) && (
            <div style={{ flex: 1 }}>
              <SelectTeam
                onChange={handlerEquipoChange}
                value={context.equipo}
                type="full"
              />
            </div>
          )}
          <div style={{ flex: 1 }}>
            <SelectGenre
              onChange={handlerGeneroChange}
              value={context.genero}
              type="full"
            />
          </div>
          <div style={{ flex: 1 }}>
            {/* <SelectCategoria
              onChange={handlerCategoriaChange}
              value={context.categoria}
            /> */}
          </div>
          {!auth.isAccountType(AccountType.Federation) && (
            <div style={{ flex: 1 }}>
              <SelectSubTeam
                onChange={handlerLetraChange}
                value={context.letra}
                type="full"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Subheader;
