import React from "react";
import { NavLink } from "react-router-dom";
import "./NavItem.css";

interface properties {
  caption: string;
  icon?: string;
  active?: boolean;
  to: string;
}

export const NavItem: React.FC<properties> = ({
  caption: text,
  icon,
  active,
  to,
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? "sider-button active" : "sider-button"
      }
    >
      {icon && (
        <i className={icon} style={{ marginRight: 10, fontSize: 20 }}></i>
      )}
      {text}
    </NavLink>
  );
};
