import React, { useMemo } from "react";
import styles from "./SelectShirtNumber.module.css";
import { NumberHelper } from "../../helpers/NumberHelper";


interface properties {
  shirtNumber?: number;
  excludeIds?: number[];
  style?: React.CSSProperties;
  onChange?: (value: number) => void;
  className?: string;
}

export const SelectShirtNumber: React.FC<properties> = ({
  excludeIds,
  shirtNumber,
  onChange,
  style,
  className,
  ...props
}) => {
  const options = useMemo(() => {
    const numeros = Array.from({ length: 100 }, (_, index) => index);
    const resultado = numeros.filter((num) => !excludeIds?.includes(num));
    return resultado.map((num) => {
      return { value: num, label: NumberHelper.zero(num, 2) };
    });
  }, [excludeIds]);


  const handlerChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(parseInt(event?.target?.value));
  };

  return (
    <div className={styles.numberSelectContainer}>
      <select
        value={shirtNumber}
        className={styles.numberSelect ?? "custom-select"} // Aplica la clase CSS aquí
        style={{ border: "0px", appearance: "none" }}
        onChange={handlerChangeValue}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
