import React, { useCallback, useContext, useState } from "react";
import { Form, message, Divider, Button, Row, Col } from "antd";
import "../SideRightCard.css";

import { ColAvatar } from "../../components/TableCard/ColAvatar";
import { ColText } from "../../components/TableCard/ColText";
import Label from "../../components/Form/Label/Label";
import Popup from "../../components/Modal/Popup";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import Input from "../../components/Form/Input/Input";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { FederationTeamAssociate } from "../../virtualclub/models/models/federationsTeams/FederationTeam.associate";
import { Row as TableRow } from "../../components/TableCard/Row";
import { FederationTeamsContext } from "../../contexts/FederationTeamsContext";
import { useFederationTeamsService } from "../../services/useFederationTeamsService";
import { FormItemHidden } from '../../components/Form/FormItem/FormItemHidden';

interface properties {
  readOnly?: boolean;
}

export const FederationTeamAccount: React.FC<properties> = ({ readOnly }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current } = useContext(FederationTeamsContext);
  const { associate } = useFederationTeamsService();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlerOpen = () => {
    setIsModalVisible(true);
  };

  const handlerClose = () => {
    setIsModalVisible(false);
  };

  const handlerCancelClick = useCallback(() => {
    form.setFieldsValue(current ?? {});
    setIsModalVisible(false);
  }, [current, form]);

  const handlerSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const onFinish = useCallback(async (values: FederationTeamAssociate): Promise<void> => {
    const response = await associate({ ...values, teamId: current?.teamId! });
    console.log(response);
    if (response?.result) {
      setIsModalVisible(false);
    }
  }, [associate, current?.teamId]);

  return (
    <>
      <Divider orientation="left">Cuenta Asociada</Divider>
      {contextHolder}
      <div className="sideright-card-forms">
        <div className="small-container">
          {current?.accountId ? (
            <TableRow id={current.accountId ?? ""} justify>
              <ColAvatar id={current.accountId ?? ""} />
              <ColText
                value={current?.account?.accountName ?? ""}
                width="250px"
              />
            </TableRow>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Label
                fontSize="20"
                fontWeight="500"
                gray
                style={{ marginBottom: 10 }}
              >
                Asociar Cuenta
              </Label>
              <Button onClick={handlerOpen} className="info-btn" icon={"+"} />
            </div>
          )}
        </div>
      </div>

      <Popup
        visible={isModalVisible}
        title="Asociar Cuenta"
        showCloseButton
        width={600}
        onClose={handlerClose}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={new FederationTeamAssociate()}
          onFinish={onFinish}
          className="sideright-card-form"
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label="Email" name="email">
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <ButtonSaveCancel
          onSaveClick={handlerSaveClick}
          onCancelClick={handlerCancelClick}
        />
      </Popup>
    </>
  );
};
