import { IsEnum } from 'class-validator';
import { FederationTeamStatus } from './FederationTeamStatus.enum';
import { plainToInstance } from 'class-transformer';
import { TeamStatus } from '../teams/TeamStatus.enum';
import { AccountView } from '../accounts/Account.view';

export class FederationTeam {
  federationTeamId?: string;
  accountId?: string;
  account?: AccountView;
  teamId?: string;
  sportId?: number;
  teamName?: string;
  teamShort?: string;

  @IsEnum(TeamStatus)
  teamStatus?: TeamStatus;
  color?: string;
  color1?: string;
  color2?: string;
  federationId?: string;
  federation?: AccountView;
  teamCode?: string;
  subTeam?: number;

  @IsEnum(FederationTeamStatus)
  federationStatus?: FederationTeamStatus;

  static fromPlain(plain: FederationTeam): FederationTeam {
    return plainToInstance(FederationTeam, plain);
  }
}
