import React from "react";
import { ColImage } from "./ColImage";
import { configApp } from "../../services/configApp";
import { useMediaQuery } from "react-responsive";

interface properties {
  id: string;
  className?: string;
  priority?: 1 | 2 | 3;
}

export const ColAvatar: React.FC<properties> = ({
  id,
  className,
  priority = 1,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const url = configApp.avatarUrl + id + ".png";
  return <ColImage src={url} className={className} />;
};
