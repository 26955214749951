import { useContext } from "react";

import "./EventPopup.css";
import { MatchTimerContext } from '../../../contexts/MatchTimerContext';
import { AvatarImage } from "../../../components/Images/AvatarImage";
import { TeamView } from '../../../virtualclub/models/models/teams/Team.View';


interface propierties {
  onSelectTeam?: (team: TeamView) => void;
}


export const StepSelectTeam: React.FC<propierties> = ({ onSelectTeam }) => {
  const { match } = useContext(MatchTimerContext);
  return (
    <div className="event-team-container">
      <div onClick={() => onSelectTeam?.(match?.homeTeam?.team!)}>
        <AvatarImage id={match?.homeTeamId!} style={{ height: 200 }} />
      </div>
      <div onClick={() => onSelectTeam?.(match?.awayTeam?.team!)}>
        <AvatarImage id={match?.awayTeamId!} style={{ height: 200 }} />
      </div>
    </div>

  );
};

