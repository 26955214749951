export const apiRoutes = {
  //---------CORE------------
  userAuth: '/public/auth',
  userInfo: '/userinfo',

  resetPassword: '/reset',

  funcionesLookup: '/aplicaciones/funciones/lookup',
  funcionLookup: '/funciones',

  usuarios: '/usuarios',
  usuario: '/usuarios/:id',
  usuarioUpdate: '/usuarios',

  fileAvatarImage: "/images/:id/avatar",

  cuenta: '/cuenta',
  cuentaUpdate: '/cuenta',
  cuentaImage: '/cuenta/:id/image',

  categories: "/categories",
  categoriesId: "/categories/:id",

  myAccount: '/myaccount',
  miCuentaUpdate: '/micuenta',
  miCuentaEquipos: '/micuenta/equipos',

  teams: "/teams",
  teamsId: "/teams/:id",

  federationsTeams: "/federationsteams",
  federationsTeamsFederate: "/federationsteams/federate",
  federationsTeamsAssociate: "/federationsteams/associate",

  members: "/members",
  member: "/members/:id",
  memberSportTeam: "/members/:id/sportsteams",

  sportsTeams: "/sportsteams",
  sportTeamId: "/sportsteams/:id",

  sportTeamCategoryId: "/sportsteamscategories/:id",  //
  sportTeamCategoryAvailables: "/sportsteamscategories/availables", //

  players: "/players",
  technicalStaff: "/technicalStaff",

  tournaments: "/tournaments",
  tournamentsId: "/tournaments/:id",

  matches: "/matches",
  matchId: "/matches/:id",
  matchSportTeam: "/matches/:matchId/sportteam",  //
  matchSportTeamByFederationTeam: "/matches/:matchId/sportteam/:federationTeamId",  //
  matchSportTeamId: "/matches/:matchId/sportteam/:matchSportTeamId",
  matchSportTeamSelected: "/matches/:matchId/sportteam/:sportTeamCategoryId",

  matchClosePreselect: "/matches/:matchId/close/:federationTeamId",

  matchTimer: "/matchtimer/:matchId",
  matchTimerSportTeam: "/matchtimer/:matchId/sportteam",
  matchTimerEvents: "/matchtimer/:matchId/events",

  //-----------------------------------------------------------
  equiposxCuenta: "/equipos/cuenta",
  equiposLookup: "/equipos/lookup",

  plantel: "/plantel/:id",
  plantelCategoria: "/plantel/:id/categoria",
  plantelesTecnico: "/plantel",
  plantelTecnico: "/plantel/:id",
  socios: "/socios",
  socio: "/socios/:id",
  categorias: "/categorias",
  torneos: "/torneos",
  equiposxTorneo: "/torneos/:id",
  categoriasUpdateJugador: "/categorias/update",
  partidos: "/partidos",
  partido: "/partidos/:id",
};
