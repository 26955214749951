import { useState } from 'react';

import { apiGet, apiPatch, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { MatchSportTeamView } from '../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { BuildUrl } from '../helpers/UrlHelper';
import { MatchEventView } from '../virtualclub/models/models/matchesEvents/MatchesEvents.view';
import { UuidHelper } from "../helpers/UuidHelper";

export const useMatchTimerService = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [match, setMatch] = useState<MatchView>();
  const [events, setEvents] = useState<MatchEventView[]>([]);
  const [matchSportsTeams, setMatchSportsTeams] = useState<MatchSportTeamView[]>([]);

  const getMatch = async (matchId: string): Promise<MatchView | undefined> => {
    try {
      const model = await apiGet<MatchView>(apiRoutes.matchTimer.replace(':matchId', matchId));
      setMatch(model);

      const urlplayers = BuildUrl(apiRoutes.matchTimerSportTeam, { matchId });
      const msp = await apiGet<MatchSportTeamView[]>(urlplayers);
      setMatchSportsTeams(msp ?? []);

      const urlevents = BuildUrl(apiRoutes.matchTimerEvents, { matchId });
      const evs = await apiGet<MatchEventView[]>(urlevents);
      setEvents(evs ?? []);

      return model;
    } catch (er) { }
    return undefined;
  };

  const sendEvent = async (event: MatchEventView): Promise<void> => {

    if (!match) return;

    const matchEventId = UuidHelper.CreateString();
    let addevent = { matchEventId, ...event };
    try {
      const param = { matchId: match.matchId! };
      const url = BuildUrl(apiRoutes.matchTimerEvents, param)
      const model = await apiPost<MatchEventView, boolean>(url, addevent);
    } catch (er: any) {
      console.error(er.message);
    }
    setEvents((prevs) => [...prevs, addevent]);
  };


  return { match, isLoading, error, events, matchSportsTeams, getMatch, setMatch, sendEvent };
}