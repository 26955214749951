export enum MatchEventType {
  NoAction = 0,
  Start = 1,
  Event = 2,
  EndPeriod = 3,
  Intermission = 4,
  EndIntermission = 5,
  EndMatch = 6,

  GreenCard = 7,
  YellowCard = 8,
  RedCard = 9,

  Goal = 10,
  PenaltyGoal = 11,
  CornerGoal = 12,
  OwnGoal = 13,

  Substitution = 14,

  MissedPenalty = 15,
  Score = 16,
  CanceledPenalty = 17,

  Injured = 18,

  ClockChange = 19,

  RemoveGreenCard = -7,
  RemoveYellowCard = -8,
  RemoveRedCard = -9,
  RemoveGoal = -10,
}
