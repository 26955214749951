import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { TournamentsContext } from "./TournamentsContext";
import { TournamentTeamView } from "../virtualclub/models/dto/TournamentTeamView";

interface Props {}
export const TournamentContext = createContext<TournamentContextValue>(
  {} as TournamentContextValue
);

export interface TournamentMatch {
  matchId: string;
  homeTeam: TournamentTeamView;
  awayTeam: TournamentTeamView;
  week: number;
  day: string;
  time: string;
  group?: string;
  stage?: string;
}
export const TournamentContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children }) => {
  const { tournamentTeams } = useContext(TournamentsContext);
  const [groups, setGroups] = useState<TournamentTeamView[][]>([[]]);
  const [maxTeamsPerGroup, setMaxTeamsPerGroup] = useState<number>(4);
  const [tournamentFormat, setTournamentFormat] = useState<
    "Liga" | "Copa" | "Grupos"
  >("Liga");
  const [copaStage, setCopaStage] = useState<number>(16);
  const [matches, setMatches] = useState<TournamentMatch[]>([]);

  const [currentWeek, setCurrentWeek] = useState<number>(1);

  useEffect(() => {
    if (tournamentTeams) {
      resetGroups();
    }
  }, [tournamentTeams, tournamentFormat, copaStage]);

  const resetGroups = () => {
    setGroups((prevGroups) => {
      const allTeams = tournamentTeams || [];
      const numGroups = tournamentFormat === "Liga" ? 2 : copaStage / 2 + 1;
      const newGroups: TournamentTeamView[][] = [allTeams];
      for (let i = 1; i < numGroups; i++) {
        newGroups.push([]);
      }
      setMaxTeamsPerGroup(tournamentFormat === "Liga" ? 4 : 2);

      return newGroups;
    });
  };

  const randomizeTeams = () => {
    const allTeams = groups.flat();
    const newGroups: TournamentTeamView[][] = [
      [],
      ...groups.slice(1).map(() => []),
    ];

    for (let i = allTeams.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [allTeams[i], allTeams[j]] = [allTeams[j], allTeams[i]];
    }

    let groupIndex = 1;
    allTeams.forEach((team) => {
      if (newGroups[groupIndex].length < maxTeamsPerGroup) {
        newGroups[groupIndex].push(team);
      } else {
        groupIndex += 1;
      }

      if (groupIndex === newGroups.length) {
        groupIndex = 1;
      }
    });

    newGroups[0] = allTeams.slice(newGroups.slice(1).flat().length);
    setGroups(newGroups);
  };

  const setGroupCount = (count: number) => {
    setGroups((prevGroups) => {
      const allTeams = prevGroups[0];
      const newGroups: TournamentTeamView[][] = [allTeams];
      for (let i = 1; i < count + 1; i++) {
        newGroups.push([]);
      }
      return newGroups;
    });
  };

  const moveTeam = (
    sourceGroupIndex: number,
    targetGroupIndex: number,
    sourceTeamIndex: number,
    targetTeamIndex: number | null
  ) => {
    setGroups((prevGroups) => {
      const newGroups = [...prevGroups];
      const sourceGroup = newGroups[sourceGroupIndex];
      const targetGroup = newGroups[targetGroupIndex];

      if (sourceGroup[sourceTeamIndex]) {
        const [movedTeam] = sourceGroup.splice(sourceTeamIndex, 1);

        if (targetTeamIndex !== null) {
          const [targetTeam] = targetGroup.splice(targetTeamIndex, 1);
          targetGroup.splice(targetTeamIndex, 0, movedTeam);
          sourceGroup.splice(sourceTeamIndex, 0, targetTeam);
        } else if (targetGroup.length < maxTeamsPerGroup) {
          targetGroup.push(movedTeam);
        } else {
          sourceGroup.splice(sourceTeamIndex, 0, movedTeam);
        }
      }

      return newGroups;
    });
  };

  const generateMatches = () => {
    const newMatches: TournamentMatch[] = [];
    let week = 1;

    if (tournamentFormat === "Liga") {
      groups.forEach((group, groupIndex) => {
        for (let i = 0; i < group.length - 1; i++) {
          for (let j = i + 1; j < group.length; j++) {
            newMatches.push({
              matchId: `${groupIndex}-${i}-${j}`,
              homeTeam: group[i],
              awayTeam: group[j],
              week: week,
              day: getMatchDay(week),
              time: "16:00",
              group: `Grupo ${groupIndex + 1}`,
            });
          }
        }
        week++;
      });
    } else if (tournamentFormat === "Copa") {
      for (let i = 0; i < groups.length; i += 2) {
        if (groups[i]?.length > 0 && groups[i + 1]?.length > 0) {
          newMatches.push({
            matchId: `${i}-${i + 1}`,
            homeTeam: groups[i][0],
            awayTeam: groups[i + 1][0],
            week: week,
            day: getMatchDay(week),
            time: "16:00",
            stage: `Etapa ${i / 2 + 1}`,
          });
        }
        week++;
      }
    }
    console.log(newMatches);

    setMatches(newMatches);
  };
  const getMatchDay = (week: number): string => {
    const days = ["viernes", "sábado", "domingo"];
    return days[week % days.length];
  };
  const filterMatchesByWeek = (week: number) => {
    return matches.filter((match) => match.week === week);
  };
  const contextValue = useMemo<TournamentContextValue>(
    () => ({
      groups,
      maxTeamsPerGroup,
      tournamentFormat,
      copaStage,
      matches,
      currentWeek,
      setCurrentWeek,
      generateMatches,
      filterMatchesByWeek,
      setGroupCount,
      setMaxTeamsPerGroup,
      setTournamentFormat,
      moveTeam,
      setCopaStage,
      randomizeTeams,
      resetGroups,
    }),
    [
      groups,
      maxTeamsPerGroup,
      tournamentFormat,
      copaStage,
      matches,
      currentWeek,
      setCurrentWeek,
      generateMatches,
      filterMatchesByWeek,
      setGroupCount,
      setMaxTeamsPerGroup,
      setTournamentFormat,
      moveTeam,
      setCopaStage,
      randomizeTeams,
      resetGroups,
    ]
  );

  return (
    <TournamentContext.Provider value={contextValue}>
      {children}
    </TournamentContext.Provider>
  );
};

export interface TournamentContextValue {
  groups: TournamentTeamView[][];
  maxTeamsPerGroup: number;
  tournamentFormat: "Liga" | "Copa" | "Grupos";
  copaStage: number;
  matches: TournamentMatch[];
  currentWeek: number;
  setCurrentWeek: (number: number) => void;
  filterMatchesByWeek: (week: number) => TournamentMatch[];
  setGroupCount: (count: number) => void;
  setMaxTeamsPerGroup: (count: number) => void;
  setTournamentFormat: (format: "Liga" | "Copa" | "Grupos") => void;
  moveTeam: (
    sourceGroupIndex: number,
    targetGroupIndex: number,
    sourceTeamIndex: number,
    targetTeamIndex: number | null
  ) => void;
  setCopaStage: (stage: number) => void;
  randomizeTeams: () => void;
  resetGroups: () => void;
  generateMatches: () => void;
}
