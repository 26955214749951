import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";
import React, { useContext, useMemo, useState } from "react";
import { SelectedPlayer } from "./SelectedPlayer";
import { MatchSportTeamView } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { ChangeShirtPopup } from '../playersComponents/ChangeShirtPopup';
import { ChangePositionPopup } from "../playersComponents/ChangePositionPopup";
interface properties {
}

export const SelectedPlayers: React.FC<properties> = ({ }) => {
  const { match, addedPlayers, updateMatchSportTeam } = useContext(PreSelectTeamContext);
  const [openShirtNumberPopup, setOpenShirtNumberPopup] = useState(false);
  const [openPositionPopup, setPositionPopup] = useState(false);
  const [current, setCurrent] = useState<MatchSportTeamView>();

  const handleClose = () => {
    setOpenShirtNumberPopup(false);
    setPositionPopup(false);
  };

  const excludeNumbers = useMemo(() => {
    const values = addedPlayers.map((x) => x.sportTeamNumber!);
    return values;
  }, [addedPlayers]);

  const onChangeShirtNumber = (value: MatchSportTeamView) => {
    handleClose();
    setCurrent(value);
    setOpenShirtNumberPopup(true);
  };

  const handlerChangeShirtNumber = async (sportTeamNumber: number) => {
    const result = await updateMatchSportTeam({ ...current, sportTeamNumber });
    if (result) {
      setPositionPopup(false);
    }
  };

  const onChangePosition = (value: MatchSportTeamView) => {
    handleClose();
    setCurrent(value);
    setPositionPopup(true);
  };

  const handlerChangePosition = async (sportTeamPosition: number) => {
    const result = await updateMatchSportTeam({ ...current, sportTeamPosition });
    if (result) {
      setPositionPopup(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <span className="info-text"> Seleccionados</span>
      </div>

      {addedPlayers.map((j) => (
        <SelectedPlayer
          key={j.matchSportTeamId} value={j}
          onChangeShirtNumber={() => onChangeShirtNumber(j)}
          onChangePosition={() => onChangePosition(j)}
        />
      ))}

      {openShirtNumberPopup && (
        <ChangeShirtPopup
          gender={current?.gender}
          lastnames={current?.sportTeamNames?.split(",")[0]}
          shirtNumber={current?.sportTeamNumber}
          openModal={openShirtNumberPopup}
          onClose={handleClose}
          onChange={handlerChangeShirtNumber}
          excludeNumbers={excludeNumbers}
        />
      )}
      {openPositionPopup && match?.sportId && (
        <ChangePositionPopup
          sportId={match?.sportId}
          position={current?.sportTeamPosition!}
          shirtNumber={current?.sportTeamNumber}
          openModal={openPositionPopup}
          onClose={handleClose}
          onChange={handlerChangePosition}
        />
      )}
    </>
  );
};
