import { useState } from "react";
import { apiRoutes } from "../constants/ApiRouteConstants";
import { convertToUrlParamiter } from "../helpers/filterHelper";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { apiGet, apiPost } from "./BaseService";
import { Member } from "../virtualclub/models/models/members/Member";
import { MembersFilters } from "../virtualclub/models/models/members/Member.filters";
import { MemberUpdated } from "../virtualclub/models/models/members/Members.dto";
import { MemberView } from "../virtualclub/models/models/members/Member.view";
import { useFileService } from "./useFileService";
import { MemberList } from "../virtualclub/models/models/members/Member.list";
import { MemberSportTeam } from "../virtualclub/models/models/members/Member.sportteam";


export const useMembersService = () => {
  const { changeAvatar: changeImage } = useFileService();
  const [data, setData] = useState<PageResult<MemberList>>();
  const [current, setCurrent] = useState<MemberView>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const list = async (
    filters?: MembersFilters
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = apiRoutes.members + convertToUrlParamiter(filters);
      const result = await apiGet<PageResult<MemberView>>(url);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }
      console.log(result);
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const select = (value?: MemberView) => {
    setCurrent(value);
  }

  const changeAvatar = async (teamId: string, file: File, fileType?: string) => {
    changeImage?.(teamId, file, fileType);

    return undefined;
  };

  const getId = async (id: string): Promise<MemberView | undefined> => {
    try {
      const model = await apiGet<MemberView>(
        apiRoutes.member.replace(':id', id)
      );
      setCurrent(model);  
      return model;
    } catch (error) {
      console.error("Error al obtener el miembro:", error);
      setError(error as Error);
      return undefined;
    }
  };

  const update = async (values: Member): Promise<MemberUpdated | undefined> => {
    try {
      const apiUrl = apiRoutes.member.replace(":id", values.memberId!)
      const result = await apiPost<Member, MemberUpdated>(apiUrl, values);

      if (result?.memberId) {
        setData((prevData) => {
          return {
            ...prevData,
            data: (prevData!.data || []).map((row) =>
              row.memberId === values.memberId ? values : row
            ),
          };
        });
      } else {
        setData((prevData) => ({
          ...prevData,
          data: [values, ...(prevData?.data || [])],
        }));
      }
      setCurrent(values);

      return result;
    } catch (er) { }
    return undefined;
  };

  const getSportTeams = async (memberId: string): Promise<MemberSportTeam[]> => {
    try {
      const result = await apiGet<MemberSportTeam[]>(apiRoutes.memberSportTeam.replace(":id", memberId));
      console.log(result);

      return result || [];
    } catch (err) {
      console.error("Error fetching sports teams by member:", err);
      return [];
    }
  };

  return { data, current, isLoading, error, list, select, update,changeAvatar, getId ,getSportTeams};
}