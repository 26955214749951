import React from "react";

interface properties {
  fontWeight?: "500" | "700";
  fontSize?: "24" | "20" | "14" | number;
  gray?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Label: React.FC<properties> = ({
  fontWeight = "500",
  fontSize = "14",
  gray = false,
  style,
  children,
}) => {
  const computedFontSize =
    typeof fontSize === "number" ? fontSize : parseInt(fontSize, 10);
  const textStyle: React.CSSProperties = {
    fontWeight,
    fontSize: computedFontSize,
    color: gray ? "#979ea8" : "inherit",
    ...style,
  };

  return <span style={textStyle}>{children}</span>;
};

export default Label;
