import React, { useState } from "react";
import { Drawer } from "antd";
import { useMediaQuery } from "react-responsive";

interface SideRightPageProps {
  children: React.ReactNode;
  isDrawerVisible?: boolean;
  onCloseDrawer?: () => void;
}

export const SideRightPage: React.FC<SideRightPageProps> = ({
  children,
  isDrawerVisible = false,
  onCloseDrawer,
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <>
      {!isSmallScreen ? (
        <div style={{ maxWidth: "35%", marginTop: 5 }}>{children}</div>
      ) : (
        <Drawer
          placement="right"
          onClose={onCloseDrawer}
          open={isDrawerVisible}
          width={700}
        >
          {children}
        </Drawer>
      )}
    </>
  );
};
