import React, { useContext } from "react";
import styles from "./TournamentPage.module.css";
import TournamentTeamCard from "./TournamentTeamCard";
import { TournamentsContext } from "../../../contexts/TournamentsContext";

interface TournamentTeamsProps {
  nextStep: () => void;
}

export const TournamentTeams: React.FC<TournamentTeamsProps> = ({
  nextStep,
}) => {
  const { tournamentTeams } = useContext(TournamentsContext);

  const handleRemoveTeam = (teamId: string) => {
    // Aquí puedes manejar la lógica de eliminación del equipo
    console.log("Eliminar equipo con ID:", teamId);
  };

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.text}>Selección de equipos</h2>

        <button className={styles.button} onClick={nextStep}>
          Siguiente
        </button>
      </div>

      <div>
        <h1 className={styles.subText}>Agregados</h1>
        <div className={styles.teamList}>
          {tournamentTeams?.map((team, index) => (
            <TournamentTeamCard
              key={index}
              team={team}
              onRemove={() => handleRemoveTeam(team?.teamId || "")} // Pasamos la función para eliminar
            />
          ))}
        </div>
      </div>
    </>
  );
};
