import React from 'react';
import styles from './Shirt.module.css';
import { ReactComponent as SHIRT } from '../../assets/shirt.svg';
import { NamesHelper } from '../../helpers/NamesHelper';
import { SportTeamCategoryType } from '../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum';

interface properties {
  categoryType?: SportTeamCategoryType,
}

export const Shirt: React.FC<properties> = ({ categoryType }) => {

  let name = "";
  if (categoryType) {
    name = NamesHelper.CategoryTypeShort(categoryType);
  }
  const shirtStyle = {
    color: NamesHelper.CategoryTypeColor(categoryType)
  };
  const letterStyle = {
    color: NamesHelper.CategoryTypeLetterColor(categoryType),
    lineHeight: 1.5
  };
  return (
    <div className={styles.shirtContainer}>
      <div className={styles.shirtImageContainer} style={shirtStyle}>
        <SHIRT className={styles.shirtImage} />
      </div>
      <div className={styles.shirtNumber} style={letterStyle}>{name}</div>
    </div>
  );
};

