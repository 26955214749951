import React, { useEffect, useState } from "react";
import styles from "./Avatar.module.css";
import LogoDefault from "../../assets/VC.svg";
import { configApp } from "../../services/configApp";

interface Properties {
  imageId?: string;
  defaultImage?: string;
  onImageUpload?: (file: File, fileType: string) => Promise<void>;
  canChangeImage?: boolean;
  size?: number;
  color?: string;
  handleError?: boolean;
}

export const Avatar: React.FC<Properties> = ({
  imageId,
  defaultImage = LogoDefault,
  onImageUpload,
  canChangeImage = false,
  color,
  size = 150,
  handleError = true,
}) => {
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (selectedLogo) {
      setImageUrl(URL.createObjectURL(selectedLogo));
    } else if (imageId) {
      setImageUrl(`${configApp.avatarUrl}${imageId}.png`);
    } else {
      setImageUrl(defaultImage);
    }

    return () => {
      if (selectedLogo) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [selectedLogo, imageId, defaultImage]);

  const handledChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedLogo(file);
      if (onImageUpload) {
        onImageUpload(file, "Logo");
      }
    }
  };

  const handlerChangeClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const containerCustomStyle: React.CSSProperties = {
    width: size,
    height: size,
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const circleStyle: React.CSSProperties = {
    backgroundColor: color,
    width: size * 0.9,
    height: size * 0.9,
    borderRadius: "50%",
  };

  const handleImageError = () => {
    setImageUrl(defaultImage);
  };

  return (
    <div className={styles.avatarContainer} style={containerCustomStyle}>
      <img
        src={imageUrl}
        alt="Team logo"
        className={styles.avatarImage}
        style={imageStyle}
        onError={handleError ? handleImageError : undefined}
      />
      <div className={styles.avatarCircle} style={circleStyle}></div>
      {canChangeImage && (
        <div className={styles.perfilImageOverlay} onClick={handlerChangeClick}>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handledChangeImage}
          />
          <i className="fa-solid fa-pen-to-square fa-2x perfil-logo-edit"></i>
        </div>
      )}
    </div>
  );
};
