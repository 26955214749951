import React from "react";
import { useParams } from "react-router-dom";
import { MatchContextProvider } from "../../contexts/MatchContext";
import { MatchContentPage } from "./MatchContentPage";

const MatchPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <MatchContextProvider matchId={id}>
      <MatchContentPage />
    </MatchContextProvider>
  );
};

export default MatchPage;
