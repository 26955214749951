import React from "react";
import logo from "./assets/Logo.png";
import backgroundImage from "../assets/background.jpg";

const AboutSection: React.FC = () => {
  return (
    <section className="about-section">
      <div className="about-card">
        <div className="about-text">
          <h3>¿Quiénes Somos?</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className="about-logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
