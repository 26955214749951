import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useTeamsService } from "../services/useTeamsService";
import { Team } from "../virtualclub/models/models/teams/Team";
import { useFederationTeamsService } from "../services/useFederationTeamsService";
import { FederationTeamFederate } from '../virtualclub/models/models/federationsTeams/FederationTeam.federate';
import { TeamsFilters } from '../virtualclub/models/models/teams/Teams.filters';

interface Props {
  baseFilters?: TeamsFilters;
}

export const TeamsContext = createContext<TeamsContextValue>(
  {} as TeamsContextValue
);

export const TeamsContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  baseFilters,
}) => {
  const {
    data,
    current,
    isLoading,
    error,
    list,
    select,
    changeAvatar,
    update: updateService,
  } = useTeamsService();
  const { federate } = useFederationTeamsService();
  const [filters, setFilters] = useState<TeamsFilters>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list(filters);
  }, [debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: TeamsFilters) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = useCallback(() => {
    select(new Team());
    setEditing(true);
  }, [select]);

  const edit = useCallback(() => {
    if (current && current?.teamId) {
      setEditing(true);
      return true;
    }
    return false;
  }, [current]);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: Team) => {
      select(value);
    },
    [select]
  );

  const update = useCallback(
    async (value: Team): Promise<boolean> => {
      const response = await updateService(value);
      if (!response) {
        return false;
      }
      setEditing(false);
      return true;
    },
    [updateService]
  );

  const updateAvatar = useCallback(
    async (teamId: string, file: File, fileType?: string): Promise<void> => {
      changeAvatar(teamId, file, fileType);
    },
    [changeAvatar]
  );

  const federateTeam = useCallback(
    async (value: FederationTeamFederate): Promise<boolean> => {
      const response = await federate(value);
      return !!response;
    },
    []
  );

  const contextValue = useMemo<TeamsContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      cancel,
      update,
      updateAvatar,
      federateTeam,
    }),
    [
      isLoading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      update,
      updateAvatar,
      federateTeam,
    ]
  );

  return (
    <TeamsContext.Provider value={contextValue}>
      {children}
    </TeamsContext.Provider>
  );
};

export interface TeamsContextValue {
  isLoading?: boolean;
  filters?: TeamsFilters;
  editing: boolean;
  data?: PageResult<Team>;
  current?: Team;

  updateFilters: (filters?: TeamsFilters) => void;
  gotoPage: (page: number) => void;
  changePageSize: (size: number) => void;

  create: () => void;
  selected: (value: Team) => void;
  edit: () => boolean;
  cancel: () => void;
  update: (value: Team) => Promise<boolean>;
  updateAvatar: (
    teamId: string,
    file: File,
    fileType?: string
  ) => Promise<void>;
  federateTeam: (value: FederationTeamFederate) => Promise<boolean>;
}
