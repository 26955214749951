import { useState } from "react";
import { apiRoutes } from "../constants/ApiRouteConstants";
import { convertToUrlParamiter } from "../helpers/filterHelper";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { apiGet, apiPatch, apiPost } from "./BaseService";
import { MemberUpdated } from "../virtualclub/models/models/members/Members.dto";
import { SportTeam } from '../virtualclub/models/models/sportsTeams/SportTeam';
import { SportsTeamsCreate, SportsTeamsCreated } from '../virtualclub/models/models/sportsTeams/SportsTeams.create';
import { SportTeamView } from '../virtualclub/models/models/sportsTeams/SportTeam.view';
import { SportsTeamsFilters } from '../virtualclub/models/models/sportsTeams/SportsTeams.filters';

export const useSportsTeamsService = () => {
  const [data, setData] = useState<PageResult<SportTeamView>>();
  const [current, setCurrent] = useState<SportTeamView>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const create = async (values: SportsTeamsCreate): Promise<SportsTeamsCreated | undefined> => {
    try {
      const response = await apiPost<SportsTeamsCreate, SportsTeamsCreated>(
        apiRoutes.sportsTeams,
        values
      );
      return response;
    } catch (er) {
      console.log(er);
    }
    return undefined;
  };


  const list = async (
    filters?: SportsTeamsFilters
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = apiRoutes.sportsTeams + convertToUrlParamiter(filters);
      const result = await apiGet<PageResult<SportTeamView>>(url);
      console.log(filters);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }
    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const select = (value?: SportTeamView) => {
    setCurrent(value);
  }



  const getId = async (id: string): Promise<SportTeamView | undefined> => {
    try {
      const model = await apiGet<SportTeamView>(
        apiRoutes.sportTeamId.replace(':id', id)
      );

      setCurrent(model);  
      return model;
    } catch (error) {
      console.error("Error al obtener el jugador:", error);
      setError(error as Error);
      return undefined;
    }
  };
  const update = async (values: SportTeamView): Promise<SportTeamView | undefined> => {
    try {
      const apiUrl = apiRoutes.sportTeamId.replace(":id", values.sportTeamId!)
      const result = await apiPatch<SportTeamView, SportTeamView>(apiUrl, values);

      if (current?.sportTeamId) {
        setData((prevData) => {
          return {
            ...prevData,
            data: (prevData!.data || []).map((row) =>
              row.memberId === values.memberId ? values : row
            ),
          };
        });
      } else {
        setData((prevData) => ({
          ...prevData,
          data: [values, ...(prevData?.data || [])],
        }));
      }
      setCurrent(values);

      return result;
    } catch (er) {
      console.log(er);
    }
    return undefined;
  };

  const updateCategory = async (values: SportTeamView): Promise<SportTeamView | undefined> => {
    try {
      const apiUrl = apiRoutes.sportTeamId.replace(":id", values.memberId!)
      const result = await apiPost<SportTeamView, SportTeamView>(apiUrl, values);

      if (current?.sportTeamId) {
        setData((prevData) => {
          return {
            ...prevData,
            data: (prevData!.data || []).map((row) =>
              row.memberId === values.memberId ? values : row
            ),
          };
        });
      } else {
        setData((prevData) => ({
          ...prevData,
          data: [values, ...(prevData?.data || [])],
        }));
      }
      setCurrent(values);

      return result;
    } catch (er) {
      console.log(er);
    }
    return undefined;
  };


  return { data, create, current, isLoading, error, list, select, update, getId };
}