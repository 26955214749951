import React from "react";
import TitleText from "./TitleText"; // Componente TitleText
import "./PosicionesList.css"; // Estilo CSS para PosicionesList

const PosicionesList: React.FC = () => {
  return (
    <div className="posiciones-list">
      <TitleText text="Tabla de Posiciones" />
      <table>
        <thead>
          <tr>
            <th className="header">Pos</th>
            <th className="header">Club</th>
            <th className="header">PJ</th>
            <th className="header">DG</th>
            <th className="header">Pts</th>
          </tr>
        </thead>
        <tbody>
          {/* Aquí se deben incluir las filas de datos de la tabla */}
          <tr>
            <td>1</td>
            <td>Club A</td>
            <td>10</td>
            <td>+15</td>
            <td>30</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Club B</td>
            <td>10</td>
            <td>+12</td>
            <td>25</td>
          </tr>
          {/* Agrega más filas según necesites */}
        </tbody>
      </table>
    </div>
  );
};

export default PosicionesList;
