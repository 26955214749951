import React from "react";
import style from "./ButtonSave.module.css";

interface properties {
  onClick?: () => void;
  saveText?: string;
}

export const ButtonSave: React.FC<properties> = ({
  onClick,
  saveText = "Guardar",
}) => {
  return (
    <button className={style.buttonSave} onClick={onClick}>
      {saveText}
    </button>
  );
};
