import { AccountView } from '../accounts/Account.view';
import { CategoryView } from '../categories/Category.view';

export class TournamentView {
  tournamentId?: string;
  accountId?: string;
  account?: AccountView;
  sportId?: number;
  categoryId?: number;
  category?: CategoryView;
  gender?: number;
  tournamentCode?: string;
  tournamentName?: string;
  tournamentStatus?: number;
  tournamentType?: number;
  registrationDate?: Date;
  closingRegistrationDate?: Date;
  tournamentStartDate?: Date;
  tournamentFinalDate?: Date;
}
