import React from "react";
import { useContext } from "react";
import { Pagination, Spin } from "antd";
import { GeneralContext } from "../../contexts/GeneralContext";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { MembersContext } from "../../contexts/MembersContext";
import { DateHelper } from "../../helpers/DateHelper";
import { ColStatus } from "../../components/TableCard/ColStatus";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";

interface properties {
  onSelect?: () => void;
}

export const MembersList: React.FC<properties> = ({ onSelect }) => {
  const {
    current,
    isLoading: searching,
    data,
    selected,
    gotoPage,
    getId,
  } = useContext(MembersContext);

  const handleRowClick = async (modelId: string) => {
    const member = await getId(modelId);
    if (member) {
      onSelect?.();
    }
  };

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }

  return (
    <Spin spinning={searching}>
      <div className="small-container">
        {data?.data &&
          data?.data.map((record) => {
            const hasPendingTeam = record.sportsTeams?.some(
              (team) => team.sportTeamStatus === SportTeamStatus.Pending
            );

            return (
              <Row
                key={record.memberId ?? ""}
                id={record.memberId ?? ""}
                gender={record.gender}
                onClick={handleRowClick}
                active={record.memberId === current?.memberId}
              >
                <ColText value={record.fileId ?? ""} grayText width="150px" />
                <ColText value={record.lastnames + "," + record.firstnames} />
                <ColText
                  value={
                    record.datebirth
                      ? `${DateHelper.getDateString(
                          record.datebirth
                        )} (${DateHelper.calculateAge(record.datebirth)})`
                      : "--"
                  }
                  grayText
                />
                <ColText value={record.email ?? "Sin Correo"} grayText />
                <ColStatus value={hasPendingTeam ? 1 : 3} />
              </Row>
            );
          })}
      </div>

      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
