import { plainToInstance } from 'class-transformer';
import { IsNotEmpty, IsString, IsOptional, IsEnum } from 'class-validator';
import { SportTeamCategoryType } from '../sportsTeamsCategories/SportTeamCategoryType.enum';

export class SportsTeamsCreate {
  @IsString()
  @IsNotEmpty()
  federationId: string;

  @IsString()
  @IsNotEmpty()
  federationTeamId: string;

  @IsString()
  @IsNotEmpty()
  memberId: string;

  @IsNotEmpty()
  @IsEnum(SportTeamCategoryType)
  sportTeamCategoryType?: SportTeamCategoryType;

  @IsOptional()
  categoryId?: number;

  @IsOptional()
  position?: number;

  @IsOptional()
  shirtNumber?: number;

  static fromPlain(plain: SportsTeamsCreate): SportsTeamsCreate {
    return plainToInstance(SportsTeamsCreate, plain);
  }
}

export class SportsTeamsCreated {
  sportTeamId: string;
}
