import React from "react";
import heroImage from "./assets/HeroImage.png";
const HeroSection: React.FC = () => {
  return (
    <section
      className="federation-hero-section"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="federation-hero-text">
        <h2>Asociacion Amateur de Hockey de</h2>
        <h1> Buenos Aires</h1>
      </div>
    </section>
  );
};

export default HeroSection;
