import React, { useContext, useState } from "react";
import "./Layout.css";
import { GeneralContext } from "../contexts/GeneralContext";
import { AuthorizationHelper } from "../helpers/AuthorizationHelper";

import { NavItem } from "../components/NavItem/NavItem";
import { Avatar } from "../components/Avatar/Avatar";
import { routes } from "../constants/RouteConstants";
import { AccountType } from "../virtualclub/models/models/accounts/AccountType.enum";

export const SideMenu: React.FC = () => {
  const [auth] = useState(new AuthorizationHelper());

  return (
    <aside className="aside">
      <div className="aside-container">
        <div className="avatar">
          <Avatar imageId={auth.get()?.accountId} handleError={false} />
        </div>
        <div className="club-text">
          <h2 className="club-name">{auth.get()?.username}</h2>
        </div>
        <nav className="nav">
          <NavItem
            caption="Fixture"
            icon="fa-regular fa-calendar"
            to={routes.Matches}
          />
          <NavItem
            caption="Plantel"
            icon="fa-regular fa-screen-users"
            to={routes.TechnicalStaff}
          />
          <NavItem
            caption="Jugadores"
            icon="fa-regular fa-users"
            to={routes.Players}
          />
          {auth.isAccountType(AccountType.Club) && (
            <>
              <NavItem
                caption="Tactica"
                icon="fa-regular fa-users-class"
                to={routes.TeamsTactics}
              />
              <NavItem
                caption="Socios"
                icon="fa-regular fa-people-arrows"
                to={routes.Members}
              />
              <NavItem
                caption="Equipos"
                icon="fa-solid fa-shield"
                to={routes.Teams}
              />
            </>
          )}

          {auth.isAccountType(AccountType.Federation) && (
            <>
              <NavItem
                caption="Federados"
                icon="fa-solid fa-shield-check"
                to={routes.FederationTeams}
              />
              <NavItem
                caption="Categorias"
                icon="fa-solid fa-layer-group"
                to={routes.Categories}
              />
              <NavItem
                caption="Torneos"
                icon="fa-solid fa-trophy"
                to={routes.Tournaments}
              />
            </>
          )}
          <NavItem
            caption="Mi cuenta"
            icon="fa-regular fa-id-card"
            to={routes.MyAccount}
          />
        </nav>
      </div>
    </aside>
  );
};
