import React from "react";
import { useParams } from "react-router-dom";
import { MatchContextProvider } from "../../contexts/MatchContext";
import { GenericPage } from "../../components/Screen/GenericPage";
import { FieldGame } from "../../components/FieldGame/FieldGame";
import { Counter } from "../../screens/match/playersComponents/Counter";

const TeamTacticsScreen: React.FC = () => {
  return (
    <GenericPage>
      <div
        style={{
          width: "20%",
          marginRight: 10,
        }}
      >
        <FieldGame sportId={1} width={300} height={500} />
      </div>

      <div
        style={{
          width: "80%",
          marginTop: 5,
          display: "flex",
        }}
      >
        <div style={{ flex: 0.5 }}>
          <span style={{ fontWeight: 700, fontSize: 18 }}>
            Lista de Buena Fe
          </span>
        </div>
        <div style={{ flex: 0.5 }}>
          <span
            className="info-text"
            style={{
              fontWeight: 500,
              fontSize: 14,
              display: "flex",
              justifyContent: "end",
            }}
          >
            Min {8} | Max {20} |
            <span style={{ fontWeight: 700, fontSize: 18, color: "#0100B4" }}>
              Act {0}
            </span>
          </span>
        </div>
      </div>
    </GenericPage>
  );
};

export default TeamTacticsScreen;
