import React, { createContext, useCallback, useMemo, useState } from "react";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { MatchView } from "../virtualclub/models/models/matches/Match.view";
import { useMatchesService } from "../services/useMatchesService";

interface Props {
  matchId?: string;
}

export const MatchContext = createContext<MatchContextValue>(
  {} as MatchContextValue
);

export const MatchContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  matchId,
}) => {
  const { get } = useMatchesService();
  const [match, setMatch] = useState<MatchView>();

  const loadData = useCallback(async () => {
    if (matchId) {
      const matchdb = await get(matchId);
      setMatch(matchdb);
    }
  }, [get, matchId]);

  const loader = useAsyncCall(loadData, [matchId]);

  const contextValue = useMemo<MatchContextValue>(
    () => ({
      loading: loader.loading,
      match,
    }),
    [loader.loading, match]
  );

  return (
    <MatchContext.Provider value={contextValue}>
      {children}
    </MatchContext.Provider>
  );
};

export interface MatchContextValue {
  loading?: boolean;
  match?: MatchView;
}
