import { useState } from 'react';

import { apiGet, apiPost } from './BaseService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import {
  convertToUrlParamiter
} from '../helpers/filterHelper';
import { PageResult } from '../virtualclub/models/base/PageResult';
import { Category } from '../virtualclub/models/models/categories/Category';
import { CategoriesFilters } from '../virtualclub/models/models/categories/Categories.filters';


export const useCategoriesService = () => {
  const [data, setData] = useState<PageResult<Category>>();
  const [current, setCurrent] = useState<Category>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const list = async (
    filters?: CategoriesFilters
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const url = apiRoutes.categories + convertToUrlParamiter(filters);
      const result = await apiGet<PageResult<Category>>(url);
      setData(result);
      if (result?.data?.length) {
        setCurrent(result.data[0]);
      } else {
        setCurrent(undefined);
      }

    } catch (err) {
      console.log(err);
      setError(err as Error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const select = (value?: Category) => {

    setCurrent(value);
  }
  const update = async (values: Category): Promise<Category | undefined> => {
    const apiUrl = apiRoutes.categoriesId.replace(":id", values.categoryId?.toString() ?? "")
    const result = await apiPost<Category, Category>(apiUrl, values);

    if (current?.categoryId) {
      setData((prevData) => {
        return {
          ...prevData,
          data: (prevData!.data || []).map((row) =>
            row.categoryId === values.categoryId ? values : row
          ),
        };
      });
    } else {
      setData((prevData) => ({
        ...prevData,
        data: [values, ...(prevData?.data || [])],
      }));
    }
    setCurrent(values);
    return result;
  }


  return { data, current, isLoading, error, list, select, update };
}