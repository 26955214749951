import React from "react";
import "./SportImage.css"
import Logo from "../../assets/VC.svg";

interface properties {
  sportId?: number;
  className?: string;
  style?: React.CSSProperties;
}

export const SportImage: React.FC<properties> = ({ sportId, className, style }) => {

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  const getSportImage = (id?: number): string => {
    if (id) {
      return `/assets/sport${id}.svg`;
    }
    return Logo;
  };

  const icon = getSportImage(sportId);

  return <div className="sport-icon" style={style}>
    <img
      src={icon}
      alt={``}
      onError={handleImageError}
      className={className}
    />
  </div>

  // return <svg className="svg-icon" fill="black">
  //   <use href="/assets/sport1.svg#icon" style={{ color: "red", backgroundColor: "green" }}></use>
  // </svg>;
};
