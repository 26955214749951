import React from "react";
import "./FormItem.css";
import { Form, Input } from 'antd';

interface properties {
  name?: string;
}

export const FormItemHidden: React.FC<properties> = ({ name }) => {
  return <Form.Item name={name} style={{ display: 'none' }}>
    <Input type="hidden" />
  </Form.Item>

}

