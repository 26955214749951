import React from "react";
import { ReactComponent as C0 } from "../../assets/shirts/cs0.svg";
import { ReactComponent as C1 } from "../../assets/shirts/cs1.svg";
import { ReactComponent as C3 } from "../../assets/shirts/cs3.svg";
import { SportTeamCategoryType } from "../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import "./tshirtShort.css";
import { NumberHelper } from "../../helpers/NumberHelper";
import { NamesHelper } from "../../helpers/NamesHelper";

interface properties {
  gender: number;
  sportTeamCategoryType: SportTeamCategoryType;
  number?: number;
  color?: string;
  reverse?: boolean;
}

export const TShirtShort: React.FC<properties> = ({
  number,
  gender,
  color = "gray",
  sportTeamCategoryType,
  reverse = false,
}) => {
  const shirtStyle = {
    color,
    transform: reverse ? "scaleX(-1)" : "none",
  };

  return (
    <div className="activity-shirt">
      {sportTeamCategoryType === SportTeamCategoryType.Player &&
        gender === 1 && (
          <C1 className="activity-shirt-image" style={shirtStyle} />
        )}
      {sportTeamCategoryType === SportTeamCategoryType.Player &&
        gender === 0 && (
          <C0 className="activity-shirt-image" style={shirtStyle} />
        )}
      {sportTeamCategoryType === SportTeamCategoryType.Player && (
        <div
          className={`activity-shirt-number ${reverse ? "reverse-number" : ""}`}
        >
          {NumberHelper.zero(number)}
        </div>
      )}

      {sportTeamCategoryType !== SportTeamCategoryType.Player && (
        <>
          <C3
            className="activity-shirt-image"
            style={{
              color: NamesHelper.CategoryTypeColor(sportTeamCategoryType),
              transform: reverse ? "scaleX(-1)" : "none", // Aplica la inversión también para este caso
            }}
          />
          <div
            className="activity-shirt-text"
            style={{
              color: NamesHelper.CategoryTypeLetterColor(sportTeamCategoryType),
            }}
          >
            {NamesHelper.CategoryTypeShort(sportTeamCategoryType)}
          </div>
        </>
      )}
    </div>
  );
};
