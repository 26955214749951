import { PreSelectTeamContext } from "./PreSelectTeamContext";
import React, { useContext, useState } from "react";
import { SportTeamView } from '../../../virtualclub/models/models/sportsTeams/SportTeam.view';
import { Button } from "antd";
import Popup from '../../../components/Modal/Popup';
import { ButtonSaveCancel } from '../../../components/Form/Buttons/ButtonSaveCancel';

interface properties {
  onChangeShirtNumber?: (value: SportTeamView) => void;
  onChangePosition?: (value: SportTeamView) => void;
}

export const PreSelectedFotter: React.FC<properties> = ({ onChangeShirtNumber, onChangePosition }) => {
  const { isValid, closedPreselect, enabled } = useContext(PreSelectTeamContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  function handlerCloseList(): void {
    setIsModalVisible(true);
  }

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    closedPreselect();
    handleClose();
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 0.45 }}>
        </div>
        {enabled &&
          <div style={{ flex: 0.2 }}>
            <Button type="primary" disabled={!isValid} onClick={handlerCloseList}>
              Presentar lista de buena fe
            </Button>
          </div>
        }
      </div>
      <Popup
        visible={isModalVisible}
        title="Confirmar..."
        showCloseButton
        showBackButton
        width={600}
        onClose={handleClose}
        onBack={handleClose}
        type="warning"

      >
        <div>
          <p>¿Estás seguro de presentar la lista de buena fe ?<br />Una ves presentada no se podra modificar los jugadores</p>

          <ButtonSaveCancel
            saveText="Confirmar"
            onSaveClick={handleConfirm}
            onCancelClick={handleClose}
          />
        </div>
      </Popup>
    </>
  );
};
