import React, { forwardRef } from "react";
import "./Input.css";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ disabled, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        disabled={disabled}
        className={`custom-input ${disabled ? "disabled" : ""}`}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
