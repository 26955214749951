import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";
import { useMatchesService } from "../services/useMatchesService";
import { SearchFilters } from "../virtualclub/models/filters/BaseFilters";
import { MatchView } from '../virtualclub/models/models/matches/Match.view';
import { MatchesFilters } from '../virtualclub/models/models/matches/Matches.filters';

interface Props {
  baseFilters?: MatchesFilters;
}

export const MatchesContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const { data, current, isLoading, error, list } = useMatchesService();
  const [filters, setFilters] = useState<MatchesFilters>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list(filters);
  }, [debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: MatchesFilters) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = () => {
    //setCurrent({} as IPlantel);
    setEditing(true);
  };

  const edit = useCallback(() => {
    // if (current && current?.socioId) {
    //   setEditing(true);
    //   return true;
    // }
    return false;
  }, [current]);

  const cancel = () => {
    setEditing(false);
  };

  const update = useCallback(
    async (value: MatchView): Promise<boolean> => {
      const updatedRow = { ...current, ...value };
      // const response = await PlantelTecnicoService.update(updatedRow);
      // if (!response) {
      //   return false;
      // }
      // if (current?.socioId) {
      //   setData((prevData) => {
      //     return {
      //       ...prevData,
      //       data: (prevData!.data || []).map((row) =>
      //         row.socioId === updatedRow.socioId ? updatedRow : row
      //       ),
      //     };
      //   });
      // } else {
      //   setData((prevData) => ({
      //     ...prevData,
      //     data: [updatedRow, ...(prevData?.data || [])],
      //   }));
      // }
      // setCurrent(updatedRow);
      setEditing(false);
      return true;
    },
    [current]
  );

  const contextValue = useMemo<MatchesContextValue>(
    () => ({
      isLoading: isLoading || loader.loading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      create,
      edit,
      cancel,
      update,
    }),
    [
      isLoading,
      loader.loading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      edit,
      update,
    ]
  );

  return (
    <MatchesContext.Provider value={contextValue}>
      {children}
    </MatchesContext.Provider>
  );
};

export const MatchesContext = createContext<MatchesContextValue>(
  {} as MatchesContextValue
);

export interface MatchesContextValue {
  isLoading?: boolean;
  filters?: MatchesFilters;
  editing: boolean;
  data?: PageResult<MatchView>;
  current?: MatchView;

  updateFilters: (filters?: MatchesFilters) => void;
  gotoPage: (page: number) => void;
  changePageSize: (size: number) => void;

  create: () => void;

  edit: () => boolean;
  cancel: () => void;
  update: (value: MatchView) => Promise<boolean>;
}
