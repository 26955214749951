import React, { useState } from "react";
import { TechnicalStaffList } from "./TechnicalStaffList";
import { TechnicalStaffCard } from "./TechnicalStaffCard";
import { SportsTeamsContextProvider } from "../../contexts/SportsTeamsContext";
import { GenericPage } from "../../components/Screen/GenericPage";
import { ContentPage } from "../../components/Screen/ContentPage";
import { SideRightPage } from "../../components/Screen/SideRightPage";
import { TechnicalStaffSearch } from "./TechnicalStaffSearch";

const TechnicalStaffScreen: React.FC = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleSelect = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  return (
    <SportsTeamsContextProvider baseFilters={{ isTechnical: true }}>
      <GenericPage>
        <ContentPage>
          <TechnicalStaffSearch />
          <TechnicalStaffList onSelect={handleSelect} />
        </ContentPage>
        <SideRightPage
          isDrawerVisible={isDrawerVisible}
          onCloseDrawer={handleCloseDrawer}
        >
          <TechnicalStaffCard />
        </SideRightPage>
      </GenericPage>
    </SportsTeamsContextProvider>
  );
};

export default TechnicalStaffScreen;
