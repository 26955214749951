import React from 'react';
import { PositionsType } from "../../virtualclub/models/interfaces/PositionsType";
import { FieldImage } from "../../components/Images/FieldImage";

interface Player {
  x: number;
  y: number;
  number: string;
  color: string;
}

export interface FieldGamePlayer {
  position: number;
  number?: string;
  color?: string;
}

interface FieldProps {
  sportId: number;
  players?: FieldGamePlayer[];
  width: number;
  height: number;
  viewPoint?: "Circle" | "Number" | "Name"
  onChange?: (position: number) => void;
}

export const FieldGame: React.FC<FieldProps> = ({ sportId, players, width, height, viewPoint, onChange }) => {

  const positions = PositionsType[sportId];
  // TODO: Pasara una lista generica para multiples formaciones
  const formacion = [
    { x: 50, y: 80, },

    { x: 20, y: 60, },
    { x: 40, y: 65, },
    { x: 60, y: 65, },
    { x: 80, y: 60, },

    { x: 20, y: 35, },
    { x: 40, y: 45, },
    { x: 60, y: 45, },
    { x: 80, y: 35, },

    { x: 40, y: 25, },
    { x: 60, y: 25, },

  ];

  let pys: Player[] = [];

  positions.map((p, i) => {
    const ex = players?.filter(x => x.position === i);
    const color = ex?.length ? "#FAE042" : "#E5E5E5";
    const text = ex?.length ? ex[0].number : positions[i].shortName;

    pys.push(
      {
        x: formacion[i].x, y: formacion[i].y, number: text ?? "", color
      },
    );
  });

  const viewText = viewPoint !== "Circle";

  return (
    <div style={{ position: 'relative', width, height }}>
      <FieldImage sportId={sportId} style={{ width, height }} />
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
      >
        {pys.map((player, index) => (
          <g
            key={index}
            transform={`translate(${width * player.x / 100}, ${height * player.y / 100})`}
            style={{ cursor: onChange ? 'pointer' : 'default' }} // Cambiar el cursor si onChange está presente
            onClick={() => onChange && onChange(index)} // Llamar a onChange con el índice del jugador
          >
            <circle r={width / 15} fill={player.color} />
            {viewText &&
              <text x="0" y="0" textAnchor="middle" dy=".4em" fontSize={width / 25} fontWeight={1000} fill="black">
                {player.number}
              </text>
            }
          </g>
        ))}
      </svg>
    </div>
  );
};

