import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { useDebounce } from "../hooks/useDebounce";

import { Member } from "../virtualclub/models/models/members/Member";
import { MembersFilters } from "../virtualclub/models/models/members/Member.filters";
import { useMembersService } from "../services/useMembersService";
import { MemberView } from "../virtualclub/models/models/members/Member.view";
import { SportTeam } from "../virtualclub/models/models/sportsTeams/SportTeam";

import { useSportsTeamsService } from "../services/useSportsTeamsService";
import { SportsTeamsCreate } from "../virtualclub/models/models/sportsTeams/SportsTeams.create";
import { SportTeamStatus } from "../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";
import { MemberSportTeam } from "../virtualclub/models/models/members/Member.sportteam";

interface Props {
  baseFilters?: MembersFilters;
}
export const MembersContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const [filters, setFilters] = useState<MembersFilters>(baseFilters || {});
  const {
    data,
    current,
    isLoading,
    error,
    list,
    select,
    changeAvatar,
    update: updateService,
    getId,
    getSportTeams,
  } = useMembersService();

  const { create: createSportsTeam } = useSportsTeamsService();
  const [editing, setEditing] = useState<boolean>(false);
  const [sportsTeams, setSportsTeams] = useState<MemberSportTeam[]>([]);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    await list(filters);
  }, [debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: MembersFilters) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page: page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = useCallback(() => {
    select(new Member());
    setEditing(true);
  }, [select]);

  const edit = useCallback(() => {
    if (current && current?.memberId) {
      setEditing(true);
      return true;
    }
    return false;
  }, [current]);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: MemberView) => {
      select(value);
      setEditing(false);
    },
    [select]
  );

  const update = useCallback(
    async (values: Member): Promise<boolean> => {
      const response = await updateService(values);
      if (!response) {
        return false;
      }
      setEditing(false);
      return true;
    },
    [updateService]
  );

  const updateAvatar = useCallback(
    async (teamId: string, file: File, fileType?: string): Promise<void> => {
      changeAvatar(teamId, file, fileType);
    },
    [changeAvatar]
  );
  const addSportTeam = useCallback(
    async (newTeam: SportsTeamsCreate): Promise<boolean> => {
      try {
        const createdTeam = await createSportsTeam(newTeam);

        if (createdTeam && createdTeam.sportTeamId) {
          const newCategory = {
            categoryId: newTeam.categoryId,
            sportTeamCategoryStatus: SportTeamStatus.Pending,
          };

          const sportTeamToAdd: SportTeam = {
            ...newTeam,
            sportTeamId: createdTeam.sportTeamId,
            federation: {
              accountName: "",
              shortName: "",
            },
            categories: newTeam.categoryId ? [newCategory] : [],
          };

          setSportsTeams((prevTeams) => [...prevTeams, sportTeamToAdd]);

          await loadSportsTeams();

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error("Error al crear el equipo deportivo:", error);
        return false;
      }
    },
    [createSportsTeam]
  );

  const loadSportsTeams = useCallback(async () => {
    if (current?.memberId) {
      const sportTeams = await getSportTeams(current.memberId);
      setSportsTeams(sportTeams);
      console.log(sportTeams);
    }
  }, [current]);

  useEffect(() => {
    loadSportsTeams();
  }, [loadSportsTeams]);

  const contextValue = useMemo<MembersContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      sportsTeams,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      cancel,
      update,
      updateAvatar,
      addSportTeam,
      getId,
    }),
    [
      isLoading,
      filters,
      data,
      current,
      editing,
      sportsTeams,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      update,
      updateAvatar,
      addSportTeam,
      getId,
    ]
  );

  return (
    <MembersContext.Provider value={contextValue}>
      {children}
    </MembersContext.Provider>
  );
};

export const MembersContext = createContext<MembersContextValue>(
  {} as MembersContextValue
);

export interface MembersContextValue {
  isLoading?: boolean;
  filters?: MembersFilters;
  editing: boolean;
  data?: PageResult<MemberView>;
  current?: MemberView;
  sportsTeams: MemberSportTeam[];

  updateFilters: (filters?: MembersFilters) => void;
  gotoPage: (page: number) => void;
  changePageSize: (size: number) => void;

  create: () => void;
  selected: (value: MemberView) => void;
  edit: () => boolean;
  cancel: () => void;
  update: (value: MemberView) => Promise<boolean>;
  updateAvatar: (
    memberId: string,
    file: File,
    fileType?: string
  ) => Promise<void>;
  addSportTeam: (newTeam: SportsTeamsCreate) => Promise<boolean>;
  getId: (id: string) => Promise<MemberView | undefined>;
}
