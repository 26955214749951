import React, { useContext, useMemo } from "react";

import {
  FieldGame,
  FieldGamePlayer,
} from "../../../components/FieldGame/FieldGame";
import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";

export const MatchField: React.FC = () => {
  const { addedPlayers } = useContext(PreSelectTeamContext);

  const players = useMemo(() => {
    return addedPlayers?.map((x) => {
      const number =
        (x.sportTeamNumber ?? 0) < 10
          ? `0${x.sportTeamNumber}`
          : x.sportTeamNumber?.toString();

      const player: FieldGamePlayer = {
        position: x.sportTeamPosition ?? 0,
        number,
        color: "#FAE042",
      };
      return player;
    });
  }, [addedPlayers]);

  return <FieldGame sportId={1} width={300} height={500} players={players} />;
};
