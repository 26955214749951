import React, { useState } from "react";
import { CheckMatchPlayers } from "./checkPlayers/CheckMatchPlayers";
import { CheckMatchContextProvider } from "./CheckMatchContext";
import { MatchView } from "../../../virtualclub/models/models/matches/Match.view";
import { Button, Steps } from "antd";
import { CheckMatchStaff } from './checkStaff/CheckMatchStaff';
import { CheckMatchMenu } from "./checkMenu/CheckMatchMenu";

interface properties {
  match?: MatchView;
  teamId?: string;
}

export const CheckMatchContent: React.FC<properties> = ({ match }) => {
  const [current, setCurrent] = useState(0);
  const items = [
    {
      key: "tabplayers",
      title: `Jugadores`,
      content: <CheckMatchPlayers />
    },
    {
      key: "tabstaff",
      title: `Plantel Tecnico`,
      content: <CheckMatchStaff />
    },
    {
      key: "tabmatch",
      title: `Partido`,
      content: <CheckMatchMenu />
    }
  ];

  const contentStyle: React.CSSProperties = {
    //lineHeight: '260px',
    textAlign: 'center',
    //color: token.colorTextTertiary,
    //backgroundColor: token.colorFillAlter,
    borderRadius: 5,
    border: `1px dashed gray`,
    marginTop: 16,
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (


    <CheckMatchContextProvider match={match}>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{items[current].content}</div>
      <div style={{ marginTop: 24 }}>
        {current < items.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {/* {current === items.length - 1 && (
        <Button type="primary" onClick={() => message.success('Processing complete!')}>
          Done
        </Button>
      )} */}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>

    </CheckMatchContextProvider>
  );
};
