import React from "react";
import { TeamsContextProvider } from "../../contexts/TeamsContext";
import { TournamentsContextProvider } from "../../contexts/TournamentsContext";
import { TournamentsList } from "./TournamentsList";
import TournamentsCard from "./TournamentsCard";

const TournamentsPage: React.FC = () => {
  return (
    <TournamentsContextProvider>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "75%", marginRight: 10, marginTop: 5 }}>
          <TournamentsList />
        </div>
        <div style={{ width: "25%" }}>
          <TournamentsCard />
        </div>
      </div>
    </TournamentsContextProvider>
  );
};

export default TournamentsPage;
