import { plainToInstance } from 'class-transformer';
import { IsNotEmpty, IsString, IsOptional } from 'class-validator';

export class FederationTeamFederate {
  @IsString()
  @IsNotEmpty()
  teamId: string;

  @IsString()
  @IsNotEmpty()
  federationCode: string;

  @IsString()
  @IsOptional()
  teamCode?: string;

  @IsOptional()
  subTeam?: number;

  static fromPlain(plain: FederationTeamFederate): FederationTeamFederate {
    return plainToInstance(FederationTeamFederate, plain);
  }
}
