import { useContext, useState } from "react";

import "./EventPopup.css";
import Popup from "../../../components/Modal/Popup";
import { IPartidosPlantel } from "../../../virtualclub/models/models/_obsolete/partido/IPartidoPlantel";
import { Button } from "antd";
import { MatchEventView } from "../../../virtualclub/models/models/matchesEvents/MatchesEvents.view";
import { MatchTimerContext } from '../../../contexts/MatchTimerContext';
import { NumberHelper } from "../../../helpers/NumberHelper";
import { NamesHelper } from '../../../helpers/NamesHelper';
import { StepSelectTeam } from './StepSelectTeam';
import { StepSelectPlayer } from "./StepSelectPlayer";
import { TeamView } from '../../../virtualclub/models/models/teams/Team.View';
import { MatchSportTeamView } from '../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view';
import { MatchEventType } from '../../../virtualclub/models/models/matchesEvents/MatchEventType.enum';

const jugadores: IPartidosPlantel[] = [
  { partidoPlantelId: 0, numero: 1, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 1, numero: 2, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 2, numero: 3, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 3, numero: 4, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 4, numero: 5, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 5, numero: 6, apellidoNombres: "Juan Pérez" },
  { partidoPlantelId: 6, numero: 7, apellidoNombres: "Juan Pérez" },
];


interface propierties {
  eventType: MatchEventType;

  onClose?: () => void;
}

enum Step {
  SelectTeam = 0,
  SelectPlayer = 1,
  Confirmation = 2
}


export const EventPopup: React.FC<propierties> = ({ eventType, onClose }) => {
  const [step, setStep] = useState<Step>(Step.SelectTeam);
  const [event, setEvent] = useState<MatchEventView>({});
  const { match, elapsedTime, addEvent } = useContext(MatchTimerContext);

  const [currentTime, setCurrentTime] = useState(elapsedTime);

  const StepName = [
    "Seleccionar Club",
    "Seleccionar Jugador",
    "Confirmacion"
  ]

  const handlerPreviusStep = () => {
    setStep((prev) => prev - 1);
  }

  const handlerSelectTeam = (team: TeamView) => {
    setEvent((prev) => ({ ...prev, teamId: team.teamId, team }));
    setStep(Step.SelectPlayer);
  }
  const handlerSelectPlayer = (player: MatchSportTeamView) => {
    setEvent((prev) => ({
      ...prev
      , matchSportTeamId: player.matchSportTeamId
      , sportTeamNames: player.sportTeamNames
      , sportTeamNumber: player.sportTeamNumber?.toString()
    }));
    setStep(Step.Confirmation);
  }

  const handlerSaveEvent = () => {
    addEvent({ ...event, matchEventType: eventType });
    onClose?.();
  }

  return (
    <Popup
      visible={true}
      onClose={onClose}
      showCloseButton
      showBackButton={step !== Step.SelectTeam}
      onBack={handlerPreviusStep}
      width={600}
    >
      <div className="event-header">
        <span className="event-type">
          {NamesHelper.MatchEventTypeName(eventType)}
        </span>
        <span className="event-step">{StepName[step]}</span>
        <span className="event-time">{NumberHelper.toTimer(currentTime)}</span>
      </div>

      {step === Step.SelectTeam && (
        <StepSelectTeam onSelectTeam={handlerSelectTeam} />
      )}

      {step === Step.SelectPlayer && event?.federationTeamId && (
        <StepSelectPlayer federationTeamId={event.federationTeamId} onSelectPlayer={handlerSelectPlayer} />
      )}
      {step === Step.Confirmation && (
        <Button className="event-btn" onClick={handlerSaveEvent}>
          Confirmar evento
        </Button>
      )}
    </Popup>
  );
};

