import React from "react";

import "./FederationScreen.css";
import Header from "./Header";
import HeroSection from "./HeroSection";
import ContentSection from "./ContentSection";

const FederationScreen: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <ContentSection />
    </div>
  );
};

export default FederationScreen;
