import { Row } from "../../../../components/TableCard/Row";
import { ColText } from "../../../../components/TableCard/ColText";
import { ColShirt } from "../../../../components/TableCard/ColShirt";
import { ColButton } from "../../../../components/TableCard/ColButton";
import { useContext } from "react";
import { PreSelectTeamContext } from "../../preSelectTeam/PreSelectTeamContext";
import { MatchSportTeamView } from "../../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view";
import { ColStatus } from "../../../../components/TableCard/ColStatus";
import { MatchSportTeamStatus } from "../../../../virtualclub/models/models/matchesSportTeams/MatchSportTeamStatus.enum";
import { CheckMatchContext } from "../CheckMatchContext";
import { TShirtShort } from "../../../../components/TShirt/tshirtShort";
import "./CheckMatchPlayer.css";
interface properties {
  value: MatchSportTeamView;
  selected?: boolean;
  onClick?: (value: MatchSportTeamView) => void;
  onChangeShirtNumber?: (value: MatchSportTeamView) => void;
  onChangePosition?: (value: MatchSportTeamView) => void;
}
export const CheckMatchPlayer: React.FC<properties> = ({
  value,
  selected,
  onClick,
  onChangeShirtNumber,
  onChangePosition,
}) => {
  const { changeStatus } = useContext(CheckMatchContext);

  const { selectCaptain, updateMatchSportTeam, enabled } =
    useContext(PreSelectTeamContext);

  function handlerChangeShirtNumber(): void {
    if (enabled) {
      onChangeShirtNumber?.(value);
    }
  }

  function handlerSelectCaptain(): void {
    if (enabled) {
      selectCaptain?.(value);
    }
  }

  function handlerClick(): void {
    if (value.matchSportTeamId) {
      changeStatus(value.matchSportTeamId);
    }
  }

  let status = 0;
  switch (value.matchSportTeamStatus) {
    case MatchSportTeamStatus.Absent:
      status = 1;
      break;
    case MatchSportTeamStatus.Present:
      status = 3;
      break;
    default:
      status = 2;
      break;
  }

  return (
    <>
      <div className="matchPlayer-container">
        <TShirtShort
          gender={value.gender ?? 0}
          sportTeamCategoryType={value.sportTeamType!}
          number={value.sportTeamNumber}
          reverse={true}
        />
        <div className="matchPlayer-content">
          <ColText value={value.sportTeamNames ?? "--"} width="200px" />
          <ColButton
            icon="C"
            onClick={handlerSelectCaptain}
            isSelected={value.isCaptain === 1}
          />
          <ColShirt
            value={value.sportTeamNumber?.toString()}
            onClick={handlerChangeShirtNumber}
          />

          <ColStatus value={status} priority={2} />
        </div>
      </div>
    </>
  );
};
