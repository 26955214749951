import React from "react";
import { ColTag } from "./ColTag";
import { PositionsType } from "../../virtualclub/models/interfaces/PositionsType";
import { useMediaQuery } from "react-responsive";

interface properties {
  value?: number;
  onClick?: () => void;
  priority?: 1 | 2 | 3;
  width?: string;
}

export const ColField: React.FC<properties> = ({
  value,
  onClick,
  priority = 1,
  width = "60px",
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });
  const isMediumScreen = useMediaQuery({ maxWidth: 1280 });

  if (priority === 3 && isSmallScreen) return null;
  if (priority === 2 && isMediumScreen) return null;
  const positionType = PositionsType[1];
  const shortName = value !== undefined ? positionType[value]?.shortName : "--";
  return <ColTag value={shortName} width={width} onClick={onClick} />;
};
