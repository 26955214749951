import React from 'react';
import { Input, InputProps, Space, Button } from 'antd';
import { ChangeEvent } from 'react';

interface properties extends InputProps {
  showCleanButton?: boolean;
}



export const InputExt: React.FC<properties> = (props) => {
  const { readOnly, style, showCount, placeholder, disabled, onChange, showCleanButton } = props;

  const styleExt = style ?? {};
  const editMode = !readOnly && !disabled;
  // if (readOnly) {
  //   styleExt.backgroundColor = "#F0F0F0";
  // }

  function handlerChange() {
    if (onChange) {
      const syntheticEvent = {
        currentTarget: {
          value: ''
        }
      } as ChangeEvent<HTMLInputElement>
      onChange(syntheticEvent);
    }
  }

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        {...props}
        style={styleExt}
        showCount={showCount && editMode}
        placeholder={editMode ? placeholder : ''}
      />
      {showCleanButton && <Button
        onClick={handlerChange}
        icon={<i className="fa fa-times" aria-hidden="true"></i>}
      />}
    </Space.Compact>
  );
};
