import { useContext } from "react";
import "./CardsList.css";
import { MatchTimerContext } from '../../contexts/MatchTimerContext';
import { NumberHelper } from "../../helpers/NumberHelper";



export const CardsList = () => {
  const { match, cards, totalElapsedTime } = useContext(MatchTimerContext);
  const homeCards = cards?.filter((event) => event.federationTeamId === match?.homeFederationTeamId);
  const awayCards = cards?.filter((event) => event.federationTeamId === match?.awayFederationTeamId);

  return (
    <div style={{ marginTop: 15 }}>
      <span className="crono-tarjetas-text">Tarjetas</span>
      <div className="crono-tarjetas">
        <div className="tarjetas-columna">
          {homeCards?.map((card, index) => (
            <div key={index} className="jugador-tarjeta">
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <i className="fa-regular fa-triangle green"></i>
                <span>{card.sportTeamNames}</span>
              </div>
              <span>{NumberHelper.toTimer((card.finishSecondsTime ?? 0) - totalElapsedTime)}</span>
            </div>
          ))}
        </div>
        <div className="tarjetas-columna">
          {awayCards?.map((card, index) => (
            <div key={index} className="jugador-tarjeta">
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <i className="fa-regular fa-squatrianglere green"></i>
                <span>{card.sportTeamNames}</span>
              </div>
              <span>{NumberHelper.toTimer((card.finishSecondsTime ?? 0) - totalElapsedTime)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

