import React, { useContext, useEffect, useState } from "react";
import "./TechnicalStaffCard.css";
import { Shirt } from "../../components/Shirt/Shirt";
import Label from "../../components/Form/Label/Label";
import Card from "../../components/Card/Card";
import { SportsTeamsContext } from "../../contexts/SportsTeamsContext";
import { Gender } from "../../virtualclub/models/models/members/Genders.enum";
import { Col, Input, Row } from "antd";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import { AvatarImage } from "../../components/Images/AvatarImage";
import { SportImage } from "../../components/Images/SportImage";
import { StatusIcon } from "../../components/Icons/StatusIcon";
import { NamesHelper } from "../../helpers/NamesHelper";
import { AuthorizationHelper } from "../../helpers/AuthorizationHelper";
import { ButtonRemove } from "../../components/Form/Buttons/ButtonRemove";
import { AccountType } from "../../virtualclub/models/models/accounts/AccountType.enum";
import { SportTeamStatus } from "../../virtualclub/models/models/sportsTeams/SportTeamStatus.enum";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { useSportsTeamsCategoriesService } from "../../services/useSportsTeamsCategoriesService";
import { SportTeamCategory } from "../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategory";

export const TechnicalStaffCard: React.FC = () => {
  const [auth] = useState(new AuthorizationHelper());
  const { updateCategory } = useSportsTeamsCategoriesService();
  const { current } = useContext(SportsTeamsContext);
  const [iconConfig, setIconConfig] = useState<{
    iconClass: string;
    color: string;
  }>({ iconClass: "", color: "" });
  useEffect(() => {
    console.log(current?.gender);
    switch (current?.gender) {
      case Gender.Feminine:
        setIconConfig({ iconClass: "fa-solid fa-mars", color: "blue" });
        break;
      case Gender.Undefined:
        setIconConfig({ iconClass: "fa-solid fa-venus", color: "pink" });
        break;
      default:
        setIconConfig({ iconClass: "", color: "" });
    }
  }, [current?.gender]);

  const handleUpdate = async (status: SportTeamStatus) => {
    const updatedCategory: SportTeamCategory = {
      sportTeamCategoryStatus: status,
    };

    await updateCategory(current?.sportTeamCategoryId ?? "", updatedCategory);
  };
  return (
    <Card>
      <div className="staff-card">
        <div>
          <AvatarImage id={current?.teamId} style={{ width: "60px" }} />
        </div>

        <SportImage sportId={current?.sportId} />

        <Label fontSize="20">
          {current?.team?.teamName}{" "}
          {current?.subTeam
            ? `"${NamesHelper.LetterName(current?.subTeam)}"`
            : ""}
        </Label>
      </div>
      <div className="staff-card">
        <div className="staff-card-shirt">
          <Shirt categoryType={current?.sportTeamCategoryType} />
        </div>

        <div>
          <div className="staff-card-header">
            <Label
              style={{
                width: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              fontSize="20"
              fontWeight="500"
            >
              {current?.firstnames} {current?.lastnames}
            </Label>
            <i
              className={iconConfig.iconClass}
              style={{
                color: iconConfig.color,
                fontSize: "24px",
                marginLeft: "20px",
              }}
            ></i>
          </div>
          <Row gutter={16}>
            <Col span={4}>
              <FormItem label="Edad">
                <Input
                  value={
                    current?.datebirth
                      ? `${new Date().getFullYear() -
                      new Date(current.datebirth).getFullYear()
                      } `
                      : "Fecha de nacimiento no disponible"
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Nacimiento">
                <Input value={current?.datebirth?.toString()} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Documento ID">
                <Input value={current?.identityDocument} />
              </FormItem>
            </Col>
          </Row>
        </div>
      </div>
      <div className="staff-card">
        <div className="staff-card-stats">
          <div className="staff-card-stat">
            <i className="fa-regular fa-check-to-slot staff-card-stat-title" />
            <span className="staff-card-stat-value">0</span>
          </div>
          <div className="staff-card-stat">
            <i className="fa-regular fa-triangle staff-card-stat-title" />
            <span className="staff-card-stat-value">0</span>
          </div>
          <div className="staff-card-stat">
            <i className="fa-regular fa-circle staff-card-stat-title" />
            <span className="staff-card-stat-value">0</span>
          </div>
          <div className="staff-card-stat">
            <i className="fa-regular fa-square staff-card-stat-title" />
            <span className="staff-card-stat-value">0</span>
          </div>
        </div>
      </div>

      <div className="staff-card">
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <AvatarImage id={current?.federationId} style={{ width: "50px" }} />
          <Label
            fontSize="20"
            style={{
              width: 250,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {current?.federationTeamView?.federation?.accountName}
          </Label>
          {current?.sportTeamCategoryStatus === SportTeamStatus.Pending && (
            <div style={{ display: "flex" }}>
              {!auth.isAccountType(AccountType.Club) && (
                <>
                  <ButtonAction
                    type="check"
                    onClick={() => handleUpdate(SportTeamStatus.Active)}
                  />
                  <ButtonRemove
                    onConfirm={() => handleUpdate(SportTeamStatus.Canceled)}
                    itemName={
                      current.federationTeamView?.federation?.accountName
                    }
                  />
                </>
              )}

              {auth.isAccountType(AccountType.Club) && (
                <ButtonRemove
                  onConfirm={() => handleUpdate(SportTeamStatus.Canceled)}
                  itemName={current.federationTeamView?.federation?.accountName}
                />
              )}
            </div>
          )}
          <StatusIcon
            value={current?.sportTeamCategoryStatus as number}
            size="50"
          />
        </div>
      </div>
    </Card>
  );
};
