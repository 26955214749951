import React, { useEffect, useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectExt, SelectExtProps, SelectItem } from "../SelectExt";
import { NamesHelper } from "../../helpers/NamesHelper";

interface properties extends SelectExtProps {
  subTeams?: number;
  showAll?: boolean;
  textAll?: string;
}

export const SelectSubTeam: React.FC<properties> = ({
  showAll = false,
  textAll = showAll ? "Todos los equipos" : "Equipo",
  subTeams = 0,
  value,
  readOnly,
  type,
  onChange,
  ...props
}) => {
  const [items, setItems] = useState<SelectItem[]>([]);
  const [selectedValue, setSelectedValue] = useState(value ?? 0);

  useEffect(() => {
    if (subTeams) {
      let its: SelectItem[] = [];
      if (showAll) {
        its.push({ value: -1, label: textAll });
      }
      its.push({ value: 0, label: "Unico" });
      for (let i = 1; i <= subTeams; i++) {
        its.push({
          value: i,
          label: NamesHelper.LetterName(i),
        } as SelectItem);
      }
      setItems(its);
    }
  }, [showAll, subTeams, textAll]);

  function onChangeValue(
    value: any,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    setSelectedValue(value);
    onChange?.(value, option);
  }

  return (
    <SelectExt
      readOnly={readOnly}
      options={items}
      value={selectedValue}
      placeholder={textAll}
      onChange={onChangeValue}
      type={type}
    />
  );
};
