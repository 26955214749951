import React from "react";
import "./ShirtPopup.css";
import { ButtonAction } from "../Form/Buttons/ButtonAction";

interface Properties {
  visible?: boolean;
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode;
  renderShirt?: () => React.ReactNode;
}

const ShirtPopup: React.FC<Properties> = ({
  title,
  visible = false,
  onClose,
  children,
  renderShirt,
}) => {
  if (!visible) return null;

  return (
    <div className="shirtpopup-overlay">
      <div className="shirtpopup-container">
        <div className="shirtpopup-content">
          <div className="shirtpopup-header">
            <h2 className="shirtpopup-title">{title}</h2>
            <ButtonAction type="cancel" onClick={onClose} tooltip="Cerrar" />
          </div>
          <div>{children}</div>
        </div>

        <div className="shirtpopup-side">
          <div className="shirtpopup-circle"></div>
          {renderShirt?.()} {/* Usamos renderShirt */}
        </div>
      </div>
    </div>
  );
};

export default ShirtPopup;
