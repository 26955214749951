import React, { useContext, useEffect, useState } from "react";
import { MatchContext } from "../../contexts/MatchContext";
import { MatchHeader } from "./matchHeader/MatchHeader";
import { PreSelectTeamContent } from './preSelectTeam/PreSelectTeamContent';
import { useTeamsService } from '../../services/useTeamsService';
import { CheckMatchContent } from './checkMatch/CheckMatchContent';
import { MatchTeamStatus } from '../../virtualclub/models/models/matches/MatchTeamStatus.enum';


interface properties {
}

export const MatchContentPage: React.FC<properties> = ({ }) => {
  const { match } = useContext(MatchContext);
  const { getTeamIdsByAccountId } = useTeamsService();
  const [currentStep, setCurrentStep] = useState<number>(-1);

  useEffect(() => {
    const fetchTeamIds = async () => {
      if (match) {
        let step = 0;
        if (match.homeStatus === MatchTeamStatus.PlayersSubmitted && match.awayStatus === MatchTeamStatus.PlayersSubmitted) {
          const teamIds = await getTeamIdsByAccountId();
          if (match?.homeTeamId && teamIds.includes(match?.homeTeamId)) {
            step = 1;
            //   if (match.homeStatus && match.homeStatus > 1) {
            //     setEnableStep(false);
            //   }
          }
        }
        setCurrentStep(step);
      }
    };
    fetchTeamIds();
  }, [getTeamIdsByAccountId, match, match?.homeStatus, match?.homeTeamId]);
  return (<>
    <MatchHeader />
    {currentStep === 0 && <PreSelectTeamContent match={match} />}
    {currentStep === 1 && <CheckMatchContent match={match} />}
  </>
  );
};
