import { useDebounce } from "../hooks/useDebounce";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { PageResult } from "../virtualclub/models/base/PageResult";
import { useAsyncCall } from "../hooks/useAsyncCall";
import { useCategoriesService } from "../services/CategoriesService";
import { Category } from "../virtualclub/models/models/categories/Category";
import { CategoriesFilters } from '../virtualclub/models/models/categories/Categories.filters';

interface Props {
  baseFilters?: CategoriesFilters;
}

export const CategoryContext = createContext<CategoryContextValue>(
  {} as CategoryContextValue
);

export const CategoryContextProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, baseFilters }) => {
  const {
    data,
    current,
    isLoading,
    error,
    list,
    select,
    update: updateService,
  } = useCategoriesService();

  const [filters, setFilters] = useState<CategoriesFilters>(baseFilters || {});
  const [editing, setEditing] = useState<boolean>(false);

  const [debouncedInput] = useDebounce({ filters }, 1000);

  const debouncedSearch = useCallback(async (): Promise<void> => {
    if (!debouncedInput) {
      return;
    }
    list(filters);
  }, [debouncedInput, filters, list]);

  const loader = useAsyncCall(debouncedSearch, [debouncedInput]);

  const updateFilters = useCallback((newFilters?: CategoriesFilters) => {
    setFilters((values) => ({ ...values, ...newFilters, page: 1 }));
  }, []);

  const gotoPage = useCallback((page: number) => {
    setFilters((values) => ({ ...values, page }));
  }, []);

  const changePageSize = useCallback((size: number) => {
    setFilters((values) => ({ ...values, page: 1, limit: size }));
  }, []);

  const create = useCallback(() => {
    select(Category.fromPlain({}));
    setEditing(true);
  }, [select]);

  const edit = useCallback(() => {
    if (current && current?.categoryId) {
      setEditing(true);
      return true;
    }
    return false;
  }, [current]);

  const cancel = () => {
    setEditing(false);
  };

  const selected = useCallback(
    (value?: Category) => {
      select(value);
    },
    [select]
  );
  const update = useCallback(
    async (value: Category): Promise<boolean> => {
      const response = await updateService(value);
      if (!response) {
        return false;
      }
      setEditing(false);
      return true;
    },
    [updateService]
  );

  const contextValue = useMemo<CategoryContextValue>(
    () => ({
      isLoading,
      filters,
      data,
      current,
      editing,
      updateFilters,
      gotoPage,
      changePageSize,
      selected,
      create,
      edit,
      cancel,
      update,
    }),
    [isLoading, filters, data, current, editing, updateFilters, gotoPage, changePageSize, selected, create, edit, update]
  );

  return (
    <CategoryContext.Provider value={contextValue}>
      {children}
    </CategoryContext.Provider>
  );
};

export interface CategoryContextValue {
  isLoading?: boolean;
  filters?: CategoriesFilters;
  editing: boolean;
  data?: PageResult<Category>;
  current?: Category;

  updateFilters: (filters?: CategoriesFilters) => void;
  gotoPage: (page: number) => void;
  changePageSize: (size: number) => void;

  create: () => void;
  selected: (value: Category) => void;
  edit: () => boolean;
  cancel: () => void;
  update: (value: Category) => Promise<boolean>;
}
