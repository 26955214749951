type PathParams = Record<string, string | number>;
type QueryParams = Record<string, string | number | boolean | Date | undefined>;

export const BuildUrl = (baseUrl: string, pathParams?: PathParams, queryParams?: object): string => {
  // Reemplazar los path params en la URL
  let url = baseUrl;
  if (pathParams) {
    Object.keys(pathParams).forEach((key) => {
      url = url.replace(`:${key}`, encodeURIComponent(String(pathParams[key])) ?? "");
    });
  }

  // Añadir los query params a la URL
  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .filter(([_, value]) => value !== undefined) // Filtra los undefined
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
      .join('&');

    if (queryString) {
      url += (url.includes('?') ? '&' : '?') + queryString;
    }
  }

  return url;
};


export function convertToUrlParamiter(o: any): string {
  const isObject = function (o: any): any {
    return o === Object(o) && !Array.isArray(o) && typeof o !== "function";
  };

  let sep = "?";
  let paramiters = "";

  if (o && isObject(o)) {
    const n: any = {};
    Object.keys(o).forEach(k => {
      if (o[k] !== undefined) {
        paramiters += `${sep}${k}=${o[k]}`;
        sep = "&";
      }
    });
  }

  return paramiters;
}
