import React, { useCallback, useContext, useEffect } from "react";
import { Form, message } from "antd";
import moment from "moment";
import "./MembersCard.css";
import user from "../../public/default-user.png";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { MembersContext } from "../../contexts/MembersContext";
import Card from "../../components/Card/Card";
import MembersForm from "./MembersForm";
import { MembersActivities } from "./MembersActivities";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { ButtonAdd } from "../../components/Form/Buttons/ButtonAdd";

export const MembersCard: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, editing, edit, cancel, update, create } =
    useContext(MembersContext);

  useEffect(() => {
    const formData = {
      ...current,
      datebirth: current?.datebirth ? moment(current.datebirth) : null,
    };
    form.setFieldsValue(formData);
  }, [current, form]);

  const handlerEditClick = () => {
    edit();
  };

  const handlerSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const handlerCancelClick = () => {
    if (current) {
      form.setFieldsValue(current);
    }
    cancel();
  };

  async function onFinish(values: any): Promise<void> {
    try {
      const result =
        (await update({
          ...values,
          //datebirth: values.datebirth ? values.datebirth.toISOString() : null,
        })) ?? false;

      if (result) {
        messageApi.open({
          type: "success",
          content: "Guardado con éxito",
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    messageApi.open({
      type: "error",
      content: "Error al guardar.",
    });
  }

  return (
    <Card>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          height: 48,
          alignItems: "center",
          gap: 8,
        }}
      >
        <ButtonAdd onClick={create} />
        {!editing && (
          <div style={{ marginTop: 8 }}>
            <ButtonAction type="edit" onClick={handlerEditClick} />
          </div>
        )}
        {editing && (
          <ButtonSaveCancel
            onSaveClick={handlerSaveClick}
            onCancelClick={handlerCancelClick}
          />
        )}
      </div>
      <MembersForm
        form={form}
        onFinish={onFinish}
        user={user}
        editing={editing}
        current={current}
      />
      <div>
        <span style={{ fontWeight: 500, fontSize: 14, marginLeft: 10 }}>
          Federaciones
        </span>
        <div>
          <MembersActivities />
        </div>
      </div>
    </Card>
  );
};
