import React, { useEffect, useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import {
  SelectExt,
  SelectExtProps,
  SelectItem,
} from "../../components/SelectExt";
import { useTeamsService } from "../../services/useTeamsService";
import { AvatarImage } from "../Images/AvatarImage";

interface properties extends SelectExtProps {
  showAll?: boolean;
  textAll?: string;
}

export const SelectTeam: React.FC<properties> = ({
  showAll = false,
  textAll = showAll ? "Todos los equipos" : "Equipo",
  value,
  readOnly,
  type,
  onChange,
  ...props
}) => {
  const { isLoading, list, data } = useTeamsService();

  useEffect(() => {
    list();
  }, []);

  const options = useMemo(() => {
    const its: SelectItem[] =
      data?.data?.map((team) => {
        return {
          value: team.teamId,
          label: (
            <span style={{ display: "flex", alignItems: "center" }}>
              <AvatarImage
                id={team.teamId}
                alt={team.teamShort}
                style={{ width: 20, marginRight: 8 }}
              />
              {team.teamShort}
            </span>
          ),
        } as SelectItem;
      }) ?? [];
    if (showAll) {
      its.unshift({ value: -1, label: textAll });
    }
    return its;
  }, [data?.data, showAll, textAll]);

  function onChangeValue(
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    const selectedTeam = data?.data?.find((team) => team.teamId === value);
    onChange?.(selectedTeam, option);
  }

  return (
    <SelectExt
      options={options}
      readOnly={readOnly}
      value={value}
      placeholder={textAll}
      onChange={onChangeValue}
      loading={isLoading}
      type={type}
    />
  );
};
