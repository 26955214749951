import { apiGet, apiPost } from './BaseService';
import { AuthRequest } from '../virtualclub/models/models/login/AuthRequest';
import axios from 'axios';
import { LocalStorageService } from './LocalStorageService';
import { apiRoutes } from '../constants/ApiRouteConstants';
import { Usuario } from '../virtualclub/models/viewmodels/core/Usuario';
import { AuthResponse } from '../virtualclub/models/models/login/AuthResponse';

const Authenticate = async (
  credentials: AuthRequest
): Promise<AuthResponse | undefined> => {
  const auth = await apiPost<AuthRequest, AuthResponse>(
    apiRoutes.userAuth,
    credentials
  );

  if (auth && auth?.token) {
    LocalStorageService.clear();
    // TODO: Guardar en el store repositorio el token actual;
    // ReactGA.set({ userId: userInfo.userId });
    setAxiosAuthorization(auth?.token);
    return auth;
  }
  return undefined;
  //     await StoreService.clear();
  //   return fetch(authUrl, requestOptions)
  //     .then(handleResponse)
  //     .then(async auth => {
  //       const userInfo = await getUserInfo(auth.data.accessToken);
  //       ReactGA.set({ userId: userInfo.userId });
  //       setAxiosAuthorization(auth.data.accessToken);
  //       return {
  //         loggedIn: true,
  //         userInfo: userInfo.data,
  //         idToken: auth.data.idToken,
  //         accessToken: auth.data.accessToken,
  //         refreshToken: auth.data.refreshToken
  //       };
  //     })
  //     .then(async loginResult => {
  //       //Get User Customer data
  //       const userCustomer = await getUserCustomer(loginResult.accessToken, loginResult.userInfo.accounts[0].accountId);
  //       return { ...loginResult, customers: userCustomer.data };
  //     });
};

export const getUserInfo = async (): Promise<Usuario | undefined> => {
  const userInfo = await apiGet<Usuario>(apiRoutes.userInfo);
  return userInfo;
};

const ResetPassword = async (
  code: string,
  password: string
): Promise<boolean> => {
  //const auth = await apiPost<AuthenticateRequest, UserLogin>(apiRoutes.userAuth, credentials);

  //if (auth) {
  //LocalStorageService.clear();
  // TODO: Guardar en el store repositorio el token actual;
  // ReactGA.set({ userId: userInfo.userId });
  //setAxiosAuthorization(auth?.token);
  //}
  //return auth;
  return false;
  //     await StoreService.clear();
  //   return fetch(authUrl, requestOptions)
  //     .then(handleResponse)
  //     .then(async auth => {
  //       const userInfo = await getUserInfo(auth.data.accessToken);
  //       ReactGA.set({ userId: userInfo.userId });
  //       setAxiosAuthorization(auth.data.accessToken);
  //       return {
  //         loggedIn: true,
  //         userInfo: userInfo.data,
  //         idToken: auth.data.idToken,
  //         accessToken: auth.data.accessToken,
  //         refreshToken: auth.data.refreshToken
  //       };
  //     })
  //     .then(async loginResult => {
  //       //Get User Customer data
  //       const userCustomer = await getUserCustomer(loginResult.accessToken, loginResult.userInfo.accounts[0].accountId);
  //       return { ...loginResult, customers: userCustomer.data };
  //     });
};

// export const getUserInfoByEmail = async (email: string): Promise<UserInfo | undefined> => {
//   let userInfo: UserInfo | undefined = undefined;
//   try {
//     userInfo = await apiGet<UserInfo>(`/User/info/email/${email}`, AppType.Core);
//   } catch {
//     console.log("User not found.");
//   }
//   return userInfo;
// };

// export const getUserInfoById = async (id: string): Promise<UserInfo | undefined> => {
//   let userInfo: UserInfo | undefined = undefined;
//   try {
//     userInfo = await apiGet<UserInfo>(`/User/info/${id}`, AppType.Core);
//   } catch {
//     console.log("User not found.");
//   }
//   return userInfo;
// };

// // return { result: this.result, message: this.message, data: this.data };

// interface RefreshTokenRequest {
//   refreshToken: string;
// }

// interface RefreshTokenResponse {
//   accessToken: string;
//   idToken: string;
//   refreshToken: string;
// }

// const refreshToken = async (): Promise<UserState> => {
//   console.log("Refreshing token...");

//   // TODO: Improve userState logged in/off interfaces to avoid the non-null assertion
//   const response = await apiPost<RefreshTokenRequest, RefreshTokenResponse>(refreshUrl, { refreshToken: StoreService.getUserState().refreshToken! });
//   setAxiosAuthorization(response.accessToken);

//   console.log("Token refreshed.");
//   return { loggedIn: true, idToken: response.idToken, accessToken: response.accessToken, refreshToken: response.refreshToken };
// };

// let refreshTimer: NodeJS.Timeout | null;
// const initRefreshTimer = () => {
//   const checkTokenInterval: number = ConfigurationService.AppSettings.TravelTokenCheckInterval;
//   if (!refreshTimer) {
//     console.log("Initializing token refresh timer...");
//     refreshTimer = setInterval(() => {
//       if (userService.verifyToken() === TokenStatus.NeedsRefresh) {
//         userService.refreshToken().then(response =>
//           StoreService.GlobalStore.dispatch({
//             type: REFRESH_SUCCESS,
//             loggedIn: response.loggedIn,
//             idToken: response.idToken,
//             accessToken: response.accessToken,
//             refreshToken: response.refreshToken
//           } as RefreshAction)
//         );
//       }
//     }, checkTokenInterval);
//   }
// };

// const stopRefreshTimer = () => {
//   if (refreshTimer) {
//     console.log("Stopped token refresh timer.");
//     clearInterval(refreshTimer);
//     refreshTimer = null;
//   }
// };

// // TODO: Use expiration - issued date / 2 as the refresh date and remove duration from the configuration
// const verifyToken = (userState?: UserState): TokenStatus => {
//   // console.log("Checking refresh token status...");
//   if (typeof userState === "undefined") userState = StoreService.getUserState();
//   if (!userState.accessToken) {
//     return TokenStatus.Invalid;
//   }

//   const decoded = jwt.decode(userState.accessToken, { json: true });
//   if (!decoded || !decoded.exp) {
//     return TokenStatus.Invalid;
//   }

//   const now = new Date();
//   const expirationDate = new Date(decoded.exp * 1000);
//   const refreshDate = new Date(decoded.exp * 1000 - ConfigurationService.AppSettings.TravelTokenDuration / 2);

//   if (now > expirationDate) {
//     console.log(`Token expired (expiration: ${expirationDate}).`);
//     return TokenStatus.Invalid;
//   } else if (now > refreshDate) {
//     console.log(`Token needs refresh (refresh date: ${refreshDate}).`);
//     return TokenStatus.NeedsRefresh;
//   } else {
//     // console.log(`Token valid (expiration: ${expirationDate}).`);
//     return TokenStatus.Valid;
//   }
// };

// const logout = async (history: History): Promise<void> => {
//   console.debug("logout");
//   ReactGA.set({ userId: undefined });

//   await StoreService.clear();
//   stopRefreshTimer();
//   setAxiosAuthorization(undefined);

//   // Redirect to login page using useHistory hook
//   //history.push(routes.Home);
//   window.location.assign("/login");
//   //window.location.reload(true); // TODO: Remove this ASAP! Not the right approach to reload an SPA.
// };

// const getUserCustomer = async (accessToken: string, accountId: string): Promise<any> => {
//   return axios
//     .get(`${customerUrl}?accountId=${accountId}`, {
//       headers: {
//         Authorization: accessToken
//       }
//     })
//     .then(handleAxiosResponse)
//     .then(userInfo => {
//       return userInfo;
//     });
// };

// const setAxiosAuthorization = (token: string | undefined): void => {
//   axios.defaults.headers.common["Authorization"] = token;
// };

// const handleResponse = async (response: Response): Promise<any> => {
//   return response.text().then(text => {
//     const data = JSON.parse(text);
//     if (!response.ok) {
//       // logout();

//       const error = (data && data.message) || response.statusText;
//       console.log(error);

//       return Promise.reject(error);
//     }
//     return data;
//   });
// };

// const handleAxiosResponse = (response: AxiosResponse): any => {
//   if (response.status !== 200) {
//     // logout();
//     const error = (response.data && response.data.message) || response.statusText;
//     return Promise.reject(error);
//   }
//   return response.data;
// };

// export const getAcceptUserInvitation = async (code: string): Promise<any> => {
//   return axios.get(`${userInvitationUrl}/${code}`).then(userInfo => {
//     return userInfo;
//   });
// };

// export const updateUserInvitation = async (userInvitation: UserInvitation): Promise<any> => {
//   const userInfo = await axios.post(`${userInvitationUrl}`, userInvitation);
//   return userInfo;
// };

// export const getUserPreferences = async (): Promise<UserPreferences> => {
//   return axios.get(`${userPreferencesUrl}`).then(userPreferences => {
//     return userPreferences.data.data;
//   });
// };

// export const forgotPassword = async (email: string): Promise<boolean> => {
//   const data = { username: email, metaData: { username: email, applicationId: "VindowTravel" } };
//   const result = await axios.post(`${forgotPasswordUrl}`, data);
//   return result.status === 200;
//   //return axios.post(`${forgotPasswordUrl}`, data);
// };

// export const resetPassword = async (userResetPassword: UserResetPassword): Promise<any> => {
//   return axios
//     .post(`${resetPasswordUrl}`, {
//       username: userResetPassword.email,
//       code: userResetPassword.verificationCode,
//       password: userResetPassword.password
//     })
//     .then(result => {
//       return result;
//     })
//     .catch(err => {
//       return err;
//     });
// };

// export const updateUserProfile = async (userProfile: UserProfile): Promise<any> => {
//   return axios
//     .post(`${updateUserUrl}`, userProfile)
//     .then(handleUserResponse)
//     .then(result => {
//       return result;
//     });
// };

// interface updateUserPayload {}

// // export const updateUserByEmail = async (email: string, )

// export const changePicture = async (fileName: string, data: string): Promise<any> => {
//   return axios
//     .post(
//       `${pictureUrl}`,
//       { fileName: fileName, data: data },
//       {
//         headers: {
//           ...axios.defaults.headers,
//           "Content-Type": "application/json"
//         }
//       }
//     )
//     .then(handleUserResponse)
//     .then(result => {
//       return result;
//     });
// };

// export const removePicture = async (): Promise<any> => {
//   const response = await axios.delete(`${pictureUrl}`, {
//     headers: {
//       ...axios.defaults.headers,
//       "Content-Type": "application/json"
//     }
//   });
//   const result = await handleUserResponse(response);
//   return result;
// };

// const resendInvitation = async (username: string, ic: string): Promise<any> => {
//   return axios.get(`${resendInvitationUrl}?username=${username}&ic=${ic}`).then(() => {
//     return;
//   });
// };

// export const isUserByEmail = async (emailList: (string | undefined)[]): Promise<User[]> => {
//   const validatedUsers = await axios.post(`${isUserUrl}`, emailList);
//   return validatedUsers.data.data;
// };

// const handleUserResponse = (response: AxiosResponse): any => {
//   if (response.status !== 200) {
//     const error = (response.data && response.data.message) || response.statusText;
//     return Promise.reject(error);
//   }
//   return response.data;
// };

// export enum TokenStatus {
//   Invalid,
//   NeedsRefresh,
//   Valid
// }

// export const userService = {
//   login,
//   verifyToken,
//   refreshToken,
//   logout,
//   getUserCustomer,
//   setAxiosAuthorization,
//   changePicture,
//   initRefreshTimer,
//   stopRefreshTimer,
//   resendInvitation,
//   getUserInfo
// };

const setAxiosAuthorization = (token: string | undefined): void => {
  LocalStorageService.set('token', token);
  axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
};

export const PublicService = {
  Authenticate,
  getUserInfo,
};
