import React, { useCallback, useContext, useState } from "react";
import { Form, message, Button, Row, Col } from "antd";
import "../SideRightCard.css";
import { TeamsContext } from "../../contexts/TeamsContext";
import Popup from "../../components/Modal/Popup";
import { FormItem } from "../../components/Form/FormItem/FormItem";
import Input from "../../components/Form/Input/Input";
import { ButtonSaveCancel } from "../../components/Form/Buttons/ButtonSaveCancel";
import { FederationTeamFederate } from "../../virtualclub/models/models/federationsTeams/FederationTeam.federate";
import { SelectSubTeam } from "../../components/lookups/SelectSubTeam";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { ButtonSave } from "../../components/Form/Buttons/ButtonSave";

interface properties {}

export const TeamFedarationsAdd: React.FC<properties> = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { current, editing, federateTeam } = useContext(TeamsContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlerOpen = () => {
    setIsModalVisible(true);
  };

  const handlerClose = () => {
    setIsModalVisible(false);
  };

  const handlerCancelClick = useCallback(() => {
    form.setFieldsValue(current ?? {});
    setIsModalVisible(false);
  }, [current, form]);

  const handlerSaveClick = useCallback((): void => {
    form.submit();
  }, [form]);

  const onFinish = useCallback(
    async (values: FederationTeamFederate): Promise<void> => {
      try {
        if (current?.teamId) {
          const model: FederationTeamFederate = {
            ...values,
            teamId: current?.teamId,
          };
          const result = (await federateTeam(model)) ?? false;
          if (result) {
            messageApi.open({
              type: "success",
              content: "Guardado con exito",
            });
            handlerCancelClick();
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
      messageApi.open({
        type: "error",
        content: "Error al guardado.",
      });
    },
    [federateTeam, current?.teamId, handlerCancelClick, messageApi]
  );

  const showAddButton = !editing && current;

  return (
    <>
      {contextHolder}
      {showAddButton && (
        <div className="sideright-card-options">
          <ButtonAction size={42} type="plus" onClick={handlerOpen} />
        </div>
      )}
      <Popup
        visible={isModalVisible}
        title="Asociar/Federar Equipo"
        showCloseButton
        width={500}
        onClose={handlerClose}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={new FederationTeamFederate()}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={24}>
              <FormItem
                label="Codigo de Asociacion/Federacion"
                name="federationCode"
              >
                <Input />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Sub-Equipos" name="subTeam">
                <SelectSubTeam subTeams={6} />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <ButtonSave onClick={handlerSaveClick} />
      </Popup>
    </>
  );
};
