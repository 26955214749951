import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MembersContextProvider } from "../../contexts/MembersContext";
import { MembersList } from "./MembersList";
import { MembersCard } from "./MembersCard";
import { GenericPage } from "../../components/Screen/GenericPage";
import { ContentPage } from "../../components/Screen/ContentPage";
import { SideRightPage } from "../../components/Screen/SideRightPage";
import { MembersSearch } from "./MembersSearch";

const MembersPage: React.FC = () => {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const handleSelect = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <MembersContextProvider>
      <GenericPage>
        <ContentPage>
          <MembersSearch />
          <MembersList onSelect={handleSelect} />
        </ContentPage>

        <SideRightPage
          isDrawerVisible={isDrawerVisible}
          onCloseDrawer={handleCloseDrawer}
        >
          <MembersCard />
        </SideRightPage>
      </GenericPage>
    </MembersContextProvider>
  );
};

export default MembersPage;
