import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";
import React, { useContext } from "react";
import { MatchContext } from '../../../contexts/MatchContext';

export const Counter: React.FC = () => {
  const { match } = useContext(MatchContext);
  const { addedPlayers } = useContext(PreSelectTeamContext);

  return (
    <span
      className="info-text"
      style={{
        fontWeight: 500,
        fontSize: 14,
        display: "flex",
        justifyContent: "end",
      }}
    >
      Min {match?.minimumPlayers} | Max {match?.maximumPlayers} |
      <span style={{ fontWeight: 700, fontSize: 18, color: "#0100B4" }}>
        Act {addedPlayers?.length ?? 0}
      </span>
    </span>
  );
};
