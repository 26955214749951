import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./LoginPopup.css";
import Popup from "../../../components/Modal/Popup";
import LoginImage from "./assets/LoginImage.png";
import { useNavigate } from "react-router-dom";
import { AuthRequest } from "../../../virtualclub/models/models/login/AuthRequest";
import { PublicService } from "../../../services/PublicService";
import { routes } from "../../../constants/RouteConstants";

interface LoginPopupProps {
  visible: boolean;
  onClose: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [userCredencials, setUserCredentials] = useState<AuthRequest>({
    username: "",
    password: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const handlerFinish = async (values: AuthRequest): Promise<void> => {
    setLoading(true);
    setHasError(false);
    try {
      const userInfo = await PublicService.Authenticate(values);

      if (userInfo) {
        navigate(routes.Matches);
      }
    } catch (err) {
      console.log(err);
      messageApi.open({
        type: "error",
        content: "Verifique el usuario y o la contraseña.",
      });
    }
    setLoading(false);
  };

  return (
    <Popup visible={visible} width={750} onClose={onClose}>
      {contextHolder}
      <div className="login-popup">
        <div className="login-popup-left">
          <img src={LoginImage} alt="Login" className="login-popup-image" />
        </div>
        <div className="login-popup-right">
          <h2 className="login-text">Iniciar Sesion</h2>
          <Form
            name="popup_login"
            className="login-form"
            layout="vertical"
            initialValues={userCredencials}
            onFinish={handlerFinish}
          >
            <span className="login-input-text">Usuario</span>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Ingrese su nombre de usuario" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email / nombre de usuario"
              />
            </Form.Item>
            <span className="login-input-text">Constraseña</span>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Ingrese su contraseña" }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Recordar</Checkbox>
              </Form.Item>
              <a className="login-label" href="">
                Olvide mi contraseña
              </a>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Popup>
  );
};

export default LoginPopup;
