import { IsEnum } from 'class-validator';
import { TeamStatus } from './TeamStatus.enum';

export class TeamView {
  teamId?: string = '';
  accountId?: string = '';
  sportId?: number = 0;
  teamName?: string = '';
  teamShort?: string = '';

  @IsEnum(TeamStatus)
  teamStatus?: TeamStatus = TeamStatus.Active;
  color?: string = '';
  color1?: string = '';
  color2?: string = '';
}
