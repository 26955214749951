import React from "react";
import { Tooltip } from "antd";
import { ReactComponent as BackIcon } from "../../../assets/icons/buttonBack.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/buttonPlus.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/buttonCheck.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/buttonCancel.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/buttonEdit.svg";
import "./ButtonAction.css";

interface Properties {
  type: "plus" | "check" | "cancel" | "edit" | "back";
  onClick?: () => void;
  size?: number;
  tooltip?: string;
  color?: string;
}

export const ButtonAction: React.FC<Properties> = ({
  type,
  onClick,
  tooltip,
  color,
  size = 32,
}) => {
  const getButtonIcon = (): JSX.Element | null => {
    switch (type) {
      case "back":
        return <BackIcon className={`button-action ${type}`} />;
      case "plus":
        return <PlusIcon className={`button-action ${type}`} />;
      case "check":
        return <CheckIcon className={`button-action ${type}`} />;
      case "cancel":
        return <CancelIcon className={`button-action ${type}`} />;
      case "edit":
        return <EditIcon className={`button-action ${type}`} />;
      default:
        return null;
    }
  };

  const getTooltip = (): string => {
    switch (type) {
      case "plus":
        return "Agregar uno nuevo";
      case "check":
        return "Confirmar";
      case "cancel":
        return "Cancelar";
      case "edit":
        return "Editar";
      default:
        return "";
    }
  };

  return (
    <Tooltip title={tooltip ?? getTooltip()}>
      <div onClick={onClick}>{getButtonIcon()}</div>
    </Tooltip>
  );
};
