import React, { useEffect, useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import { SelectExt, SelectExtProps, SelectItem } from "../SelectExt";
import { AvatarImage } from "../Images/AvatarImage";
import { useFederationTeamsService } from "../../services/useFederationTeamsService";
import { NamesHelper } from "../../helpers/NamesHelper";

interface properties extends SelectExtProps {
  federationId?: string;
  showAll?: boolean;
  textAll?: string;
}

export const SelectFederatedTeams: React.FC<properties> = ({
  federationId,
  showAll = false,
  textAll = showAll ? "Todos las federaciones" : "Seleccione una federacion",
  value,
  readOnly,
  type,
  onChange,
  ...props
}) => {
  const { isLoading, list, data } = useFederationTeamsService();

  useEffect(() => {
    if (federationId) {
      list({ federationId });
    }
  }, [federationId]);

  const options = useMemo(() => {
    const its: SelectItem[] =
      data?.data?.map((row) => {
        return {
          value: row.federationTeamId,
          label: (
            <span style={{ display: "flex", alignItems: "center" }}>
              <AvatarImage
                id={row.teamId}
                alt={row.teamShort}
                style={{ width: 20, marginRight: 8 }}
              />
              {row.teamName}{" "}
              {row.subTeam ? (
                <strong> [{NamesHelper.LetterName(row.subTeam)}]</strong>
              ) : (
                ""
              )}
            </span>
          ),
        } as SelectItem;
      }) ?? [];
    if (showAll) {
      its.unshift({ value: -1, label: textAll });
    }
    return its;
  }, [data?.data, showAll, textAll]);

  function onChangeValue(
    value: string | number,
    option: DefaultOptionType | DefaultOptionType[]
  ): void {
    const selectedTeam = data?.data?.find(
      (row) => row.federationTeamId === value
    );
    onChange?.(selectedTeam, option);
  }

  return (
    <SelectExt
      options={options}
      readOnly={readOnly}
      value={value}
      placeholder={textAll}
      onChange={onChangeValue}
      loading={isLoading}
      type={type}
    />
  );
};
