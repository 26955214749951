import React, { forwardRef } from "react";
import { InputHTMLAttributes } from "react";

interface InputDateProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  value?: string | Date; // Puede recibir un valor como string o Date
  disabled?: boolean;
  onChange?: (date: string | null) => void;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  ({ value, onChange, disabled, ...props }, ref) => {
    const formattedValue =
      value instanceof Date
        ? value.toISOString().split("T")[0]
        : value
        ? new Date(value).toISOString().split("T")[0]
        : "";

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value ? e.target.value : null;
      if (onChange) onChange(newValue);
    };

    const displayValue = formattedValue
      ? new Date(formattedValue).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "dd/mm/aaaa";

    return (
      <input
        type="date"
        {...props}
        ref={ref}
        disabled={disabled}
        value={formattedValue}
        onChange={handleChange}
        className={`custom-input ${disabled ? "disabled" : ""}`}
        placeholder={displayValue}
      />
    );
  }
);

export default InputDate;
