export class NumberHelper {
  static toCurrency(value?: number, decimal = 2): string {
    return (value ?? 0)
      .toFixed(decimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  static zero(value = 0, count = 2): string {
    return value?.toString().padStart(count, '0');
  }

  static toTimer(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const sec = Math.trunc(remainingSeconds); // Obtiene la parte entera
    const milisec = +(remainingSeconds - sec).toFixed(2) * 100;

    //return `${hours > 0 ? hours + ":" : ""}${NumberHelper.zero(minutes, 2)}:${sec.toString().padStart(2, '0')}.${milisec.toString().padStart(2, '0')}`;
    return `${hours > 0 ? hours + ":" : ""}${NumberHelper.zero(minutes, 2)}:${sec.toString().padStart(2, '0')}`;
  }




}

