import React from "react";
import styles from "./PlayerShirt.module.css";
import { ReactComponent as C0 } from "../../assets/shirts/c0.svg";
import { ReactComponent as C1 } from "../../assets/shirts/c1.svg";

interface Properties {
  gender: number;
  number?: string | number;
  name?: string;
  fontColor?: string;
  color?: string;
}

export const PlayerShirt: React.FC<Properties> = ({
  gender,
  number,
  name,
  fontColor = "black",
  color = "white",
}) => {
  const ShirtImage = gender === 1 ? C1 : C0;

  const shirtStyle = {
    color,
  };
  return (
    <div className={styles.shirtContainer}>
      <div className={styles.shirtImageContainer} style={shirtStyle}>
        <ShirtImage className={styles.shirtImage} />
      </div>
      {number && (
        <div className={styles.shirtNumber} style={{ color: fontColor }}>
          {number}
        </div>
      )}
      {name && (
        <div className={styles.shirtName} style={{ color: fontColor }}>
          {name}
        </div>
      )}
    </div>
  );
};
