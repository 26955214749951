import React, { useContext } from "react";
import "./MatchHeader.css";
import { configApp } from "../../../services/configApp";
import { DateHelper } from "../../../helpers/DateHelper";
import Logo from "../../../public/logo.png";
import { MatchContext } from "../../../contexts/MatchContext";
import { NamesHelper } from '../../../helpers/NamesHelper';


interface properties {
  teamId?: string;
}

export const MatchHeader: React.FC<properties> = () => {
  const { match } = useContext(MatchContext);

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = Logo;
  };

  return (
    <div className="nextmatch-card">
      <div className="nextmatch-header">
        <span className="nextmatch-info-textsmall">
          {match?.matchCode} | {match?.gender ? "Caballeros" : "Damas"} |{" "}
          {match?.category?.categoryName}
        </span>
      </div>

      <div className="nextmatch-body">
        <div className="nextmatch-team">
          <img
            src={`${configApp.avatarUrl}${match?.homeTeamId}.png`}
            alt={match?.homeTeam?.team?.teamName}
            onError={handleImageError}
            className="nextmatch-team-logo"
          />
          <span className="nextmatch-team-name">
            {match?.homeTeam?.team?.teamName} {NamesHelper.LetterName(match?.homeTeam?.subTeam)}
          </span>
        </div>

        <div className="nextmatch-info">
          <span className="nextmatch-info-textmedium">
            {" "}
            {DateHelper.getDateString(match?.dateTimePlay ?? new Date())}
          </span>
          <span className="nextmatch-info-textbold">
            {DateHelper.formatTime(match?.dateTimePlay ?? new Date())}
          </span>
        </div>

        <div className="nextmatch-team">
          <span className="nextmatch-team-name">
            {match?.awayTeam?.team?.teamName} {NamesHelper.LetterName(match?.awayTeam?.subTeam)}
          </span>
          <img
            src={`${configApp.avatarUrl}${match?.awayTeamId}.png`}
            alt={match?.awayTeam?.team?.teamName}
            onError={handleImageError}
            className="nextmatch-team-logo"
          />
        </div>
      </div>

    </div>
  );
};
