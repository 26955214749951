import { Pagination, Spin } from "antd";
import { useContext } from "react";
import { SearchInput } from "../../components/Search/SearchInput";
import { TeamsContext } from "../../contexts/TeamsContext";
import { OrderBy } from "../../components/OrderBy/OrderBy";
import { Row } from "../../components/TableCard/Row";
import { ColText } from "../../components/TableCard/ColText";
import { ColAvatar } from "../../components/TableCard/ColAvatar";
import { ColSport } from "../../components/TableCard/ColSport";
import { ButtonAction } from "../../components/Form/Buttons/ButtonAction";
import { ButtonAdd } from "../../components/Form/Buttons/ButtonAdd";

interface properties {
  title?: string;
  onSelect?: () => void;
}

export const TeamsList: React.FC<properties> = ({ title, onSelect }) => {
  const {
    isLoading: searching,
    data,
    filters,
    current,
    create,
    updateFilters,
    selected,
    gotoPage,
  } = useContext(TeamsContext);

  function handlerSearch(value: string): void {
    updateFilters?.({ search: value });
  }

  function handlerSort(value: string): void {
    //updateFilters?.({ sort: value });
  }

  const handleRowClick = (teamId: string) => {
    const equipo = data?.data?.find((p) => p.teamId === teamId);
    if (equipo) {
      selected(equipo);
      onSelect?.();
    }
  };

  function handlerChangePage(page: number, pageSize: number): void {
    gotoPage(page);
  }

  return (
    <Spin spinning={searching}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          marginBottom: 5,
        }}
      >
        <div style={{ flex: 1, fontWeight: 400 }}>
          {title ?? "Listado de equipos"}
        </div>
        <div style={{ flex: 1 }}>
          <SearchInput value={filters?.search} onChange={handlerSearch} />
        </div>
        <div style={{ flex: 1 }}>
          <OrderBy
            //value={filters?.sort}
            options={[
              { value: "teamName", label: "Nombre" },
              { value: "teamShort", label: "Nombre Corto" },
            ]}
            onChange={handlerSort}
            showSort
          />
        </div>
        <div>
          <ButtonAdd onClick={create} />
        </div>
      </div>
      <div className="small-container">
        {data?.data &&
          data?.data.map((record) => (
            <Row
              key={record.teamId}
              id={record.teamId ?? ""}
              onClick={handleRowClick}
              active={record.teamId === current?.teamId}
            >
              <ColAvatar id={record.teamId ?? ""} />
              <ColSport value={record.sportId ?? 0} />
              <ColText value={record.teamName ?? ""} />
              <ColText value={record.teamShort ?? ""} />
            </Row>
          ))}
      </div>
      {data?.limit && (data?.total ?? 0) > data?.limit && (
        <Pagination
          total={data?.total}
          pageSize={data.limit}
          current={data.page}
          onChange={handlerChangePage}
        />
      )}
    </Spin>
  );
};
