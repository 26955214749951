import React, { useCallback, useContext, useState } from "react";
import { SportTeamCategoryType } from "../../../virtualclub/models/models/sportsTeamsCategories/SportTeamCategoryType.enum";
import { SelectSportTeam } from "../../../components/lookups/SelectSportTeam";
import { MatchContext } from "../../../contexts/MatchContext";
import { SelectSportTeamPopup } from "../../../components/lookups/SelectSportTeamPopup";
import { SportTeamView } from "../../../virtualclub/models/models/sportsTeams/SportTeam.view";
import { MatchSportTeamView } from "../../../virtualclub/models/models/matchesSportTeams/MatchSportTeam.view";
import { PreSelectTeamContext } from "../preSelectTeam/PreSelectTeamContext";

interface properties {
  teamId?: string;
}

export const MatchStaffList: React.FC<properties> = ({ teamId }) => {
  const [sportTeamCategoryType, setSportTeamCategoryType] =
    useState<SportTeamCategoryType>();
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const { match } = useContext(MatchContext);
  const { updateMatchSportTeam } = useContext(PreSelectTeamContext);

  const handlerClick = useCallback((value: SportTeamCategoryType) => {
    setSportTeamCategoryType(value);
    setIsOpenPopup(true);
  }, []);

  const handlerClose = useCallback(() => {
    setIsOpenPopup(false);
  }, []);

  const handlerSelected = useCallback(
    (value: SportTeamView) => {
      updateMatchSportTeam({
        ...(value as SportTeamView),
        sportTeamType: sportTeamCategoryType,
      });
      setIsOpenPopup(false);
    },
    [sportTeamCategoryType, updateMatchSportTeam]
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Judge}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Coach}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.PhysicalTrainer}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Assistant}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Medic}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
        <SelectSportTeam
          sportTeamCategoryType={SportTeamCategoryType.Referee}
          onClick={(v: SportTeamCategoryType) => handlerClick(v)}
        />
      </div>

      <SelectSportTeamPopup
        isOpen={isOpenPopup}
        federationId={match?.accountId}
        sportTeamCategoryType={sportTeamCategoryType}
        onClose={handlerClose}
        onSelected={handlerSelected}
      />
    </div>
  );
};

export default MatchStaffList;
